import { Fragment } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useConfirm } from 'material-ui-confirm';

import {
  FormControl,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Link,
} from '@mui/material';

// Icons
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// Utils
import messages from '../../../static/messages';

// Services
import proposalsServices from '../../../services/proposalsServices';
import resourceServices from '../../../services/resourceServices';

// Styles
import { button } from '../../../static/theme-styles';

const ProposalCustomerInfoTable = ({ proposal, id, refresh }) => {
  const confirm = useConfirm();

  // Action functions
  const onRemoveFile = (fileId) => {
    const data = proposal.informationForCustomer.map((el) => ({ id: el.id })).filter((el) => el.id !== fileId);

    updateProposal(data);
  };

  // Handler functions
  const handleRemoveFile = (fileId) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('file'),
    })
      .then(() => onRemoveFile(fileId))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const updateProposal = async (data) => {
    try {
      const response = await proposalsServices.update(id, {
        informationForCustomer: data,
      });

      if (response.status === 200) {
        toast.success(messages.success.update.common);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const uploadFile = async (event) => {
    const newFile = event.target.files[0];

    const response = await resourceServices.upload(newFile, 'proposal-information-for-customer');

    if (response.status === 201) {
      const infoForCustomer = [
        ...proposal.informationForCustomer.map((el) => ({ id: el.id })),
        {
          id: response.data.id,
        },
      ];

      updateProposal(infoForCustomer);
      event.target.value = '';
      event.target.files = [];
    }
    try {
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="75%">File Name</TableCell>
              <TableCell width="20%">Created At</TableCell>
              <TableCell width="5%">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposal?.informationForCustomer?.length ? (
              proposal?.informationForCustomer?.map((el) => (
                <TableRow key={el.id}>
                  <TableCell scope="row">{el.filename}</TableCell>
                  <TableCell>{moment.utc(el.createdAt).format('MMM Do YYYY, h:mm:ss a')}</TableCell>
                  <TableCell>
                    <Tooltip title="See">
                      <Link href={el.url} target="_blank">
                        <IconButton>
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <IconButton onClick={() => handleRemoveFile(el.id)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <FormControl sx={{ mt: 3 }}>
        <Button variant="contained" endIcon={<UploadFileIcon />} component="label" sx={button('secondary')}>
          Upload File
          <input hidden accept="image/*, .pdf, .heic" type="file" name="media" onChange={uploadFile} />
        </Button>
      </FormControl>
    </Fragment>
  );
};

export default ProposalCustomerInfoTable;
