import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Divider,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';

// Icons
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services
import warrantyServices from '../../../../services/warrantyServices';

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

const WarrantyModule = ({ systemName, data, setSystems, system }) => {
  const [allWarranties, setAllWarranties] = useState([]);
  const [warrantiesList, setWarrantiesList] = useState([]);
  const [filtersData, setFiltersData] = useState();
  const [yearsList, setYearsList] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [currentGroupItem, setCurrentGroupItem] = useState();
  const [currentItemDD, setCurrentItemDD] = useState(null);

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    const val = event.target.value;

    const selected_item = allWarranties.find((el) => {
      if (el.years === val && el.description === currentItem) return el;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        warranties: [
          ...prevState.filter((system) => system.name === systemName)[0].warranties,
          {
            flexibleItems: [
              {
                item: selected_item,
                price: null,
              },
            ],
            selectedItemIndex: 0,
          },
        ],
      },
    ]);
    setCurrentItem();
  };

  const onAddNewItemToGroup = (event, warrantyID) => {
    const val = event.target.value;

    const selected_item = allWarranties.find((el) => {
      if (el.years === val && el.description === currentGroupItem) return el;
    });

    const updatedList = [
      ...warrantiesList.filter((el) => el.warrantyId !== warrantyID),
      {
        ...warrantiesList.filter((el) => el.warrantyId === warrantyID)[0],
        flexibleItems: [
          ...warrantiesList.filter((el) => el.warrantyId === warrantyID)[0].flexibleItems,
          {
            item: selected_item,
            price: null,
            itemId: warrantiesList.filter((el) => el.warrantyId === warrantyID)[0].flexibleItems.length,
          },
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((el) => el.name !== systemName),
      {
        ...prevState.filter((el) => el.name === systemName)[0],
        warranties: updatedList,
      },
    ]);

    setCurrentGroupItem();
  };

  const onRemoveItem = (item) => {
    const updatedList = [
      ...warrantiesList.filter((el) => !el.flexibleItems.includes(item)),
      {
        ...warrantiesList.filter((el) => el.flexibleItems.includes(item))[0],
        flexibleItems: [
          ...warrantiesList.filter((el) => el.flexibleItems.includes(item))[0].flexibleItems.filter((i) => i !== item),
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        warranties: updatedList,
      },
    ]);
  };

  const onSelectItem = (event) => {
    const title = event.target.value;

    let unique_values = allWarranties
      .filter((el) => el.description === title)
      .reduce(
        (acc, el) => {
          if (acc.map[el.years]) return acc;

          acc.map[el.years] = true;
          acc.years.push(el.years);

          return acc;
        },
        {
          map: {},
          years: [],
        },
      );

    setCurrentItem(title);
    setYearsList(unique_values.years);
  };

  const onSelectGroupItem = (event) => {
    const title = event.target.value;

    let unique_values = allWarranties
      .filter((el) => el.description === title)
      .reduce(
        (acc, el) => {
          if (acc.map[el.years]) return acc;

          acc.map[el.years] = true;
          acc.years.push(el.years);

          return acc;
        },
        {
          map: {},
          years: [],
        },
      );

    setCurrentGroupItem(title);
    setYearsList(unique_values.years);
  };

  // Handler functions
  const handleChange = (event, warrantyID, itemID) => {
    const key = event.target.name;
    const val = event.target.value;

    const updatedWorks = [
      ...warrantiesList.filter((el) => el.warrantyId !== warrantyID),
      {
        ...warrantiesList.filter((el) => el.warrantyId === warrantyID)[0],
        ...(key === 'selectedItemIndex' && {
          [key]: Number(val),
          flexibleItems: [...warrantiesList.filter((el) => el.warrantyId === warrantyID)[0].flexibleItems].sort(
            function (a, b) {
              if (a.itemId < b.itemId) {
                return -1;
              }
              if (a.itemId > b.itemId) {
                return 1;
              }
              return 0;
            },
          ),
        }),
        ...(key !== 'selectedItemIndex' && {
          flexibleItems: [
            ...warrantiesList
              .filter((el) => el.warrantyId === warrantyID)[0]
              .flexibleItems.filter((item) => item.itemId !== itemID),
            {
              ...warrantiesList
                .filter((el) => el.warrantyId === warrantyID)[0]
                .flexibleItems.filter((item) => item.itemId === itemID)[0],
              [key]: Number(val),
            },
          ].sort(function (a, b) {
            if (a.itemId < b.itemId) {
              return -1;
            }
            if (a.itemId > b.itemId) {
              return 1;
            }
            return 0;
          }),
        }),
      },
    ].sort(function (a, b) {
      if (a.warrantyId < b.warrantyId) {
        return -1;
      }
      if (a.warrantyId > b.warrantyId) {
        return 1;
      }
      return 0;
    });

    console.log(updatedWorks);

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        warranties: updatedWorks,
      },
    ]);
  };

  const handleChangeToOther = (event, warrantyID, itemID) => {
    const key = event.target.name;
    const val = event.target.value;

    const updatedWorks = [
      ...warrantiesList.filter((el) => el.warrantyId !== warrantyID),
      {
        ...warrantiesList.filter((el) => el.warrantyId === warrantyID)[0],
        ...(key === 'selectedItemIndex' && {
          flexibleItems: [...warrantiesList.filter((el) => el.warrantyId === warrantyID)[0].flexibleItems].sort(
            function (a, b) {
              if (a.itemId < b.itemId) {
                return -1;
              }
              if (a.itemId > b.itemId) {
                return 1;
              }
              return 0;
            },
          ),
        }),
        ...(key !== 'selectedItemIndex' && {
          flexibleItems: [
            ...warrantiesList
              .filter((el) => el.warrantyId === warrantyID)[0]
              .flexibleItems.filter((item) => item.itemId !== itemID),
            {
              ...warrantiesList
                .filter((el) => el.warrantyId === warrantyID)[0]
                .flexibleItems.filter((item) => item.itemId === itemID)[0],
              [key]: val,
            },
          ].sort(function (a, b) {
            if (a.itemId < b.itemId) {
              return -1;
            }
            if (a.itemId > b.itemId) {
              return 1;
            }
            return 0;
          }),
        }),
      },
    ].sort(function (a, b) {
      if (a.warrantyId < b.warrantyId) {
        return -1;
      }
      if (a.warrantyId > b.warrantyId) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        warranties: updatedWorks,
      },
    ]);
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleGroupItems = (item) => {
    const updatedList = [
      ...warrantiesList.filter((el) => el !== item),
      {
        ...item,
        grouped: item.grouped ? false : true,
      },
    ].sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        warranties: updatedList,
      },
    ]);
  };

  const dragStartHandler = (e, item) => setCurrentItemDD(item);

  const dragEndHandler = (e) => setCurrentItemDD(null);

  const dragOverHandler = (e) => e.preventDefault();

  const dragLeaveHandler = (e) => (e.target.style.boxShadow = 'none');

  const dropHandler = (e, item) => {
    e.preventDefault();
    e.target.style.boxShadow = 'none';

    const currentIndex = warrantiesList.indexOf(currentItemDD);

    warrantiesList.splice(currentIndex, 1);

    const dropIndex = warrantiesList.indexOf(item);

    warrantiesList.splice(dropIndex, 0, currentItemDD);

    system.warranties = warrantiesList;
  };

  // Async functions
  const getWarrantiesList = async () => {
    try {
      const response = await warrantyServices.getAllWarranty(0, 1000000, true);

      if (response.status === 206) {
        let unique_values = response.data.data.reduce(
          (acc, el) => {
            if (acc.map[el.description]) return acc;

            acc.map[el.description] = true;
            acc.warranties.push({
              id: el.id,
              name: el.description,
            });

            return acc;
          },
          {
            map: {},
            warranties: [],
          },
        );

        setFiltersData(unique_values.warranties);
        setAllWarranties(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getWarrantiesList();

    if (data) {
      const listOfWarranties = [];

      data?.forEach((el, index) => {
        listOfWarranties.push({
          ...el,
          warrantyId: index,
          grouped: el.flexibleItems.length > 1 || el.grouped ? true : false,
          flexibleItems: [
            ...(() => {
              const items = [];

              el.flexibleItems.forEach((item, idx) => {
                items.push({
                  ...item,
                  itemId: idx,
                });
              });
              return items;
            })(),
          ],
        });
      });

      setWarrantiesList(listOfWarranties);
    }
  }, [data]);

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Warranty</strong>
      </Typography>

      {warrantiesList.map((el) => (
        <Box key={el.warrantyId} sx={{ mt: 2, ...(el.grouped && proposal_grouped_items) }}>
          <RadioGroup value={el.selectedItemIndex}>
            {el.flexibleItems
              ?.sort(function (a, b) {
                if (a.itemId < b.itemId) {
                  return -1;
                }
                if (a.itemId > b.itemId) {
                  return 1;
                }
                return 0;
              })
              .map((item, index) => (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}
                  key={item.itemId}
                  draggable={true}
                  onDragStart={(e) => dragStartHandler(e, el)}
                  onDragEnd={(e) => dragEndHandler(e)}
                  onDragOver={(e) => dragOverHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDrop={(e) => dropHandler(e, el)}
                  className="item"
                >
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    {el.grouped && (
                      <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                        <Radio
                          name="selectedItemIndex"
                          value={index}
                          checked={index === el.selectedItemIndex}
                          onChange={(event) => handleChange(event, el.warrantyId, item.itemId)}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Description"
                        name="description"
                        value={item.description || item.description === '' ? item.description : item.item?.description}
                        fullWidth
                        size="small"
                        onChange={(event) => handleChangeToOther(event, el.warrantyId, item.itemId)}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <TextField
                        label="Years"
                        name="years"
                        value={item.years || item.years === '' ? item.years : item.item?.years}
                        fullWidth
                        size="small"
                        onChange={(event) => handleChangeToOther(event, el.warrantyId, item.itemId)}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel required htmlFor="price">
                          Price
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="price"
                          name="price"
                          value={(item.price !== null ? item.price : item.item.price) || ''}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          label="Price"
                          onChange={(event) => handleChange(event, el.warrantyId, item.itemId)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                      {el.flexibleItems.length < 2 && (
                        <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                          <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                            {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete Item">
                        <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </RadioGroup>
          {el.grouped && (
            <Fragment>
              <FormControl>
                <InputLabel id="warranties">Add New</InputLabel>
                <Select
                  labelId="warranties"
                  name="item"
                  value={currentGroupItem || '1'}
                  label="Add New"
                  onChange={onSelectGroupItem}
                  size="small"
                >
                  <MenuItem value="1">Select an item from the list to add</MenuItem>
                  {filtersData?.map((el) => (
                    <MenuItem key={el.id} value={el.name}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ ml: 2 }}>
                <InputLabel id="warranties_years">Years</InputLabel>
                <Select
                  labelId="warranties_years"
                  name="item"
                  value="choose"
                  label="Years"
                  onChange={(event) => onAddNewItemToGroup(event, el.warrantyId)}
                  size="small"
                  disabled={!currentGroupItem}
                >
                  <MenuItem value="choose">Select number of years</MenuItem>
                  {yearsList?.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Fragment>
          )}
        </Box>
      ))}

      <Divider sx={{ margin: '0 auto 25px' }} />

      <Box>
        <FormControl>
          <InputLabel id="warranties">Add New</InputLabel>
          <Select
            labelId="warranties"
            name="item"
            value={currentItem || '1'}
            label="Add New"
            onChange={onSelectItem}
            size="small"
          >
            <MenuItem value="1">Select an item from the list to add</MenuItem>
            {filtersData?.map((el) => (
              <MenuItem key={el.id} value={el.name}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
          <InputLabel id="warranties_years">Years</InputLabel>
          <Select
            labelId="warranties_years"
            name="item"
            value="choose"
            label="Years"
            onChange={onAddNewItem}
            size="small"
            disabled={!currentItem}
          >
            <MenuItem value="choose">Select number of years</MenuItem>
            {yearsList?.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default WarrantyModule;
