import { Fragment } from 'react';

import { Typography } from '@mui/material';

import { column_heading, time_slots_styles } from '../Calendar';

const time_slots = [
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
];

const TimeSlots = () => (
  <Fragment>
    <Typography sx={column_heading} variant="body2">
      Time
    </Typography>
    {time_slots.map((slot, index) => (
      <Typography key={index} sx={time_slots_styles} component="p">
        {slot}
      </Typography>
    ))}
  </Fragment>
);

export default TimeSlots;
