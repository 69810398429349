import { Fragment } from 'react';
import moment from 'moment';

import {
  Box,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Hooks
import usePagination from '../../../hooks/usePagination';

// Styles
import { table_container_base } from '../../../static/styles';

const PropertyAppointmentsTable = ({ appointments }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  return (
    <Fragment>
      <TableContainer component={Paper} sx={table_container_base}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="10%">Status</TableCell>
              <TableCell width="10%">Date</TableCell>
              <TableCell width="10%">Type</TableCell>
              <TableCell width="10%">Source</TableCell>
              <TableCell width="60%">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.length ? (
              appointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((appointment) => (
                <TableRow key={appointment.id}>
                  <TableCell scope="row">
                    <Link
                      href={`/appointments/${appointment.id}`}
                      sx={{ fontWeight: 'bold', justifyContent: 'center' }}
                      className="full-col-flex-link"
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {appointment.status === 'Active' && (
                        <Fragment>
                          <CircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} /> Active
                        </Fragment>
                      )}
                      {appointment.status === 'In Progress' && (
                        <Fragment>
                          <CircleIcon sx={{ color: 'orange', mr: 1, width: 15, height: 15 }} /> In Progress
                        </Fragment>
                      )}
                      {appointment.status === 'Completed' && (
                        <Fragment>
                          <CheckCircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} /> Completed
                        </Fragment>
                      )}
                      {appointment.status === 'Cancelled' && (
                        <Fragment>
                          <CancelIcon sx={{ color: 'red', mr: 1, width: 15, height: 15 }} /> Cancelled
                        </Fragment>
                      )}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      className="full-col-flex-link"
                    >
                      {moment(appointment.startTime).format('MM/DD/YYYY')}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      className="full-col-flex-link"
                    >
                      {appointment.type.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      className="full-col-flex-link"
                    >
                      {appointment.source?.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      className="full-col-flex-link"
                    >
                      <List sx={{ p: 0 }}>
                        {appointment.notes?.map((note) => (
                          <ListItem sx={{ pl: 0, pr: 0 }} key={note.id}>
                            <ListItemText
                              primary={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography variant="body2">{note.note}</Typography>

                                  <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.3)', ml: '5px' }}>
                                    (by {note.createdBy?.fullName} at {` `}
                                    {moment.utc(note.createdAt).format('MM/DD/YYYY hh:mm A')})
                                  </Typography>
                                </Box>
                              }
                              sx={{ m: 0 }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={appointments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Clients per page"
      />
    </Fragment>
  );
};

export default PropertyAppointmentsTable;
