import { Box, Typography } from '@mui/material';

const YouHaveNoAccess = () => (
  <Box
    component="section"
    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}
  >
    <NoEnterIcons />
    <Typography variant="h4" gutterBottom sx={{ mt: 2, fontWeight: 800 }}>
      You Have No Access to this page
    </Typography>
  </Box>
);

export default YouHaveNoAccess;

const NoEnterIcons = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="800px"
    width="800px"
    version="1.1"
    viewBox="0 0 304.223 304.223"
    style={{ width: 250, height: 250 }}
  >
    <g>
      <g>
        <path
          fill="#ff4122"
          d="M152.112,0C68.241,0,0.008,68.244,0.008,152.114c0,83.865,68.233,152.109,152.103,152.109    c83.865,0,152.103-68.244,152.103-152.109C304.215,68.244,235.982,0,152.112,0z M152.112,275.989    c-68.32,0-123.891-55.565-123.891-123.875c0-68.326,55.571-123.891,123.891-123.891s123.891,55.565,123.891,123.891    C276.003,220.424,220.432,275.989,152.112,275.989z"
        />
        <path
          fill="#ff4122"
          d="M226.92,138.261H77.308c-7.658,0-13.853,6.206-13.853,13.853c0,7.664,6.201,13.853,13.853,13.853    H226.92c7.658,0,13.859-6.19,13.859-13.853C240.779,144.467,234.573,138.261,226.92,138.261z"
        />
      </g>
    </g>
  </svg>
);
