import { useState, useEffect } from 'react';

import { Box, Drawer, Grid, TextField, Button, Divider, Typography } from '@mui/material';

// Utils
import messages from '../../static/messages';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['name'];

const PropertyTypesDrawer = (props) => {
  const { currentItem, setCurrentItem, createType } = props;
  const [errors, setErrors] = useState({});

  // Action functions
  const resetErrors = () => {
    setErrors({});
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  // Handler functions
  const handleClose = () => props.onCloseDrawer(resetErrors);

  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setCurrentItem((prevState) => ({
      ...prevState,
      [key]: val,
      isEdited: true,
    }));
  };

  const handleValidate = () => {
    resetErrors();

    required_fields.forEach((field) => {
      if (currentItem[field] === '') {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
  };

  // Hooks
  useEffect(() => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  }, []);

  useEffect(() => {
    if (currentItem?.isEdited) {
      delete currentItem.isEdited;

      !Object.values(errors).includes(true) && createType();
    }
  }, [errors]);

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        {currentItem && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Add New Item
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="name"
                label="Name"
                defaultValue={currentItem.name}
                fullWidth
                onChange={handleChange}
                error={errors.name}
                helperText={errors.name && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                disabled={!currentItem.isEdited}
                onClick={handleValidate}
              >
                Add New Item
              </Button>

              <Button variant="contained" onClick={handleClose} sx={button('secondary')}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default PropertyTypesDrawer;
