import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

// Custom Components
import { Spinner, BrowserTitle, DateSwitcher } from '../components';

// Utils
import messages from '../static/messages';
import { AuthContext } from '../context/AuthProvider';

// Services
import userServices from '../services/userServices';
import appointmentsServices from '../services/appointmentsServices';

// Hooks
import usePagination from '../hooks/usePagination';

// Styles
import { table_container_base } from '../static/styles';

const link_styles = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const Timer = () => {
  const { userData } = useContext(AuthContext);
  const [date, setDate] = useState(moment().format());
  const [appointments, setAppointments] = useState();
  const [techniciansList, setTechniciansList] = useState();
  const [user, setUser] = useState('all');

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  // Handler functions
  const handleFilterChange = (event) => setUser(event.target.value);

  // Async functions
  const getTechniciansList = async () => {
    try {
      const response = await userServices.getAllUsers(0, 1000000, false);

      if (response.status === 206) setTechniciansList(response.data.data.filter((el) => el.role === 'technician'));
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getUserAppointments = async (id) => {
    try {
      const response = await appointmentsServices.getAllAppointments(date.split('T')[0] + 'T08:00:00.000Z', id);

      if (response.status === 206) setAppointments(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getTechniciansList();
  }, []);

  useEffect(() => {
    if (user !== 'all') getUserAppointments(user);
    else getUserAppointments(null);
  }, [user, date]);

  // Check for data loading
  if (!appointments) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title="Timer | AirMaxx Pro" />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DateSwitcher date={date} setDate={setDate} />

        <FormControl sx={{ mr: 2, maxWidth: 240, width: '100%' }} size="small">
          <InputLabel id="user">User</InputLabel>
          <Select labelId="user" label="User" value={user} name="user" onChange={handleFilterChange}>
            <MenuItem value="all">All</MenuItem>
            {techniciansList?.map((el) => (
              <MenuItem value={el.id} key={el.id}>
                {el.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper} sx={{ ...table_container_base, mt: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: { md: '20%' } }}>Name</TableCell>
              <TableCell sx={{ width: { md: '50%' } }}>Address</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }, width: '10%' }}>Type</TableCell>
              <TableCell sx={{ width: { md: '10%' } }}>Check In Time</TableCell>
              <TableCell sx={{ width: { md: '10%' } }}>Check Out Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments?.length ? (
              appointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((appointment) => (
                <TableRow key={appointment.id} hover>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {appointment.user?.fullName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {appointment.property?.address}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {appointment.type.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {appointment?.checkIn ? moment(appointment?.checkIn).format('hh:mm A') : 'No Data'}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {appointment?.checkOut ? moment(appointment?.checkOut).format('hh:mm A') : 'No Data'}
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={appointments?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
      />
    </Box>
  );
};

export default Timer;
