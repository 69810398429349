import { Fragment } from 'react';

import { Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const ProposalViewSystemWorks = ({ arr, handleChange, system_index }) => (
  <Fragment>
    <Typography variant="body2" sx={{ fontWeight: 600, pt: 2, mb: 1 }}>
      Scope Of Work
    </Typography>

    {arr.map(
      (el, idx) =>
        el.flexibleItems.length > 0 && (
          <Fragment key={idx}>
            {el.flexibleItems.length < 2 ? (
              <Box sx={{ borderBottom: '1px solid #ccc', pb: 1, pt: 1, fontSize: 12 }}>
                <Typography sx={{ fontSize: 12 }}>{el.flexibleItems[0].item.description}</Typography>
                {el.flexibleItems[0].item.resources &&
                  el.flexibleItems[0].item.resources.map((el, resource_index) => (
                    <Box key={resource_index}>
                      {` | `}
                      <span>Files: {resource_index + 1}</span>
                    </Box>
                  ))}
              </Box>
            ) : (
              <FormControl sx={{ mt: 1, borderBottom: '1px solid #ccc', pb: 1, pt: 1 }} fullWidth>
                <FormLabel id="option" sx={{ fontSize: 12 }}>
                  Select an option
                </FormLabel>
                <RadioGroup
                  aria-labelledby="option"
                  name="option"
                  value={el.selectedItemIndex}
                  onChange={(event) => handleChange(event, system_index, 'scopeOfWorks', el)}
                >
                  {el.flexibleItems.map((item, item_index) => (
                    <FormControlLabel
                      value={item_index}
                      key={item_index}
                      control={<Radio size="small" />}
                      label={
                        <Fragment>
                          <Typography sx={{ fontSize: 12 }}>{item.item.description}</Typography>
                          {item.item.resources &&
                            item.item.resources.map((el, resource_index) => (
                              <Box key={resource_index}>
                                {` | `}
                                <span>Files: {resource_index + 1}</span>
                              </Box>
                            ))}
                        </Fragment>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </Fragment>
        ),
    )}
  </Fragment>
);

export default ProposalViewSystemWorks;
