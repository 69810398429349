import api from '../api/axios';

const getSettingsList = () => {
  const response = api.get(`/setting/company`);

  return response;
};

const update = (updatedData) => {
  const response = api.patch(`/setting/company`, updatedData);

  return response;
};

const settingsServices = {
  getSettingsList,
  update,
};

export default settingsServices;
