import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import {
  Autocomplete,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// Utils
import messages from '../../static/messages';

// Hooks
import { usePropertiesAutocomplete } from '../../hooks/useAutocomplete';

// Services
import appointmentsServices from '../../services/appointmentsServices';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['property', 'type', 'notes', 'source'];

export const appointment_times = [
  { time12: '07:00 AM', time24: '07:00' },
  { time12: '07:15 AM', time24: '07:15' },
  { time12: '07:30 AM', time24: '07:30' },
  { time12: '07:45 AM', time24: '07:45' },
  { time12: '08:00 AM', time24: '08:00' },
  { time12: '08:15 AM', time24: '08:15' },
  { time12: '08:30 AM', time24: '08:30' },
  { time12: '08:45 AM', time24: '08:45' },
  { time12: '09:00 AM', time24: '09:00' },
  { time12: '09:15 AM', time24: '09:15' },
  { time12: '09:30 AM', time24: '09:30' },
  { time12: '09:45 AM', time24: '09:45' },
  { time12: '10:00 AM', time24: '10:00' },
  { time12: '10:15 AM', time24: '10:15' },
  { time12: '10:30 AM', time24: '10:30' },
  { time12: '10:45 AM', time24: '10:45' },
  { time12: '11:00 AM', time24: '11:00' },
  { time12: '11:15 AM', time24: '11:15' },
  { time12: '11:30 AM', time24: '11:30' },
  { time12: '11:45 AM', time24: '11:45' },
  { time12: '12:00 PM', time24: '12:00' },
  { time12: '12:15 PM', time24: '12:15' },
  { time12: '12:30 PM', time24: '12:30' },
  { time12: '12:45 PM', time24: '12:45' },
  { time12: '01:00 PM', time24: '13:00' },
  { time12: '01:15 PM', time24: '13:15' },
  { time12: '01:30 PM', time24: '13:30' },
  { time12: '01:45 PM', time24: '13:45' },
  { time12: '02:00 PM', time24: '14:00' },
  { time12: '02:15 PM', time24: '14:15' },
  { time12: '02:30 PM', time24: '14:30' },
  { time12: '02:45 PM', time24: '14:45' },
  { time12: '03:00 PM', time24: '15:00' },
  { time12: '03:15 PM', time24: '15:15' },
  { time12: '03:30 PM', time24: '15:30' },
  { time12: '03:45 PM', time24: '15:45' },
  { time12: '04:00 PM', time24: '16:00' },
  { time12: '04:15 PM', time24: '16:15' },
  { time12: '04:30 PM', time24: '16:30' },
  { time12: '04:45 PM', time24: '16:45' },
  { time12: '05:00 PM', time24: '17:00' },
  { time12: '05:15 PM', time24: '17:15' },
  { time12: '05:30 PM', time24: '17:30' },
  { time12: '05:45 PM', time24: '17:45' },
  { time12: '06:00 PM', time24: '18:00' },
  { time12: '06:15 PM', time24: '18:15' },
  { time12: '06:30 PM', time24: '18:30' },
  { time12: '06:45 PM', time24: '18:45' },
  { time12: '07:00 PM', time24: '19:00' },
  { time12: '07:15 PM', time24: '19:15' },
  { time12: '07:30 PM', time24: '19:30' },
  { time12: '07:45 PM', time24: '19:45' },
  { time12: '08:00 PM', time24: '20:00' },
  { time12: '08:15 PM', time24: '20:15' },
  { time12: '08:30 PM', time24: '20:30' },
  { time12: '08:45 PM', time24: '20:45' },
  { time12: '09:00 PM', time24: '21:00' },
  { time12: '09:15 PM', time24: '21:15' },
  { time12: '09:30 PM', time24: '21:30' },
  { time12: '09:45 PM', time24: '21:45' },
  { time12: '10:00 PM', time24: '22:00' },
  { time12: '10:15 PM', time24: '22:15' },
  { time12: '10:30 PM', time24: '22:30' },
  { time12: '10:45 PM', time24: '22:45' },
  { time12: '11:00 PM', time24: '23:00' },
];

const AppointmentDrawer = (props) => {
  const { currentAppointment, setCurrentAppointment, createAppointment, technicians } = props;
  const [errors, setErrors] = useState({});
  const [types, setTypes] = useState([]);
  const [sources, setSources] = useState([]);

  moment.tz.setDefault('America/Los_Angeles');

  const { handleSearchProperty, propertyOptions } = usePropertiesAutocomplete(2, false);

  // Action functions
  const resetErrors = () => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const resetQueries = () => handleSearchProperty('');

  const onPropertySelected = (event, val) => {
    event.preventDefault();
    if (val) {
      setCurrentAppointment((prevState) => ({
        ...prevState,
        property: {
          id: val.id,
        },
        isEdited: true,
      }));
    } else {
      setCurrentAppointment({
        property: {
          id: '',
        },
        isEdited: false,
      });
    }
    resetQueries();
  };

  // Handler functions
  const handleValidate = () => {
    required_fields.forEach((field) => {
      if (currentAppointment[field] === '' || currentAppointment[field] === null || !currentAppointment[field]) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
  };

  const handleChange = (event) => {
    const key = event.target?.name || 'date';
    const val = event.target?.value;

    setCurrentAppointment((prevState) => ({
      ...prevState,
      [key]: event.target?.name ? val : moment.utc(event.$d).format('MM/DD/YYYY'),
      isEdited: true,
    }));
  };

  const handleClose = () => props.onCloseDrawer(resetErrors);

  // Async functions
  const getTypes = async () => {
    try {
      const response = await appointmentsServices.getAppointmentTypes(false);

      if (response.status === 206) setTypes(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      const response = await appointmentsServices.getAppointmentSources(false);

      if (response.status === 206) setSources(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    if (currentAppointment && !currentAppointment?.isEdited) {
      getTypes();
      getSources();
    }
  }, [currentAppointment]);

  useEffect(() => {
    if (currentAppointment?.isEdited) {
      !Object.values(errors).includes(true) && createAppointment();
    }
  }, [errors]);

  // console.log(currentAppointment);

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        {currentAppointment && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Add New Appointment
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                options={propertyOptions}
                getOptionLabel={(option) => option?.address}
                filterOptions={(x) => x}
                onChange={onPropertySelected}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Property"
                    onChange={(e) => handleSearchProperty(e.target.value)}
                    error={errors.property}
                    helperText={errors.property && messages.errors.required_field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="technician" error={errors.technician}>
                  Preferred Technician
                </InputLabel>
                <Select
                  labelId="technician"
                  name="technician"
                  value={currentAppointment?.technician || ''}
                  label="Preferred Technician"
                  onChange={handleChange}
                  error={errors.technician}
                >
                  {technicians?.map((tech) => (
                    <MenuItem key={tech.tech_id} value={tech.tech_id}>
                      {tech.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.technician && (
                  <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type" error={errors.type}>
                  Appointment Type *
                </InputLabel>
                <Select
                  labelId="type"
                  name="type"
                  value={currentAppointment?.type || ''}
                  label="Appointment Type *"
                  onChange={handleChange}
                  error={errors.type}
                >
                  {types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.type && (
                  <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="source" error={errors.source}>
                  Source *
                </InputLabel>
                <Select
                  labelId="source"
                  name="source"
                  value={currentAppointment?.source || ''}
                  label="Source *"
                  onChange={handleChange}
                  required
                  error={errors.source}
                >
                  {sources.map((source) => (
                    <MenuItem key={source.id} value={source.id}>
                      {source.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.source && (
                  <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="MM/DD/YYYY"
                  value={currentAppointment.date}
                  onChange={handleChange}
                  disablePast
                  renderInput={(params) => <TextField {...params} name="date" fullWidth error={false} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="startAt">Start Time</InputLabel>
                    <Select
                      labelId="startAt"
                      name="startAt"
                      value={currentAppointment?.startAt}
                      label="Start Time *"
                      onChange={handleChange}
                      disabled={!currentAppointment.date}
                    >
                      {appointment_times.map((time, index) => (
                        <MenuItem key={index} value={time.time24}>
                          {time.time12}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="finishAt">End Time</InputLabel>
                    <Select
                      labelId="finishAt"
                      name="finishAt"
                      value={currentAppointment?.finishAt || ''}
                      label="End Time"
                      onChange={handleChange}
                      disabled={!currentAppointment.startAt}
                    >
                      {appointment_times.map((time, index) => (
                        <MenuItem key={index} value={time.time24}>
                          {time.time12}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="notes"
                label="Appointment Notes"
                value={currentAppointment?.notes}
                rows={4}
                onChange={handleChange}
                fullWidth
                multiline
                required
                error={errors.notes}
                helperText={errors.notes && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                onClick={handleValidate}
                disabled={!currentAppointment?.isEdited}
              >
                Create Appointment
              </Button>

              <Button variant="contained" onClick={handleClose} sx={button('secondary')}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default AppointmentDrawer;
