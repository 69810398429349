import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Paper,
  Grid,
  InputLabel,
  Link,
  Button,
  TextField,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';

// Custom components
import { Spinner, AppointmentNotes } from '../../';

// Utils
import messages from '../../../static/messages';

// Services
import appointmentsServices from '../../../services/appointmentsServices';
import userServices from '../../../services/userServices';
import proposalsServices from '../../../services/proposalsServices';
import propertiesServices from '../../../services/propertiesServices';

// Styles
import { flex_center } from '../../../static/styles';
import { button } from '../../../static/theme-styles';

// External variables
import { appointment_times } from '../../Drawers/appointment.drawer';

const AppointmentDetailsTable = ({ appointment, setAppointment, id, refresh }) => {
  const [userRole, setUserRole] = useState();
  const [techniciansList, setTechniciansList] = useState();
  const [types, setTypes] = useState();
  const [sources, setSources] = useState();
  const [updates, setUpdates] = useState({
    isEdited: false,
  });
  const [propertyComment, setPropertyComment] = useState();

  const confirm = useConfirm();
  const navigate = useNavigate();

  // Handler functions
  const handleChangeStatus = (event) => {
    confirm({
      title: messages.confirm_message.update,
      description: messages.confirm_message.update_description('status'),
    })
      .then(() => updateStatus(event.target.value))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleChange = (data) => {
    setUpdates((prevState) => ({
      ...prevState,
      ...data,
      isEdited: true,
    }));

    setAppointment((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleChangeDate = (event) => {
    const start = appointment?.startTime?.split('T')[1] || '16:00:00.000Z';
    const end = appointment?.endTime?.split('T')[1] || '17:00:00.000Z';
    const date = moment.utc(event.$d).format('YYYY-MM-DD');

    const newStartTime = date + 'T' + start;
    const newEndTime = date + 'T' + end;

    setUpdates((prevState) => ({
      ...prevState,
      startTime: newStartTime,
      endTime: newEndTime,
      isEdited: true,
    }));

    setAppointment((prevState) => ({
      ...prevState,
      startTime: newStartTime,
      endTime: newEndTime,
    }));
  };

  const handleChangeTime = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    const date = appointment.startTime.split('T')[0];

    const newTime = moment(date)
      .set({
        hours: val.split(':')[0],
        minutes: val.split(':')[1],
        seconds: '0',
      })
      .utcOffset('-0800');

    setUpdates((prevState) => ({
      ...prevState,
      [key]: moment.utc(newTime).format(),
      isEdited: true,
    }));

    setAppointment((prevState) => ({
      ...prevState,
      [key]: moment.utc(newTime).format(),
    }));
  };

  const handleRemove = () => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Appointment'),
    })
      .then(() => removeAppointment())
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleToQueue = () => {
    confirm({
      title: messages.confirm_message.update,
      description: messages.confirm_message.update_description('Appointment'),
    })
      .then(() => backToQueue())
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleDuplicate = () => {
    confirm({
      title: messages.confirm_message.copy,
      description: messages.confirm_message.copy_description('Appointment'),
    })
      .then(() => duplicate())
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const getTechniciansList = async () => {
    try {
      const response = await userServices.getUserByRole('technician');

      if (response.status === 206) {
        setTechniciansList(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getTypes = async () => {
    try {
      const response = await appointmentsServices.getAppointmentTypes(false);

      if (response.status === 206) setTypes(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      const response = await appointmentsServices.getAppointmentSources(false);

      if (response.status === 206) setSources(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateDetails = async () => {
    try {
      const response = await appointmentsServices.update(id, updates);
      if (response.status === 200) {
        setUpdates({
          isEdited: false,
        });
        toast.success(`Appointment details updated successfully`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateStatus = async (status) => {
    try {
      const response = await appointmentsServices.update(id, {
        status,
      });

      if (response.status === 200) {
        toast.success(`Status successfully updated to ${status}`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const addTimeStamp = async (mode) => {
    const date = moment().utcOffset('-0800');

    try {
      const response = await appointmentsServices.update(appointment?.id, {
        ...(mode === 'checkIn' && { checkIn: moment.utc(date).format() }),
        ...(mode === 'checkOut' && { checkOut: moment.utc(date).format() }),
      });

      if (response.status === 200) {
        toast.success(mode === 'checkIn' ? 'Check In' : 'Check Out');
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeAppointment = async () => {
    try {
      const response = await appointmentsServices.remove(id);

      if (response.status === 200) {
        toast.success(messages.appointment.remove);
        navigate('/appointments');
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const backToQueue = async () => {
    try {
      const response = await appointmentsServices.update(id, {
        startTime: null,
        endTime: null,
      });

      if (response.status === 200) {
        toast.success(messages.success.update.common);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const duplicate = async () => {
    const data = {
      status: 'Active',
      type: {
        id: appointment.type.id,
      },
      source: {
        id: appointment.source.id,
      },
      property: {
        id: appointment.property.id,
      },
    };

    try {
      const response = await appointmentsServices.create(data);

      if (response.status === 201) navigate('/appointments');
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const createProposal = async () => {
    const data = {
      property: {
        id: appointment?.property?.id,
      },
      client: {
        id: appointment?.property?.clients[0]?.client?.id,
      },
      emailTo: [appointment?.property?.clients[0]?.client?.id],
      status: 'Active',
    };

    try {
      const response = await proposalsServices.create(data);

      if (response.status === 200) navigate(`/proposals/${response.data.id}`);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const addPropertyNote = async () => {
    const notes = [
      ...appointment.property.notes,
      {
        note: propertyComment,
      },
    ];

    try {
      const response = await propertiesServices.update(appointment.property.id, { notes });

      if (response.status === 200) {
        setPropertyComment(null);
        toast.success(messages.success.update.note.save);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getTechniciansList();
    getTypes();
    getSources();

    const role = JSON.parse(localStorage.getItem('user')).role;

    setUserRole(role);
  }, []);

  if (!techniciansList && !types && !sources && !userRole) return <Spinner />;

  return (
    <Fragment>
      <Box
        sx={{
          ...flex_center,
          mt: 2,
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: {
            xs: 'flex-start !important',
            md: 'center !important',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
          <Link href={`/properties/${appointment?.property?.id}`} underline="hover" color="rgba(0, 0, 0, 0.87)">
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {appointment?.property?.address}
            </Typography>
          </Link>
          <Link
            href={`https://www.google.com/maps/place/${appointment.property.address}`}
            variant="contained"
            sx={{ ml: 2 }}
            target="_blank"
          >
            Navigate To Location
          </Link>

          {!appointment?.checkIn && (
            <Button
              variant="contained"
              onClick={() => addTimeStamp('checkIn')}
              size="large"
              sx={{ ...button('secondary'), ml: 2 }}
              endIcon={<TimerIcon />}
              disabled={!appointment.user}
            >
              Check In
            </Button>
          )}

          {appointment?.checkIn && (
            <Button
              variant="contained"
              onClick={() => addTimeStamp('checkOut')}
              size="large"
              sx={{ ...button('secondary'), ml: 2 }}
              disabled={!!appointment?.checkOut}
              endIcon={<TimerOffIcon />}
            >
              Check Out
            </Button>
          )}
        </Box>

        <FormControl sx={{ minWidth: 120, maxWidth: 150, mt: { xs: 3, md: 0 } }} size="small">
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            variant="outlined"
            value={appointment?.status}
            name="status"
            onChange={handleChangeStatus}
            label="Status"
            sx={{
              '& > div': {
                ...flex_center,
              },
            }}
          >
            <MenuItem value="Active">
              <CircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} /> Active
            </MenuItem>
            <MenuItem value="In Progress">
              <CircleIcon sx={{ color: 'orange', mr: 1, width: 15, height: 15 }} /> In Progress
            </MenuItem>
            <MenuItem value="Completed">
              <CheckCircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} /> Completed
            </MenuItem>
            <MenuItem value="Cancelled">
              <CancelIcon sx={{ color: 'red', mr: 1, width: 15, height: 15 }} /> Cancelled
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: 600, mb: 1 }}>Appointment Info:</Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {appointment?.property.unit && (
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600, width: '50%' }}>Unit</TableCell>
                    <TableCell>{appointment?.property.unit}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>Appointment Date</TableCell>
                  <TableCell>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="MM/DD/YYYY"
                        value={moment(appointment?.startTime).format('MM/DD/YYYY')}
                        onChange={handleChangeDate}
                        disabled={userRole === 'technician' || !appointment.user}
                        disablePast
                        renderInput={(params) => (
                          <TextField {...params} name="date" fullWidth error={false} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>Start Time</TableCell>
                  <TableCell>
                    <Select
                      name="startTime"
                      value={
                        appointment_times.filter(
                          (el) =>
                            el.time12 === moment(appointment?.startTime).tz('America/Los_Angeles').format('hh:mm A'),
                        )[0]?.time24 || ''
                      }
                      onChange={handleChangeTime}
                      size="small"
                      fullWidth
                      disabled={userRole === 'technician' || !appointment?.startTime}
                    >
                      {appointment_times.map((time, index) => (
                        <MenuItem key={index} value={time.time24}>
                          {time.time12}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>End Time</TableCell>
                  <TableCell>
                    <Select
                      name="endTime"
                      value={
                        appointment_times.filter(
                          (el) =>
                            el.time12 === moment(appointment?.endTime).tz('America/Los_Angeles').format('hh:mm A'),
                        )[0]?.time24 || ''
                      }
                      onChange={handleChangeTime}
                      size="small"
                      fullWidth
                      disabled={userRole === 'technician' || !appointment?.startTime}
                    >
                      {appointment_times.map((time, index) => (
                        <MenuItem key={index} value={time.time24}>
                          {time.time12}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>Created By</TableCell>
                  <TableCell>
                    {appointment?.createdBy.fullName} ({moment.utc(appointment?.createdAt).format('L, h:mm:ss a')})
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 2 }}>
            <Typography sx={{ fontWeight: 600, mb: 1 }}>Property Notes:</Typography>
            {appointment?.property?.notes.map((el) => (
              <Fragment key={el.id}>
                <Box alignItems="center" sx={{ display: 'flex', mt: 1 }} key={el.id}>
                  <Typography variant="body1">
                    {el.note}
                    {` `}
                    <Typography variant="caption" sx={{ fontStyle: 'italic ' }}>
                      (Created by <strong>{el.createdBy.fullName}</strong> at{' '}
                      {moment.utc(el.createdAt).format('L, h:mm:ss a')})
                    </Typography>
                  </Typography>
                </Box>
                <Divider sx={{ mt: 1 }} />
              </Fragment>
            ))}

            <Box sx={{ mt: 3 }}>
              <TextField
                label="New Note"
                multiline
                rows={4}
                value={propertyComment || ''}
                fullWidth
                onChange={(e) => setPropertyComment(e.target.value)}
              />

              <Button
                variant="contained"
                size="small"
                sx={{ ...button('secondary'), mt: 3 }}
                onClick={addPropertyNote}
                disabled={!propertyComment}
              >
                Add Note
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ mt: 4 }}>
          {techniciansList && (
            <FormControl fullWidth>
              <InputLabel id="technician">Preferred Technician</InputLabel>
              <Select
                labelId="technician"
                name="technician"
                value={appointment?.user?.id || ''}
                label="Preferred Technician"
                onChange={(event) =>
                  handleChange({
                    user: {
                      id: event.target.value,
                    },
                  })
                }
                disabled={userRole === 'technician'}
              >
                {techniciansList?.map((technician) => (
                  <MenuItem key={technician.id} value={technician.id}>
                    {technician.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {types && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="type">Appointment Type</InputLabel>
              <Select
                labelId="type"
                name="type"
                value={appointment?.type.id || ''}
                label="Appointment Type"
                onChange={(event) =>
                  handleChange({
                    type: {
                      id: event.target.value,
                    },
                  })
                }
                disabled={userRole === 'technician'}
              >
                {types.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {sources && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="source">Appointment Source</InputLabel>
              <Select
                labelId="source"
                name="source"
                value={appointment?.source?.id || ''}
                label="Appointment Source"
                onChange={(event) =>
                  handleChange({
                    source: {
                      id: event.target.value,
                    },
                  })
                }
                disabled={userRole === 'technician'}
              >
                {sources.map((source) => (
                  <MenuItem key={source.id} value={source.id}>
                    {source.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Box sx={{ mt: 2 }}>
            <AppointmentNotes notesList={appointment?.notes} id={id} refresh={refresh} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={updateDetails}
            disabled={!updates.isEdited}
            size="large"
            sx={button('primary', 'secondary')}
          >
            Save
          </Button>

          <Button
            variant="contained"
            onClick={handleToQueue}
            size="large"
            sx={{ ...button('secondary'), ml: { md: 2, xs: 1 } }}
            disabled={!appointment.startTime || userRole !== 'superadmin'}
          >
            Put Back In Queue
          </Button>

          {(userRole === 'superadmin' || userRole === 'admin' || userRole === 'dispatcher') && (
            <Button
              variant="contained"
              onClick={() => handleDuplicate(appointment.id)}
              size="large"
              sx={{ ...button('secondary'), ml: { md: 2, xs: 0 } }}
              endIcon={<FileCopyIcon />}
            >
              Duplicate
            </Button>
          )}

          {(userRole === 'technician' || userRole === 'superadmin') && (
            <Fragment>
              <Button
                variant="contained"
                onClick={createProposal}
                size="large"
                sx={{ ...button('green', 'green_shade'), ml: { md: 2, xs: 1 }, my: { md: 0, xs: 2 } }}
                endIcon={<AddIcon />}
              >
                Create Proposal
              </Button>
            </Fragment>
          )}

          <Button
            variant="contained"
            color="error"
            onClick={handleRemove}
            size="large"
            sx={{ ...button('primary', 'primary_shade'), float: { md: 'right', xs: 'none' } }}
            disabled={userRole !== 'superadmin'}
          >
            Delete Appointment
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AppointmentDetailsTable;
