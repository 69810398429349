import { useState, Fragment } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Button,
  IconButton,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
} from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Custom Components
import { AppointmentMediaDrawer } from '../..';

// Utils
import messages from '../../../static/messages';

// Services
import appointmentsServices from '../../../services/appointmentsServices';

// Hooks
import usePagination from '../../../hooks/usePagination';
import useDrawer from '../../../hooks/useDrawer';

// Styles
import { actions_column, table_container_base } from '../../../static/styles';
import { button } from '../../../static/theme-styles';

// External variables
const base_media = {
  name: '',
  description: '',
  resource: {},
};

const AppointmentMediaTable = ({ mediaList, id, refresh }) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [isNewItem, setDrawerMode] = useState(false);

  const confirm = useConfirm();
  const { drawer, openDrawer, foolCheck } = useDrawer(currentItem);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setDrawerMode(false);
      setCurrentItem(null);
      resetErrors && resetErrors();
    }
  };

  const onAddMedia = () => {
    setCurrentItem(base_media);
    setDrawerMode(true);
    openDrawer();
  };

  const onEditMedia = (id) => {
    const media = mediaList.filter((el) => el.id === id)[0];
    delete media.createdAt;
    delete media.deletedAt;
    delete media.updatedAt;
    setCurrentItem(media);
    openDrawer();
  };

  // Handler functions
  const handleDelete = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Media'),
    })
      .then(() => removeMedia(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  // Async functions
  const addNewMedia = async (resetFile) => {
    delete currentItem.isEdited;

    const updatedMedia = {
      media: [...mediaList, currentItem],
    };

    try {
      const response = await appointmentsServices.update(id, updatedMedia);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.property.update);
        setCurrentItem(null);
        refresh();
        resetFile();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeMedia = async (mediaId) => {
    const updatedMedia = {
      media: [...mediaList.filter((el) => el.id !== mediaId)],
    };

    try {
      const response = await appointmentsServices.update(id, updatedMedia);

      if (response.status === 200) {
        toast.success(messages.property.update);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateMedia = async () => {
    delete currentItem.isEdited;

    const updatedMedia = {
      media: [...mediaList.filter((el) => el.id !== currentItem.id), currentItem],
    };

    try {
      const response = await appointmentsServices.update(id, updatedMedia);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.property.update);
        setCurrentItem(null);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        onClick={onAddMedia}
        endIcon={<AddIcon />}
        sx={{ ...button('secondary'), color: '#fff', mb: 2 }}
      >
        Add Media
      </Button>

      <TableContainer component={Paper} sx={table_container_base}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="15%">Uploaded at</TableCell>
              <TableCell width="15%">Uploaded by</TableCell>
              <TableCell width="15%">File Name</TableCell>
              <TableCell width="50%">Description</TableCell>
              <TableCell width="5%">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mediaList?.length ? (
              mediaList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((media) => (
                <TableRow key={media?.id}>
                  <TableCell scope="row">{moment(media.resource.createdAt).format('MMM Do YYYY')}</TableCell>
                  <TableCell>{media.resource.createdBy.fullName}</TableCell>
                  <TableCell>{media.name}</TableCell>
                  <TableCell>{media.description}</TableCell>
                  <TableCell sx={actions_column}>
                    <Tooltip title="Download Media">
                      <IconButton aria-label="download" onClick={() => handleDownload(media.resource.url)}>
                        <FileDownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Media">
                      <IconButton aria-label="edit" onClick={() => onEditMedia(media.id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Media">
                      <IconButton aria-label="remove" onClick={() => handleDelete(media.id)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={mediaList?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Clients per page"
      />

      <AppointmentMediaDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        isNewItem={isNewItem}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        addNewMedia={addNewMedia}
        updateMedia={updateMedia}
      />
    </Fragment>
  );
};

export default AppointmentMediaTable;

//  appointment-media
