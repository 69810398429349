import api from '../api/axios';

const getAllClients = (skip = 0, take = 1000000, withDeleted) => {
  const response = api.get(
    `/client?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  );

  return response;
};

const getClientById = (id) => {
  const response = api.get(`/client/${id}`);

  return response;
};

const create = (client) => {
  const response = api.post(`/client`, client);

  return response;
};

const update = (id, updatedClient) => {
  const response = api.patch(`/client/${id}`, updatedClient);

  return response;
};

const remove = (id) => {
  const response = api.delete(`/client/${id}`);

  return response;
};

const restore = (id) => {
  const response = api.get(`/client/${id}/restore`);

  return response;
};

const searchRequest = (skip = 0, take = 1000000, query, widthDeleted = false, options = {}) => {
  const response = api.get(`/client?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&query=${query}`, { ...options });

  return response;
};

const getClientToken = (id) => {
  const response = api.get(`client/${id}/token`);

  return response;
};

const clientsServices = {
  getAllClients,
  getClientById,
  create,
  update,
  remove,
  restore,
  searchRequest,
  getClientToken,
};

export default clientsServices;
