import api from '../api/axios';

const getAllEquipment = (skip = 0, take = 1000000, withDeleted) => {
  const response = api.get(
    `/equipment?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  );

  return response;
};

const getEquipmentById = (id) => {
  const response = api.get(`/equipment/${id}`);

  return response;
};

const create = (item) => {
  const response = api.post(`/equipment`, item);

  return response;
};

const update = (id, updatedItem) => {
  const response = api.patch(`/equipment/${id}`, updatedItem);

  return response;
};

const remove = (id) => {
  const response = api.delete(`/equipment/${id}`);

  return response;
};

const restore = (id) => {
  const response = api.get(`/equipment/${id}/restore`);

  return response;
};

const search = (skip = 0, take = 1000000, query, widthDeleted = false, options = {}) => {
  const response = api.get(`/equipment?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&query=${query}`, { ...options });

  return response;
};

const fileImport = (newFile) => {
  const response = api.post(
    '/equipment/import',
    {
      file: newFile,
    },
    {
      headers: { accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    },
  );

  return response;
};

const fileExport = () => {
  const response = api.get(`/equipment/export`);

  return response;
};

const getEquipmentKit = () => {
  const response = api.get(`/equipment/kit`);

  return response;
};

const find = (type, make, model, size) => {
  const response = api.get(
    `equipment?skip=0&take=1000000&sort=id%2Cdesc&withDeleted=false&type=${type}&make=${make}&model=${model}&size=${size}`,
  );

  return response;
};

const equipmentServices = {
  getAllEquipment,
  getEquipmentById,
  create,
  update,
  remove,
  restore,
  search,
  fileImport,
  fileExport,
  getEquipmentKit,
  find,
};

export default equipmentServices;
