import { Fragment } from 'react';

import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material';

// Utils
import { formatPhone } from '../../../utils/formatter';

// Hooks
import usePagination from '../../../hooks/usePagination';

// Styles
import { table_container_base } from '../../../static/styles';

const link_styles = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const AppointmentClientsTable = ({ clients }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  return (
    <Fragment>
      <TableContainer component={Paper} sx={table_container_base}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="25%">Name</TableCell>
              <TableCell width="25%">Relation</TableCell>
              <TableCell width="25%">Email</TableCell>
              <TableCell width="25%">Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients?.length ? (
              clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link
                      href={`/clients/${client.client.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {client.client.fullName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/clients/${client.client.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {client.relation}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/clients/${client.client.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {client.client.email}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/clients/${client.client.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {formatPhone(client.client.phoneNumber)}
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={clients?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Clients per page"
      />
    </Fragment>
  );
};

export default AppointmentClientsTable;
