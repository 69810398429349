import api from '../api/axios'

const getAllAppointments = (date, id, forQueue) => {
  const response = api.get(
    `/appointment?skip=0&take=1000000&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=false${
      date ? `&date=${date}` : ''
    }${id ? `&userId=${id}` : ''}${forQueue ? '&forQueue=true' : ''}&withDayOff=true`,
  );

  return response
}

const getAppointmentById = (id) => {
  const response = api.get(`/appointment/${id}`)

  return response
}

const create = (appointment) => {
  const response = api.post(`/appointment`, appointment)

  return response
}

const update = (id, updatedAppointment) => {
  const response = api.patch(`/appointment/${id}`, updatedAppointment)

  return response
}

const remove = (id) => {
  const response = api.delete(`/appointment/${id}`)

  return response
}

const getAppointmentTypes = (withDeleted = true) => {
  const response = api.get(
    `/appointmentType?skip=0&take=1000000&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  )

  return response
}

const getAppointmentTypeById = (id) => {
  const response = api.get(`/appointmentType/${id}`)

  return response
}

const createAppointmentType = (name) => {
  const response = api.post(`/appointmentType`, {
    name,
  })

  return response
}

const removeAppointmentType = (id) => {
  const response = api.delete(`/appointmentType/${id}`)

  return response
}

const restoreAppointmentType = (id) => {
  const response = api.get(`/appointmentType/${id}/restore`)

  return response
}

const getAppointmentSources = (withDeleted = true) => {
  const response = api.get(
    `/appointmentSource?skip=0&take=1000000&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  )

  return response
}

const createAppointmentSource = (name) => {
  const response = api.post(`/appointmentSource`, {
    name,
  })

  return response
}

const removeAppointmentSource = (id) => {
  const response = api.delete(`/appointmentSource/${id}`)

  return response
}

const restoreAppointmentSource = (id) => {
  const response = api.get(`/appointmentSource/${id}/restore`)

  return response
}

const appointmentsServices = {
  getAllAppointments,
  getAppointmentById,
  create,
  update,
  remove,
  getAppointmentTypes,
  createAppointmentType,
  getAppointmentTypeById,
  removeAppointmentType,
  restoreAppointmentType,
  getAppointmentSources,
  createAppointmentSource,
  removeAppointmentSource,
  restoreAppointmentSource,
}

export default appointmentsServices
