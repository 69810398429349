import { useState, useEffect, lazy, Suspense } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TabPanel, BrowserTitle, Spinner } from '../components';

const SettingsTable = lazy(() => import('../components/Tables/Settings/settings.settings'));
const UsersTable = lazy(() => import('../components/Tables/Settings/settings.users'));
const SuppliersTable = lazy(() => import('../components/Tables/Settings/settings.suppliers'));
const AppointmentTable = lazy(() => import('../components/Tables/Settings/settings.appointment'));
const PropertyTable = lazy(() => import('../components/Tables/Settings/settings.propertyTypes'));

function addTabIndex(index) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}

export const Settings = () => {
  const location = window.location.pathname;
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const tabSwitcher = (e, val) => {
    e.preventDefault();
    setActiveTab(val);

    switch (val) {
      case 1:
        history('/settings/users');
        break;
      case 2:
        history('/settings/suppliers');
        break;
      case 3:
        history('/settings/appointment');
        break;
      case 4:
        history('/settings/property');
        break;
      default:
        history('/settings');
        break;
    }
  };

  useEffect(() => {
    switch (location) {
      case '/settings/users':
        setActiveTab(1);
        break;
      case '/settings/suppliers':
        setActiveTab(2);
        break;
      case '/settings/appointment':
        setActiveTab(3);
        break;
      case '/settings/property':
        setActiveTab(4);
        break;
      default:
        setActiveTab(0);
        break;
    }
  }, [location]);

  return (
    <Box component="section" id="settings-page">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={tabSwitcher} aria-label="settings tabs">
            <Tab
              label="Settings"
              {...addTabIndex(0)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Users"
              {...addTabIndex(1)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Suppliers"
              {...addTabIndex(2)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Appointment"
              {...addTabIndex(3)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Property"
              {...addTabIndex(4)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          {activeTab === 0 && <BrowserTitle title="Settings | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <SettingsTable />
          </Suspense>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          {activeTab === 1 && <BrowserTitle title="Users | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <UsersTable />
          </Suspense>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          {activeTab === 2 && <BrowserTitle title="Suppliers | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <SuppliersTable />
          </Suspense>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          {activeTab === 3 && <BrowserTitle title="Appointment | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <AppointmentTable />
          </Suspense>
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          {activeTab === 3 && <BrowserTitle title="Property | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <PropertyTable />
          </Suspense>
        </TabPanel>
      </Box>
    </Box>
  );
};
