import { useState, useEffect, useContext, Fragment } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import {
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

// Menu Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import MessageIcon from '@mui/icons-material/Message';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupIcon from '@mui/icons-material/Group';
// import ReceiptIcon from '@mui/icons-material/Receipt';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HouseIcon from '@mui/icons-material/House';
// import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LogoutIcon from '@mui/icons-material/Logout';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import { Outlet, NavLink, useNavigate } from 'react-router-dom';

import { Spinner } from '../';

import permissions from '../../static/permissions';

import { AuthContext } from '../../context/AuthProvider';

import './layout.scss';

const navigation = [
  {
    text: 'Appointments',
    icon: <CalendarMonthIcon />,
    permission: permissions.matrix,
  },
  {
    text: 'Schedule',
    icon: <EventNoteIcon />,
    permission: permissions.schedule,
  },
  {
    text: 'Time-Tracking',
    icon: <AccessTimeIcon />,
    permission: permissions.timer,
  },
  // {
  //   text: 'Messages',
  //   icon: <MessageIcon />,
  //   permission: permissions.messages,
  // },
  {
    text: 'Proposals',
    icon: <BusinessCenterIcon />,
    permission: permissions.proposals,
  },
  {
    text: 'Clients',
    icon: <GroupIcon />,
    permission: permissions.clients,
  },
  // {
  //   text: 'Invoices',
  //   icon: <ReceiptIcon />,
  //   permission: permissions.invoices,
  // },
  {
    text: 'Items',
    icon: <SummarizeIcon />,
    permission: permissions.items,
  },
  {
    text: 'Properties',
    icon: <HouseIcon />,
    permission: permissions.properties,
  },
  // {
  //   text: 'Installers',
  //   icon: <BuildIcon />,
  //   permission: permissions.installers,
  // },
  {
    text: 'Settings',
    icon: <SettingsIcon />,
    permission: permissions.settings,
  },
  // {
  //   text: 'Reminders',
  //   icon: <AccessAlarmIcon />,
  //   permission: permissions.reminders,
  // },
  {
    text: 'Logs',
    icon: <ReceiptLongIcon />,
    permission: permissions.logs,
  },
];

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 25px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 25px)`,
  },
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Layout = () => {
  const [open, setOpen] = useState();

  const { userData, logout } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const drawerToggle = () => setOpen(!open);

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem('user'));

    if (userLocal) setOpen(userLocal.isDrawerOpen);
    else if (userData) setOpen(userData.preferences.drawer);
  }, [userData]);

  useEffect(() => {
    if (window.innerWidth < 900) setOpen(false);
  }, [window.location.pathname]);

  if (!userData) return <Spinner />;

  return (
    <Fragment>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <AppBar position="fixed" open={open} sx={{ backgroundColor: '#F0F3F5', color: '#0D3B59' }}>
          <Toolbar sx={{ width: '100%' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ fontFamily: "'Days One', sans-serif" }}>
              Welcome To CRM
            </Typography>

            <Tooltip title="Technical Support">
              <IconButton color="inherit" onClick={() => navigate('/support')} sx={{ margin: '0 0 0 auto' }}>
                <ContactSupportIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <Box
              sx={{
                pt: 1,
                pb: 1,
                img: {
                  height: 60,
                  width: 'auto',
                  objectFit: 'cover',
                },
              }}
            >
              <img src="/images/logo.svg" alt="Logo" />
            </Box>
            <IconButton onClick={drawerToggle}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {navigation?.map((el, index) => {
              if (userData?.permissions.includes(el.permission)) {
                return (
                  <Fragment key={index}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <NavLink to={el.text.toLocaleLowerCase()} className="navigation-link">
                        <Tooltip title={el.text} placement="right" disableHoverListener={open}>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 0,
                                justifyContent: 'center',
                                flexDirection: open ? 'row' : 'column',
                                alignItems: 'center',
                              }}
                            >
                              {el.icon}
                              {!open && (
                                <Typography variant="body2" sx={{ fontSize: '12px !important' }}>
                                  {el.text}
                                </Typography>
                              )}
                            </ListItemIcon>
                            {open && <ListItemText primary={el.text} />}
                          </ListItemButton>
                        </Tooltip>
                      </NavLink>
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              }
              return false;
            })}

            <ListItem disablePadding sx={{ display: 'block' }}>
              <NavLink to="/profile" className="navigation-link">
                <Tooltip title="Profile" placement="right" disableHoverListener={open}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 0,
                        justifyContent: 'center',
                        flexDirection: open ? 'row' : 'column',
                        alignItems: 'center',
                      }}
                    >
                      <PersonIcon />
                      {!open && (
                        <Typography variant="body2" sx={{ fontSize: '12px !important' }}>
                          Profile
                        </Typography>
                      )}
                    </ListItemIcon>
                    {open && <ListItemText primary="Profile" />}
                  </ListItemButton>
                </Tooltip>
              </NavLink>
            </ListItem>

            <Divider />

            <ListItem disablePadding sx={{ display: 'block' }}>
              <Tooltip title="Log Out" placement="right" disableHoverListener={open}>
                <ListItemButton
                  onClick={logout}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 0,
                      justifyContent: 'center',
                      flexDirection: open ? 'row' : 'column',
                      alignItems: 'center',
                    }}
                  >
                    <LogoutIcon />
                    {!open && (
                      <Typography variant="body2" sx={{ fontSize: '12px !important' }}>
                        Log Out
                      </Typography>
                    )}
                  </ListItemIcon>
                  {open && <ListItemText primary={'Log Out'} />}
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            maxWidth: window.location.pathname === '/appointments' ? 'unset !important' : '100%',
          }}
        >
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
      <ToastContainer />
    </Fragment>
  );
};

export default Layout;
