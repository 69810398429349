import api from '../api/axios';

const update = (updatedData) => {
  const response = api.patch(`/profile`, updatedData);

  return response;
};

const updatePhoto = (photo) => {
  const response = api.patch(
    `/profile/photo`,
    { file: photo },
    {
      headers: { accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    },
  );

  return response;
};

const profileServices = {
  update,
  updatePhoto,
};

export default profileServices;
