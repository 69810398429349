import { Fragment } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Autocomplete,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableContainer,
  Paper,
  Checkbox,
  Grid,
  InputLabel,
  Link,
  Modal,
} from '@mui/material';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Custom components
import { ProposalNotes, NotesBar, InstallationDate, CheckNumber } from '../../';

// Utils
import { formatPhone } from '../../../utils/formatter';
import messages from '../../../static/messages';

// Services
import proposalsServices from '../../../services/proposalsServices';
import locationServices from '../../../services/locationServices';

// Hooks
import { useLocationAutocomplete } from '../../../hooks/useAutocomplete';

// Styles
import { flex_center, select_no_border } from '../../../static/styles';

const autocomplete_styles = {
  border: 'none',
  '.MuiOutlinedInput-root': {
    padding: 0,
  },
  '.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    '&::after': {
      content: `''`,
      borderBottom: '2px solid #1976d2 !important',
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute !important',
      transform: 'scaleX(0) !important',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important',
      pointerEvents: 'none !important',
    },
  },
  '.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
    '&::after': {
      transform: 'scaleX(1) translateX(0) !important',
    },
  },
  input: {
    fontSize: '14px !important',
    paddingLeft: '0 !important',
    border: '0 !important',
    '&:focus': {
      border: '0 !important',
    },
  },
  fieldset: {
    border: 0,
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
    borderTop: '0 !important',
    borderRight: '0 !important',
    borderLeft: '0 !important',
    borderRadius: '0 !important',
  },
};

const ProposalDetailsTable = ({ proposal, id, refresh }) => {
  const confirm = useConfirm();

  const { handleSearchLocation, locationOptions } = useLocationAutocomplete(2);

  // Handler functions
  const handleChangeStatus = (event) => {
    confirm({
      title: messages.confirm_message.update,
      description: messages.confirm_message.update_description('status'),
    })
      .then(() => updateStatus(event.target.value))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleChangeAddress = (event, address) => {
    event.preventDefault();
    if (address !== null)
      confirm({
        title: messages.confirm_message.update,
        description: messages.confirm_message.update_description('billing address'),
      })
        .then(() => getLocationInfo(address))
        .catch(() => toast.info(messages.cancel_message));
  };

  const handleChangeType = (event) => {
    confirm({
      title: messages.confirm_message.update,
      description: messages.confirm_message.update_description('type'),
    })
      .then(() => updateDocumentType(event.target.value))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleToggleToEmailsList = (event, clientId) => {
    const isChecked = event.target.checked;
    let emails = proposal?.emailTo !== null ? [...proposal?.emailTo] : [];

    if (isChecked) {
      emails.push(clientId);
    } else {
      emails = [...proposal?.emailTo?.filter((email) => email !== clientId)];
    }

    confirm({
      title: messages.confirm_message.update,
      description: messages.confirm_message.update_description('emails list'),
    })
      .then(() => toggleEmailsList(emails))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const updateStatus = async (status) => {
    try {
      const response = await proposalsServices.update(id, {
        status,
      });

      if (response.status === 200) {
        toast.success(`Status successfully updated to ${status}`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getLocationInfo = async (address) => {
    try {
      const response = await locationServices.getPlace(address);

      if (response.status === 200) updateBillingAddress(response.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateBillingAddress = async (address) => {
    try {
      const response = await proposalsServices.update(id, {
        billingPlace: address,
      });
      if (response.status === 200) {
        handleSearchLocation('');
        toast.success(`The billing address was successfully updated`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateDocumentType = async (type) => {
    try {
      const response = await proposalsServices.update(id, {
        documentType: type,
      });
      if (response.status === 200) {
        handleSearchLocation('');
        toast.success(`The document type was successfully updated`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const toggleEmailsList = async (emails) => {
    try {
      const response = await proposalsServices.update(id, {
        emailTo: emails,
      });

      if (response.status === 200) {
        toast.success(`The emails list was successfully updated`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Box
        sx={(theme) => ({
          ...flex_center,
          mt: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        })}
      >
        <Link href={`/properties/${proposal.property.id}`} underline="hover" color="rgba(0, 0, 0, 0.87)">
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {proposal?.property.address}
          </Typography>
        </Link>

        <FormControl
          sx={(theme) => ({
            minWidth: 120,
            maxWidth: 150,
            [theme.breakpoints.down('sm')]: {
              mt: 2,
            },
          })}
          size="small"
        >
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            variant="outlined"
            value={proposal?.status}
            name="status"
            onChange={handleChangeStatus}
            label="Status"
            sx={{
              '& > div': {
                ...flex_center,
              },
            }}
          >
            <MenuItem value="Active">
              <CircleIcon sx={{ color: 'lightgrey', mr: 1, width: 15, height: 15 }} /> Active
            </MenuItem>
            <MenuItem value="Pending">
              <CircleIcon sx={{ color: 'orange', mr: 1, width: 15, height: 15 }} /> Pending
            </MenuItem>
            <MenuItem value="Completed">
              <CheckCircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} /> Completed
            </MenuItem>
            <MenuItem value="Cancelled">
              <CancelIcon sx={{ color: 'red', mr: 1, width: 15, height: 15 }} /> Cancelled
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: 600, mb: 1 }}>Proposal Info ({proposal.publicId}):</Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {/* BILLING ADDRESS */}
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>Billing Address</TableCell>
                  <TableCell>
                    <Autocomplete
                      options={locationOptions}
                      getOptionLabel={(option) => option}
                      filterOptions={(x) => x}
                      onChange={handleChangeAddress}
                      value={proposal?.billingPlace?.label}
                      freeSolo
                      sx={autocomplete_styles}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="billingPlace"
                          onChange={(e) => handleSearchLocation(e.target.value)}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* INSTALLATION DATE */}
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>Installation Date</TableCell>
                  <TableCell>
                    <InstallationDate data={proposal?.installationSchedule} id={id} refresh={refresh} />
                  </TableCell>
                </TableRow>

                {/* DOCUMENT TYPE */}
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>Document Type</TableCell>
                  <TableCell>
                    <Select
                      name="documentType"
                      value={proposal?.documentType || 'Proposal'}
                      size="small"
                      onChange={handleChangeType}
                      sx={select_no_border}
                    >
                      <MenuItem value="Proposal">Proposal</MenuItem>
                      <MenuItem value="Contract">Contract</MenuItem>
                      <MenuItem value="Invoice">Invoice</MenuItem>
                      <MenuItem value="Receipt">Receipt</MenuItem>
                      <MenuItem value="Amount Due">Amount Due</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>

                {/* RECEIPT NUMBER */}
                {proposal?.documentType === 'Receipt' && (
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600, width: '50%' }}>Receipt check number</TableCell>
                    <TableCell>
                      <CheckNumber data={proposal} id={id} refresh={refresh} />
                    </TableCell>
                  </TableRow>
                )}

                {/* CREATED BY */}
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, width: '50%' }}>Created By</TableCell>
                  <TableCell>
                    {proposal?.createdBy.fullName} ({moment.utc(proposal?.createdAt).format('L, h:mm:ss a')})
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: 600, mb: 1 }}>Clients related to property:</Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="5%">Notice</TableCell>
                  <TableCell width="25%">Name</TableCell>
                  <TableCell width="15%">Phone</TableCell>
                  <TableCell width="35%">Email</TableCell>
                  <TableCell width="20%">Relation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proposal?.property?.clients.length ? (
                  proposal?.property?.clients?.map((client) => (
                    <TableRow key={client.id}>
                      <TableCell scope="row" sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={proposal?.emailTo?.includes(client.client.id)}
                          onChange={(event) => handleToggleToEmailsList(event, client.client.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Link href={`/clients/${client.client.id}`} underline="hover" color="rgba(0, 0, 0, 0.87)">
                          {client.client.fullName}
                        </Link>
                      </TableCell>
                      <TableCell>{formatPhone(client.client.phoneNumber)}</TableCell>
                      <TableCell scope="row">{client.client.email}</TableCell>
                      <TableCell scope="row">{client.relation}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                      <strong>No Data</strong>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box>
            <ProposalNotes notesList={proposal.notesForInstallers} id={id} refresh={refresh} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography sx={{ fontWeight: 600, mb: 1 }}>Property Notes:</Typography>

            {proposal.property.notes.map((el) => (
              <Fragment key={el.id}>
                <Box alignItems="center" sx={{ display: 'flex', mt: 1 }} key={el.id}>
                  <Typography variant="body1">
                    {el.note}
                    {` `}
                    <Typography variant="caption" sx={{ fontStyle: 'italic ' }}>
                      (Created by <strong>{el.createdBy.fullName}</strong> at{' '}
                      {moment.utc(el.createdAt).format('L, h:mm:ss a')})
                    </Typography>
                  </Typography>
                </Box>
                <Divider sx={{ mt: 1 }} />
              </Fragment>
            ))}
          </Box>
        </Grid>
      </Grid>

      <NotesBar
        orders={proposal?.equipmentOrders}
        data={proposal?.additionalNotes}
        id={id}
        publicId={proposal?.publicId}
        refresh={refresh}
        systems={proposal?.data?.systems}
      />
    </Fragment>
  );
};

export default ProposalDetailsTable;
