import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';

import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Typography } from '@mui/material';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// Custom Components
import {
  Spinner,
  BrowserTitle,
  AppointmentDetailsTable,
  AppointmentAppointmentsTable,
  AppointmentProposalsTable,
  AppointmentClientsTable,
  AppointmentMediaTable,
} from '../components';

// Utils
import messages from '../static/messages';

// Services
import appointmentsServices from '../services/appointmentsServices';

// Hooks
import useAccordion from '../hooks/useAccordion';

// External variables
const blocks = ['details', 'appointments', 'proposals', 'clients', 'media'];

const Appointment = () => {
  const [appointment, setAppointment] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const { open, accordionToggle } = useAccordion(blocks);

  // Async functions
  const getAppointmentData = async () => {
    try {
      const response = await appointmentsServices.getAppointmentById(id);
      setAppointment(response.data);
    } catch (error) {
      if (error.response.data.status === 404) {
        navigate('/404');
      } else {
        toast.error(messages.errors.error_data_loaning);
        console.log(error);
      }
    }
  };

  // Hooks
  useEffect(() => {
    getAppointmentData();
    accordionToggle('details');
  }, []);

  // Check for data loading
  if (!appointment) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title={`Appointment page | AirMaxx Pro`} />

      <Button
        variant="text"
        onClick={() => navigate('/appointments')}
        startIcon={<KeyboardBackspaceIcon />}
        sx={{ mb: 2 }}
      >
        Back to Appointments
      </Button>

      {/* Appointment Details */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.details}
        onChange={() => accordionToggle('details')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Appointment Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AppointmentDetailsTable
            {...{
              appointment,
              setAppointment,
              id,
              refresh: getAppointmentData,
            }}
          />
        </AccordionDetails>
      </Accordion>

      {/* Appointments */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.appointments}
        onChange={() => accordionToggle('appointments')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Appointments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AppointmentAppointmentsTable appointments={appointment.appointments} />
        </AccordionDetails>
      </Accordion>

      {/* Proposals */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.proposals}
        onChange={() => accordionToggle('proposals')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Proposals</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AppointmentProposalsTable proposals={appointment?.proposals} />
        </AccordionDetails>
      </Accordion>

      {/* Clients */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.clients}
        onChange={() => accordionToggle('clients')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Clients</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AppointmentClientsTable clients={appointment.property?.clients} />
        </AccordionDetails>
      </Accordion>

      {/* Media */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.media}
        onChange={() => accordionToggle('media')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Media</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AppointmentMediaTable mediaList={appointment?.media} id={id} refresh={getAppointmentData} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Appointment;
