import api from '../api/axios';

const getSuppliers = () => {
  const response = api.get('/setting/suppliers');

  return response;
};

const update = (updatedData) => {
  const response = api.patch('/setting/suppliers', {
    data: updatedData,
  });

  return response;
};

const suppliersServices = {
  getSuppliers,
  update,
};

export default suppliersServices;
