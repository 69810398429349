import { useState, useEffect, Fragment } from 'react';

import {
  Autocomplete,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

// Utils
import messages from '../../static/messages';

// Services

// Hooks
import { useLocationAutocomplete, usePropertyTypeAutocomplete } from '../../hooks/useAutocomplete';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['address'];

const PropertyDrawer = (props) => {
  const { currentProperty, setCurrentProperty, createProperty, updateProperty } = props;
  const [errors, setErrors] = useState({});

  const { handleSearchLocation, locationOptions } = useLocationAutocomplete(2);
  const { handleSearchType, typeOptions } = usePropertyTypeAutocomplete(1);

  // Action functions
  const resetErrors = () => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const resetQueries = () => {
    handleSearchLocation('');
    handleSearchType('');
  };

  const onAddressSelected = (e, val) => {
    e.preventDefault();
    setCurrentProperty((prevState) => ({
      ...prevState,
      address: val,
      isEdited: true,
    }));
    resetQueries();
  };

  const onPropertyTypeSelected = (e, val) => {
    e.preventDefault();
    setCurrentProperty((prevState) => ({
      ...prevState,
      typeName: val,
      isEdited: true,
    }));
    resetQueries();
  };

  // Handler functions
  const handleValidate = () => {
    required_fields.forEach((field) => {
      const el = currentProperty[field];
      if (el === '' || el === null) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
  };

  const handleClose = () => props.onCloseDrawer(resetErrors);

  // Async functions

  // Hooks
  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    if (currentProperty?.isEdited) {
      !Object.values(errors).includes(true) && (props.isNewProperty ? createProperty() : updateProperty());
    }
  }, [errors]);

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        {currentProperty && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  {props.isNewProperty ? 'Add New Property' : 'Update Property'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={currentProperty?.type}
                options={typeOptions}
                getOptionLabel={(option) => option.name || ''}
                filterOptions={(x) => x}
                onChange={onPropertyTypeSelected}
                isOptionEqualToValue={(option, value) => option}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Property Type"
                    onChange={(e) => handleSearchType(e.target.value)}
                    // error={errors.typeName}
                    // helperText={errors.typeName && messages.errors.required_field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={currentProperty?.address}
                options={locationOptions}
                getOptionLabel={(option) => option}
                filterOptions={(x) => x}
                onChange={onAddressSelected}
                isOptionEqualToValue={(option, value) => option}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Address"
                    onChange={(e) => handleSearchLocation(e.target.value)}
                    error={errors.address}
                    helperText={errors.address && messages.errors.required_field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Unit"
                name="unit"
                value={currentProperty.unit || ''}
                fullWidth
                onChange={(event) =>
                  setCurrentProperty((prevState) => ({
                    ...prevState,
                    unit: event.target.value,
                    isEdited: true,
                  }))
                }
              />
            </Grid>

            {props.showInvalid && (
              <Fragment>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setCurrentProperty((prevState) => ({
                            ...prevState,
                            isCorrect: event.target.checked,
                            isEdited: true,
                          }))
                        }
                      />
                    }
                    label="Is Correct"
                  />
                </Grid>
              </Fragment>
            )}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                onClick={handleValidate}
                // disabled={!currentProperty.isEdited}
                disabled={props.showInvalid ? !currentProperty.isCorrect : !currentProperty.isEdited}
              >
                {props.isNewProperty ? 'Add New Property' : 'Update Property'}
              </Button>

              <Button variant="contained" sx={button('secondary')} onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default PropertyDrawer;
