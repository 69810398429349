import { Fragment } from 'react';
import moment from 'moment-timezone';

import { Box, Typography } from '@mui/material';

const TooltipTitle = ({ info }) => (
  <Box>
    <Typography variant="body2">
      Status: <strong>{info.status}</strong>
    </Typography>

    {info.user && (
      <Typography variant="body2">
        Assigned to: <strong>{info.user ? info.user.fullName : 'Not assigned'}</strong>
      </Typography>
    )}

    <Typography variant="body2">
      Address: <strong>{info.property?.address}</strong>
    </Typography>
    {info.startTime && (
      <Fragment>
        <Typography variant="body2">
          Date: <strong>{moment(info.startTime).format('LL')}</strong>
        </Typography>
        <Typography variant="body2">
          Time:{' '}
          <strong>
            {moment(info.startTime).format('hh:mm')}-{moment(info.endTime).format('hh:mm A')}
          </strong>
        </Typography>
      </Fragment>
    )}

    <Typography variant="body2">Notes:</Typography>
    {info.notes && (
      <Fragment>
        {info.notes.map((note) => (
          <Typography variant="body2" key={note.id}>
            {note.note} <i>({note.createdBy.fullName})</i>
          </Typography>
        ))}
      </Fragment>
    )}

    {info.reasons.length > 0 && (
      <Fragment>
        <Typography variant="body2">Cancel Reason:</Typography>
        {info?.reasons.map((note) => (
          <Typography variant="body2" key={note.id}>
            {note.note} <i>({note.createdBy.fullName})</i>
          </Typography>
        ))}
      </Fragment>
    )}
  </Box>
);

export default TooltipTitle;
