import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Link,
  Paper,
} from '@mui/material';

// Icons
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { container } from '../../pages/ProposalView';

const ProposalViewAdditionalInfo = ({ info }) => (
  <Box component="section" sx={container}>
    <Typography variant="h5" sx={{ pt: 4, pb: 2 }}>
      Additional information
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell width="5%">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {info?.map((el) => (
            <TableRow key={el.id}>
              <TableCell scope="row">{el.filename}</TableCell>
              <TableCell>
                <Tooltip title="See">
                  <Link href={el.url} target="_blank" sx={{ width: '100%', textAlign: 'center', display: 'block' }}>
                    <IconButton>
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default ProposalViewAdditionalInfo;
