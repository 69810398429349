import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';

import {
  Autocomplete,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

// Utils
import messages from '../../static/messages';

// Services
import propertiesServices from '../../services/propertiesServices';

// Hooks
import { useSmartLocationAutocomplete } from '../../hooks/useAutocomplete';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['id'];

const ProposalDrawer = (props) => {
  const { currentProposal, setCurrentProposal, createProposal } = props;
  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);

  const { handleSearchLocation, locationOptions } = useSmartLocationAutocomplete();

  // Action functions
  const resetErrors = () => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const resetQueries = () => {
    // handleSearchProperty('');
    handleSearchLocation('');
  };

  const onPropertySelected = (event, val) => {
    event.preventDefault();
    if (val) {
      setCurrentProposal((prevState) => ({
        ...prevState,
        property: {
          id: val.id,
        },
        isEdited: true,
      }));
    } else {
      setCurrentProposal({
        property: {
          id: '',
        },
        isEdited: false,
      });
      setClients([]);
    }
    resetQueries();
  };

  // Handler functions
  const handleValidate = () => {
    required_fields.forEach((field) => {
      const property = currentProposal.property[field];
      const client = currentProposal.client[field];

      if (property === '' || property === null) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else if (client === '' || client === null) {
        setErrors((prevState) => ({
          ...prevState,
          [`client_${field}`]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
          [`client_${field}`]: false,
        }));
      }
    });
  };

  const handleChange = (event) => {
    const val = event.target.value;

    setCurrentProposal((prevState) => ({
      ...prevState,
      client: {
        id: val,
      },
      emailTo: [val],
      isEdited: true,
    }));
  };

  const handleClose = () => props.onCloseDrawer(resetErrors);

  // Async functions
  const getClientsList = async (id) => {
    try {
      const response = await propertiesServices.getPropertyById(id);
      setClients(response.data.clients);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    resetErrors();
    setClients([]);
  }, []);

  useEffect(() => {
    if (currentProposal?.isEdited) {
      !Object.values(errors).includes(true) && createProposal();
    }
  }, [errors]);

  useEffect(() => {
    if (currentProposal && currentProposal?.property.id !== '') {
      getClientsList(currentProposal?.property.id);
    } else {
      setClients([]);
    }
  }, [currentProposal, currentProposal?.property.id]);

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        {currentProposal && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Add New Proposal
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                loading={locationOptions.length ? false : true}
                options={locationOptions}
                getOptionLabel={(option) => `${option?.address} ${option?.unit && `(${option?.unit})`}`}
                filterOptions={(x) => x}
                onChange={onPropertySelected}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                freeSolo
                clearOnEscape
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Property"
                    onChange={(e) => handleSearchLocation(e.target.value)}
                    error={errors.id}
                    helperText={errors.id && messages.errors.required_field}
                  />
                )}
              />
            </Grid>

            {clients.length > 0 && (
              <Fragment>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="client" error={errors.role}>
                      Client *
                    </InputLabel>
                    <Select
                      labelId="client"
                      name="client"
                      value={currentProposal.client?.id || ''}
                      label="Client"
                      onChange={handleChange}
                      error={errors.client_id}
                    >
                      {clients.map((client) => (
                        <MenuItem key={client.id} value={client.client.id}>
                          {client.client.fullName} {client.relation && `(${client.relation})`}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.client_id && (
                      <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Fragment>
            )}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {currentProposal.property.id && clients.length < 1 && (
              <Grid item xs={12}>
                <Typography>Please add a client to the property</Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                onClick={handleValidate}
                disabled={!currentProposal?.isEdited || !clients.length}
              >
                Add New Proposal
              </Button>

              <Button variant="contained" color="error" onClick={handleClose} sx={button('secondary')}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default ProposalDrawer;
