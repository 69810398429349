import { useState, Fragment } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Divider,
  Input,
  Rating,
} from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

// Utils
import { formatPhone } from '../../../utils/formatter';
import messages from '../../../static/messages';

// Services
import clientsServices from '../../../services/clientsServices';

// Styles
import { button } from '../../../static/theme-styles';

const ClientDetailsTable = ({ client, id, refresh }) => {
  const confirm = useConfirm();

  const handleRateClient = (event) => {
    confirm({
      title: messages.confirm_message.update,
      description: messages.confirm_message.update_description('Client Rating'),
    })
      .then(() => rateClient(event.target.value))
      .catch(() => toast.info(messages.cancel_message));
  };

  const rateClient = async (rating) => {
    const response = await clientsServices.update(client.id, {
      rating,
    });

    if (response.status === 200) {
      refresh();
      toast.success(messages.client.update);
    }

    try {
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Table>
        <TableBody>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Client Rating</TableCell>
            <TableCell>
              <Rating
                value={client?.rating}
                size="large"
                onChange={handleRateClient}
                sx={{ display: 'flex', alignItems: 'center' }}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Company</TableCell>
            <TableCell>{client?.companyName}</TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>First Name</TableCell>
            <TableCell>{client?.firstName}</TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Last Name</TableCell>
            <TableCell>{client?.lastName}</TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Phone</TableCell>
            <TableCell>{formatPhone(client?.phoneNumber)}</TableCell>
          </TableRow>
          {client?.fax && (
            <TableRow sx={{ backgroundColor: '#fff' }}>
              <TableCell sx={{ fontWeight: 600 }}>Fax</TableCell>
              <TableCell>{formatPhone(client?.fax)}</TableCell>
            </TableRow>
          )}
          {client?.mobile && (
            <TableRow sx={{ backgroundColor: '#fff' }}>
              <TableCell sx={{ fontWeight: 600 }}>Mobile</TableCell>
              <TableCell>{formatPhone(client?.mobile)}</TableCell>
            </TableRow>
          )}
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
            <TableCell>{client?.email}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <ClientNotes {...{ notesList: client?.notes, id, refresh }} />
    </Fragment>
  );
};

export default ClientDetailsTable;

const ClientNotes = ({ notesList, id, refresh }) => {
  const confirm = useConfirm();
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const userRole = JSON.parse(localStorage.getItem('user')).role;
  const [comment, setComment] = useState();
  const [editableNote, setEditableNote] = useState();

  // Action functions
  const onEditNote = (el) => setEditableNote(el);

  const onCancelEditNote = () => {
    confirm({
      title: messages.confirm_message.close_drawer,
      description: messages.confirm_message.close_drawer_description,
    })
      .then(() => setEditableNote(null))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Handler functions
  const handleChangeNote = (e) => {
    setEditableNote((prevState) => ({
      ...prevState,
      note: e.target.value,
    }));
  };

  const handleRemove = (noteId) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Note'),
    })
      .then(() => removeNote(noteId))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const addNewNote = async () => {
    const notes = [
      ...notesList,
      {
        note: comment,
      },
    ];

    try {
      const response = await clientsServices.update(id, { notes });

      if (response.status === 200) {
        setComment(null);
        toast.success(messages.success.update.note.save);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateNote = async () => {
    const notes = [
      ...notesList.filter((el) => el.id !== editableNote.id),
      {
        id: editableNote.id,
        note: editableNote.note,
      },
    ];

    try {
      const response = await clientsServices.update(id, { notes });

      if (response.status === 200) {
        setEditableNote(null);
        toast.success(messages.success.update.note.save);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeNote = async (noteId) => {
    const notes = [...notesList.filter((el) => el.id !== noteId)];

    try {
      const response = await clientsServices.update(id, { notes });

      if (response.status === 200) {
        toast.success(messages.success.update.note.remove);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        pt: 3,
        pb: 3,
      }}
    >
      <Typography sx={{ fontWeight: 600, mb: 1 }}>Notes:</Typography>
      {notesList.map((el) => (
        <Fragment key={el.id}>
          <Box alignItems="center" sx={{ display: 'flex', mt: 1 }}>
            {editableNote?.id === el.id ? (
              <Input
                defaultValue={editableNote.note}
                name="editableNote"
                id="editableNote"
                onChange={handleChangeNote}
                autoFocus
                sx={{
                  width: 500,
                  '&::before': {
                    display: 'none',
                  },
                }}
              />
            ) : (
              <Typography variant="body1">
                {el.note}
                {` `}
                <Typography variant="caption" sx={{ fontStyle: 'italic ' }}>
                  (Created by <strong>{el.createdBy.fullName}</strong> at{' '}
                  {moment.utc(el.createdAt).format('L, h:mm:ss a')})
                </Typography>
              </Typography>
            )}

            <Box sx={{ ml: 1 }}>
              {editableNote?.id === el.id ? (
                <Fragment>
                  <Tooltip title="Save">
                    <IconButton onClick={updateNote}>
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton onClick={onCancelEditNote}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              ) : (
                <Fragment>
                  <Tooltip title="Edit Note" disabled={userId !== el.createdBy.id && userRole !== 'superadmin'}>
                    <IconButton onClick={() => onEditNote(el)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Note" disabled={userId !== el.createdBy.id && userRole !== 'superadmin'}>
                    <IconButton onClick={() => handleRemove(el.id)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              )}
            </Box>
          </Box>
          <Divider sx={{ mt: 1 }} />
        </Fragment>
      ))}

      <Box sx={{ mt: 3 }}>
        <TextField
          id="new-note"
          label="New Note"
          multiline
          rows={4}
          value={comment || ''}
          fullWidth
          onChange={(e) => setComment(e.target.value)}
        />

        <Button
          variant="contained"
          size="small"
          sx={{ ...button('secondary'), mt: 3 }}
          onClick={addNewNote}
          disabled={!comment}
        >
          Add Note
        </Button>
      </Box>
    </Box>
  );
};
