import { Outlet } from 'react-router-dom';
import { YouHaveNoAccess } from './';

const RequireAuth = ({ userPermissions, allowedPermission }) => {
  if (userPermissions?.includes(allowedPermission)) return <Outlet />;

  return <YouHaveNoAccess />;
};

export default RequireAuth;
