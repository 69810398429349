const colors = {
  primary: '#C30109',
  primary_shade: '#910309',
  secondary: '#0D3B59',
  grey: '#F0F3F5',
  darkGrey: '#9EB1BC',
  disabled: '#e4e4e4',
  green: '#008000',
  green_shade: '#015701',
};

const button = (primaryColor, hoverColor) => ({
  backgroundColor: colors[primaryColor],
  borderRadius: 20,
  textTransform: 'none',
  fontFamily: 'Lato, sans-serif',
  minWidth: 90,
  ...(hoverColor && {
    '&:hover': {
      backgroundColor: colors[hoverColor],
    },
  }),
  '&:disabled': {
    borderRadius: '20px !important',
  },
});

export { button };
