import { useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Button,
  IconButton,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Menu,
  MenuItem,
  Link,
} from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';

// Custom Components
import { ClientPropertiesDrawer } from '../..';

// Utils
import messages from '../../../static/messages';
import { nullClear, nullClearObject } from '../../../utils/cleaners';

// Services
import clientsServices from '../../../services/clientsServices';

// Hooks
import usePagination from '../../../hooks/usePagination';
import useDrawer from '../../../hooks/useDrawer';

// Styles
import { actions_column, table_container_base } from '../../../static/styles';
import { button } from '../../../static/theme-styles';

const menuPaperProps = {
  elevation: 0,
  sx: {
    left: '105px !important',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiSvgIcon-root': {
      width: 25,
      height: 25,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

// External variables
const base_property = {
  property: {
    typeName: '',
    address: '',
  },
  relation: '',
};

const ClientPropertiesTable = ({ properties, id, refresh }) => {
  const [drawerMode, setDrawerMode] = useState('find');
  const [currentProperty, setCurrentProperty] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMore = Boolean(anchorEl);

  const confirm = useConfirm();
  const { drawer, openDrawer, foolCheck } = useDrawer(currentProperty);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setDrawerMode('find');
      setCurrentProperty(null);
      resetErrors && resetErrors();
    }
  };

  const onAddProperty = () => {
    setCurrentProperty(base_property);
    setDrawerMode('new');
    openDrawer();
  };

  const onFindProperty = () => {
    setCurrentProperty(base_property);
    openDrawer();
  };

  const onEditProperty = (id) => {
    const property = properties.filter((el) => el.property.id === id)[0];
    delete property.property.createdAt;
    delete property.property.deletedAt;
    delete property.property.updatedAt;
    setCurrentProperty(property);
    setDrawerMode('edit');
    openDrawer();
  };

  // Handler functions
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleDelete = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Property'),
    })
      .then(() => removeProperty(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const addNewProperty = async () => {
    delete currentProperty.isEdited;

    const filterForNullValue = nullClear(properties);
    const updatedProperties = {
      properties: [...filterForNullValue, currentProperty],
    };

    try {
      const response = await clientsServices.update(id, updatedProperties);
      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.client.update);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeProperty = async (propertyId) => {
    const updatedList = { properties: [...nullClear(properties.filter((el) => el.property.id !== propertyId))] };

    try {
      const response = await clientsServices.update(id, updatedList);

      if (response.status === 200) {
        toast.success(messages.client.update);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateProperty = async () => {
    delete currentProperty.isEdited;

    const filterForNullValue = nullClear(properties);
    const updatedProperties = {
      properties: [
        ...filterForNullValue.filter((el) => el.id !== currentProperty.id),
        nullClearObject(currentProperty),
      ],
    };

    try {
      const response = await clientsServices.update(id, updatedProperties);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.client.update);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Button variant="contained" onClick={handleClick} endIcon={<AddIcon />} sx={{ ...button('secondary'), mb: 2 }}>
        Add Property
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMore}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        PaperProps={menuPaperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onFindProperty}>
          <SearchIcon /> Existing Property
        </MenuItem>
        <MenuItem onClick={onAddProperty}>
          <HomeIcon /> New Property
        </MenuItem>
      </Menu>

      <TableContainer component={Paper} sx={table_container_base}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="20%">Type</TableCell>
              <TableCell width="55%">Address</TableCell>
              <TableCell width="20%">Relation to Client</TableCell>
              <TableCell width="5%">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {properties.length ? (
              properties.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((property) => (
                <TableRow key={property.id}>
                  <TableCell scope="row">
                    <Link
                      href={`/properties/${property.property.id}`}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                      className="full-col-flex-link"
                    >
                      {property.property.typeName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/properties/${property.property.id}`}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                      className="full-col-flex-link"
                    >
                      {property.property.address}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/properties/${property.property.id}`}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                      className="full-col-flex-link"
                    >
                      {property.relation}
                    </Link>
                  </TableCell>
                  <TableCell sx={actions_column}>
                    <Tooltip title="Edit Property">
                      <IconButton aria-label="edit" onClick={() => onEditProperty(property.property.id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Property">
                      <IconButton aria-label="remove" onClick={() => handleDelete(property.property.id)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={properties.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Properties per page"
      />

      <ClientPropertiesDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        drawerMode={drawerMode}
        currentProperty={currentProperty}
        setCurrentProperty={setCurrentProperty}
        addNewProperty={addNewProperty}
        updateProperty={updateProperty}
      />
    </Fragment>
  );
};

export default ClientPropertiesTable;
