import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Button,
  Divider,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';

// Icons
// import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';

// Custom Components
import SystemModal from './system/system.modal';
import WarrantyModule from './system/system.warranty';
import ScopeOfWorkModule from './system/system.works';
import EquipmentModule from './system/system.equipment';
import InstantsModule from './system/system.instants';
import RebatesModule from './system/system.rebates';
import SystemTemplateCreateModal from './system/system.template.create';
import SystemTemplateLoadModal from './system/system.template.load';

// Utils
import messages from '../../../static/messages';

// Services
import proposalsServices from '../../../services/proposalsServices';

// External variables
import { button } from '../../../static/theme-styles';

const base_system = {
  name: 'New System',
  installation: 0,
  equipments: [],
  scopeOfWorks: [],
  warranties: [],
  instants: [],
  rebates: [],
};

const ProposalSystemTable = ({ proposal, id, refresh, systems, setSystems, updateSystems }) => {
  // const [systems, setSystems] = useState([]);
  const [currentSystem, setCurrentSystem] = useState();
  const [openSystemModal, setOpenSystemModal] = useState(false);
  const [isNewSystem, setModalMode] = useState(true);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openLoadTemplateModal, setOpenLoadTemplateModal] = useState(false);
  const [templates, setTemplates] = useState();

  const confirm = useConfirm();

  // Action functions
  const onAddNewSystem = (system) => {
    const isValidName = systems.filter((el) => el.name === system.name);

    if (isValidName.length) toast.error('A system with this name already exists, please select a new one');
    else {
      addSystem(system);
      setOpenSystemModal(false);
      setCurrentSystem();
    }
  };

  // const onEditSystem = (title) => {
  //   const system = systems.filter((el) => el.name === title)[0];
  //   setCurrentSystem(system);
  //   setModalMode(false);
  //   setOpen(true);
  // };

  // Handler functions
  const handleOpenSystemModal = () => {
    setCurrentSystem(base_system);
    setOpenSystemModal(true);
  };

  const handleCloseSystemModal = () => {
    setCurrentSystem();
    setOpenSystemModal(false);
    setModalMode(true);
  };

  const handleOpenTemplateModal = () => setOpenTemplateModal(true);

  const handleCloseTemplateModal = () => setOpenTemplateModal(false);

  const handleOpenLoadTemplateModal = () => setOpenLoadTemplateModal(true);

  const handleCloseLoadTemplateModal = () => {
    setOpenLoadTemplateModal(false);
    setTemplates();
  };

  const handleRemoveSystem = (system) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description(`"${system.name}"`),
    })
      .then(() => removeSystem(system))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleCopySystem = (system) => {
    confirm({
      title: messages.confirm_message.copy,
      description: messages.confirm_message.copy_description(`"${system.name}"`),
    })
      .then(() => copySystem(system))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleChange = (event, title) => {
    const key = event.target.name;
    const val = event.target.value;

    setSystems((prevState) => [
      ...prevState.filter((el) => el.title !== title),
      {
        ...prevState.filter((el) => el.title === title)[0],
        [key]: Number(val),
      },
    ]);
  };

  // Async functions
  const addSystem = async (system) => {
    const updatedSystems = {
      systems: [...systems.filter((el) => el.title === system.title), system],
    };

    try {
      const response = await proposalsServices.update(id, { data: updatedSystems });

      if (response.status === 200) {
        toast.success('New system successfully added to proposal');
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // const updateSystem = async (system) => {};

  const removeSystem = async (system) => {
    const updatedSystems = {
      systems: [...systems.filter((el) => el.name !== system.name)],
    };

    try {
      const response = await proposalsServices.update(id, { data: updatedSystems });

      if (response.status === 200) {
        toast.success('The system successfully removed from the proposal');
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const copySystem = async (system) => {
    const updatedSystems = {
      systems: [
        ...systems,
        {
          ...system,
          name: `${system.name} COPY`,
        },
      ],
    };

    try {
      const response = await proposalsServices.update(id, { data: updatedSystems });

      if (response.status === 200) {
        toast.success('System copy successfully created');
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // const updateSystems = async () => {
  //   try {
  //     const response = await proposalsServices.update(id, {
  //       data: {
  //         systems: systems,
  //       },
  //     });

  //     if (response.status === 200) {
  //       toast.success('Systems successfully updated');
  //       refresh();
  //     }
  //   } catch (error) {
  //     toast.error(messages.errors.error_try_again);
  //     console.log(error);
  //   }
  // };

  const getTemplates = async () => {
    try {
      const response = await proposalsServices.getSystemTemplates();

      if (response.status === 206) {
        handleOpenLoadTemplateModal();

        setTemplates(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getTemplate = async (id) => {
    handleCloseLoadTemplateModal();

    try {
      const response = await proposalsServices.getSystemTemplateById(id);

      if (response.status === 200) {
        setSystems(response.data.systems);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const saveTemplate = async (name) => {
    const data = {
      name,
      systems,
    };

    try {
      const response = await proposalsServices.createSystemTemplate(data);

      if (response.status === 201) {
        toast.success('System Template Created');
        handleCloseTemplateModal();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeTemplate = async (id) => {
    try {
      const response = await proposalsServices.deleteSystemTemplate(id);

      if (response.status === 200) {
        toast.success('System Template Deleted');
        getTemplates();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    if (proposal.data?.systems) setSystems(proposal.data.systems);
  }, [proposal]);

  return (
    <Fragment>
      <Button
        variant="contained"
        endIcon={<AddIcon />}
        onClick={handleOpenSystemModal}
        size="small"
        sx={button('secondary')}
      >
        Add new system
      </Button>

      <Button
        variant="contained"
        onClick={updateSystems}
        size="small"
        sx={{ ...button('secondary'), ml: 2 }}
        disabled={!systems.length > 0}
      >
        Save Systems
      </Button>

      <Button
        variant="contained"
        onClick={getTemplates}
        size="small"
        sx={{ ...button('secondary'), ml: 2, mt: { xs: 2, md: 0 } }}
      >
        Load Template
      </Button>

      <Button
        variant="contained"
        onClick={handleOpenTemplateModal}
        size="small"
        sx={{ ...button('secondary'), ml: 2, mt: { xs: 2, md: 0 } }}
        disabled={!systems.length > 0}
      >
        Save Template
      </Button>

      {systems.length > 0 && (
        <Fragment>
          {systems
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((el, index) => (
              <Box key={index} sx={{ mt: 4 }}>
                <Divider textAlign="left">
                  <Chip label={`System: ${el.name}`} sx={{ mr: 1 }} />
                  {/* <Tooltip title="Edit system">
                    <IconButton aria-label="Edit" onClick={() => onEditSystem(el.name)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip> */}

                  <Tooltip title="Copy system">
                    <IconButton aria-label="Copy" onClick={() => handleCopySystem(el)}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Remove system">
                    <IconButton aria-label="Remove" onClick={() => handleRemoveSystem(el)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Divider>

                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <EquipmentModule
                      systemName={el.name}
                      data={el.equipments.sort((a, b) => {
                        if (a.equipmentId < b.equipmentId) {
                          return -1;
                        }
                        if (a.equipmentId > b.equipmentId) {
                          return 1;
                        }
                        return 0;
                      })}
                      system={el}
                      setSystems={setSystems}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ScopeOfWorkModule
                      systemName={el.name}
                      data={el.scopeOfWorks}
                      system={el}
                      setSystems={setSystems}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <WarrantyModule systemName={el.name} data={el.warranties} system={el} setSystems={setSystems} />
                  </Grid>

                  <Grid item xs={12}>
                    <InstantsModule systemName={el.name} data={el.instants} system={el} setSystems={setSystems} />
                  </Grid>

                  <Grid item xs={12}>
                    <RebatesModule systemName={el.name} data={el.rebates} system={el} setSystems={setSystems} />
                  </Grid>
                </Grid>

                <Grid container alignItems="center" sx={{ mt: 4 }}>
                  <Grid item>
                    <FormControl fullWidth size="small">
                      <InputLabel required htmlFor="installation_price">
                        Installation Price
                      </InputLabel>
                      <OutlinedInput
                        required
                        id="installation_price"
                        name="installationPrice"
                        value={el.installationPrice || ''}
                        onChange={(event) => handleChange(event, el.title)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Installation Price"
                        type="number"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            ))}
        </Fragment>
      )}

      {systems.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={handleOpenSystemModal}
            size="small"
            sx={button('secondary')}
          >
            Add new system
          </Button>

          <Button variant="contained" onClick={updateSystems} size="small" sx={{ ...button('secondary'), ml: 2 }}>
            Save Systems
          </Button>
        </Box>
      )}

      <SystemModal
        open={openSystemModal}
        currentSystem={currentSystem}
        setCurrentSystem={setCurrentSystem}
        handleClose={handleCloseSystemModal}
        onAddNewSystem={onAddNewSystem}
        isNewSystem={isNewSystem}
        // handleSaveSystem={handleSaveSystem}
      />

      <SystemTemplateCreateModal
        open={openTemplateModal}
        close={handleCloseTemplateModal}
        saveTemplate={saveTemplate}
      />

      {templates && (
        <SystemTemplateLoadModal
          open={openLoadTemplateModal}
          close={handleCloseLoadTemplateModal}
          onLoadTemplate={getTemplate}
          templates={templates}
          removeTemplate={removeTemplate}
        />
      )}
    </Fragment>
  );
};

export default ProposalSystemTable;
