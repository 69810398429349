import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';

import { Box, Button, Typography, Table, TableBody, TableRow, TableCell, TextField, Modal } from '@mui/material';

// Icons
import NoteAddIcon from '@mui/icons-material/NoteAdd';

// Utils
import messages from '../../../static/messages';

// Services
import proposalsServices from '../../../services/proposalsServices';

// Styles
import { button } from '../../../static/theme-styles';
import { modal_style } from '../../../static/styles';

const ProposalPriceTable = ({ proposal, id, refresh, prices }) => {
  const [paymentNote, setPaymentNote] = useState({
    note: '',
    isEdited: false,
  });
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState({
    total: 0,
    rebates: 0,
    instants: 0,
  });

  const confirm = useConfirm();

  // Handler functions
  const handleChange = (event) =>
    setPaymentNote((prevState) => ({
      ...prevState,
      note: event.target.value,
      isEdited: true,
    }));

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    if (paymentNote.isEdited) {
      confirm({
        title: messages.confirm_message.close_drawer,
        description: messages.confirm_message.close_drawer_description,
      })
        .then(() => {
          setOpen(false);
          setPaymentNote((prevState) => ({
            ...prevState,
            note: proposal.paymentNotes,
          }));
        })
        .catch(() => toast.info(messages.cancel_message));
    } else setOpen(false);
  };

  // Async functions
  const addPaymentNote = async () => {
    delete paymentNote.isEdited;

    try {
      const response = await proposalsServices.update(id, {
        paymentNotes: paymentNote.note,
      });

      if (response.status === 200) {
        handleClose();
        toast.success('Payment notes updated successfully');
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    if (proposal)
      setPaymentNote((prevState) => ({
        ...prevState,
        note: proposal.paymentNotes,
      }));
  }, [proposal]);

  useEffect(() => {
    const total = prices.map((system) => 0 + system.total).reduce((a, b) => a + b);
    const rebates = prices.map((system) => 0 + system.rebates_price).reduce((a, b) => a + b);
    const instants = prices.map((system) => 0 + system.instants_price).reduce((a, b) => a + b);

    setPrice({
      total,
      rebates,
      instants,
    });
  }, [refresh]);

  return (
    <Fragment>
      <Table>
        <TableBody>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Total Price (incl. parts and labor)</TableCell>
            <TableCell>
              {price.total.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Rebates</TableCell>
            <TableCell>
              {price.rebates.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Instants</TableCell>
            <TableCell>
              {price.instants.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Total After</TableCell>
            <TableCell>
              {(price.total - price.rebates - price.instants).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: '#fff' }}>
            <TableCell sx={{ fontWeight: 600 }}>Total to Pay</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              <strong>
                {(price.total - price.instants).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Button variant="contained" onClick={handleOpen} endIcon={<NoteAddIcon />} sx={{ ...button('secondary'), mt: 2 }}>
        Payment Description
      </Button>

      <Typography variant="h4" sx={{ mt: 2, textAlign: 'center' }}>
        {!!proposal?.signature && (
          <Fragment>
            <Box sx={{ border: '1px solid #ccc', padding: 1 }}>
              <img src={proposal?.signature.sign} alt="signature" style={{ verticalAlign: 'top' }} />
            </Box>
            <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
              Accepted on {moment.utc(proposal?.signature?.createdAt).format('MMM Do YYYY, h:mm:ss a')}
            </Typography>
          </Fragment>
        )}
      </Typography>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modal_style}>
          <TextField
            id="paymentNote"
            variant="standard"
            value={paymentNote.note}
            onChange={handleChange}
            label="Payment Description"
            fullWidth
            rows="3"
            multiline
            autoFocus
          />

          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={addPaymentNote} size="small" sx={button('primary', 'secondary')}>
              Save
            </Button>

            <Button
              variant="contained"
              onClick={paymentNote.isEdited ? handleClose : () => setOpen(false)}
              size="small"
              sx={{ ...button('secondary'), ml: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default ProposalPriceTable;
