import { useState } from 'react';

export const usePagination = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  return { rowsPerPage, setRowsPerPage, page, setPage, handleChangePage, handleChangeRowsPerPage };
};

export default usePagination;
