import { useState, useEffect } from 'react';

export const useAccordion = (accordionList) => {
  const [open, setOpen] = useState({});

  const accordionToggle = (key) => {
    setOpen((prevState) => ({
      ...prevState,
      // [key]: !open[key],
      [key]: true,
    }));
  };

  useEffect(() => {
    accordionList.forEach((el) => {
      setOpen((prevState) => ({
        ...prevState,
        [el]: true,
      }));
    });
  }, [accordionList]);

  return { open, accordionToggle };
};

export default useAccordion;
