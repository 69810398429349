import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';

// Custom Components
import { Spinner, SuppliersDrawer } from '../..';

// Utils
import messages from '../../../static/messages';

// Services
import suppliersServices from '../../../services/suppliersServices';

// Hooks
import useDrawer from '../../../hooks/useDrawer';

// Styles
import { table_header_with_search, table_container_base, actions_column } from '../../../static/styles';

import { button } from '../../../static/theme-styles';

// External variables
const base_supplier = {
  name: '',
  email: '',
};

const SuppliersTable = () => {
  const [suppliers, setSuppliers] = useState();
  const [currentSupplier, setCurrentSupplier] = useState(null);
  const [isNewSupplier, setSupplierMode] = useState(false);
  const [supplierIndex, setSupplierIndex] = useState(null);

  const { drawer, openDrawer, foolCheck } = useDrawer(currentSupplier);

  const confirm = useConfirm();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setCurrentSupplier(null);
      setSupplierIndex(null);
      setSupplierMode(false);
      resetErrors && resetErrors();
    }
  };

  const onAddSupplier = () => {
    setSupplierMode(true);
    setCurrentSupplier(base_supplier);
    openDrawer();
  };

  const onEditSupplier = (supplier, index) => {
    setCurrentSupplier(supplier);
    setSupplierIndex(index);
    openDrawer();
  };

  // Handler functions
  const handleDelete = (index) => {
    confirm({
      title: messages.confirm_message.restore,
      description: messages.confirm_message.remove_description('supplier'),
    })
      .then(() => removeSupplier(index))
      .catch(() => toast.warning(messages.cancel_message));
  };

  // Async functions
  const getSuppliersList = async () => {
    try {
      const response = await suppliersServices.getSuppliers();

      setSuppliers(response.data.value.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const createSupplier = async () => {
    delete currentSupplier.isEdited;

    const data = [...suppliers, currentSupplier];

    try {
      const response = await suppliersServices.update(data);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.supplier.create);
        getSuppliersList();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateSupplier = async () => {
    delete currentSupplier.isEdited;

    delete suppliers[supplierIndex];

    const updatedData = [...suppliers.filter((el) => typeof el !== 'undefined'), currentSupplier];

    try {
      const response = await suppliersServices.update(updatedData);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.supplier.update);
        getSuppliersList();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeSupplier = async (index) => {
    delete suppliers[index];

    const updatedData = [...suppliers.filter((el) => typeof el !== 'undefined')];

    try {
      const response = await suppliersServices.update(updatedData);

      if (response.status === 200) {
        toast.success(messages.supplier.remove);
        getSuppliersList();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getSuppliersList();
  }, []);

  // Check for data loading
  if (!suppliers) return <Spinner />;

  return (
    <Fragment>
      <Box sx={table_header_with_search}>
        <Button variant="contained" endIcon={<AddIcon />} onClick={onAddSupplier} sx={button('primary', 'secondary')}>
          Add new
        </Button>
      </Box>

      <Paper>
        <TableContainer sx={table_container_base}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="45%">Name</TableCell>
                <TableCell width="50%">Email</TableCell>
                <TableCell width="5%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.length ? (
                suppliers.map((supplier, index) => (
                  <TableRow key={index} hover>
                    <TableCell scope="row">{supplier.name}</TableCell>
                    <TableCell>{supplier.email}</TableCell>
                    <TableCell sx={actions_column}>
                      <Tooltip title="Edit Supplier">
                        <IconButton onClick={() => onEditSupplier(supplier, index)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete User">
                        <IconButton onClick={() => handleDelete(index)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                    <strong>No Data</strong>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <SuppliersDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        currentSupplier={currentSupplier}
        setCurrentSupplier={setCurrentSupplier}
        isNewSupplier={isNewSupplier}
        createSupplier={createSupplier}
        updateSupplier={updateSupplier}
      />
    </Fragment>
  );
};

export default SuppliersTable;
