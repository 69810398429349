import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const FilesTable = ({ files, setCurrentItem }) => {
  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const removeFile = (id) => {
    setCurrentItem((prevState) => ({
      ...prevState,
      resources: [...prevState.resources.filter((el) => el.id !== id)],
      isEdited: true,
    }));
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="files table">
        <TableHead>
          <TableRow>
            <TableCell width="80%">Created At</TableCell>
            <TableCell width="20%">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files?.map((el) => (
            <TableRow key={el.id}>
              <TableCell scope="row">{moment(el.createdAt).format('MMM Do YYYY, h:mm:ss a')}</TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                }}
              >
                <Tooltip title="Download File">
                  <IconButton aria-label="download" onClick={() => downloadFile(el.url)} sx={{ height: 35, width: 35 }}>
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete File">
                  <IconButton aria-label="remove" onClick={() => removeFile(el.id)} sx={{ height: 35, width: 35 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilesTable;
