import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Link,
  Tooltip,
  IconButton,
} from '@mui/material';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';

// Custom Components
import { Spinner, DateSwitcher, BrowserTitle } from '../components';

// Utils
import messages from '../static/messages';

// Services
import appointmentsServices from '../services/appointmentsServices';

// Hooks
import usePagination from '../hooks/usePagination';

// Styles
import { table_container_base } from '../static/styles';

const link_styles = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

// External variables

const Schedule = () => {
  const [appointments, setAppointments] = useState();
  const [date, setDate] = useState(moment().format());
  const [userRole, setUserRole] = useState();
  const userData = JSON.parse(localStorage.getItem('user'));

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  // Async functions
  const getUserAppointments = async (id) => {
    try {
      const response = await appointmentsServices.getAllAppointments(date.split('T')[0] + 'T08:00:00.000Z', id);

      if (response.status === 206) setAppointments(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const addTimeStamp = async (id, mode) => {
    const date = moment().utcOffset('-0800');

    try {
      const response = await appointmentsServices.update(id, {
        ...(mode === 'checkIn' && { checkIn: moment.utc(date).format() }),
        ...(mode === 'checkOut' && { checkOut: moment.utc(date).format() }),
      });

      if (response.status === 200) {
        toast.success(mode === 'checkIn' ? 'Check In' : 'Check Out');
        getUserAppointments(userData.id);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    setUserRole(userData?.role);
    getUserAppointments(userData?.id);
  }, [date]);

  if (!appointments) return <Spinner />;

  return (
    <Box>
      <BrowserTitle title="Schedule | AirMaxx Pro" />

      <DateSwitcher date={date} setDate={setDate} />

      <TableContainer component={Paper} sx={{ ...table_container_base, mt: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }, width: { md: '5%' } }}>Status</TableCell>
              <TableCell sx={{ width: { md: '50%' } }}>Address</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }, width: '10%' }}>Type</TableCell>
              <TableCell sx={{ width: { md: '15%' } }}>Time Frame</TableCell>
              {(userRole === 'technician' || userRole === 'superadmin') && (
                <TableCell sx={{ width: '5%' }}>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.length ? (
              appointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((appointment) => (
                <TableRow key={appointment.id} hover>
                  <TableCell scope="row" sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      sx={{ ...link_styles, justifyContent: 'center' }}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {appointment.status === 'Active' && (
                        <Fragment>
                          <CircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} />
                        </Fragment>
                      )}
                      {appointment.status === 'In Progress' && (
                        <Fragment>
                          <CircleIcon sx={{ color: 'orange', mr: 1, width: 15, height: 15 }} />
                        </Fragment>
                      )}
                      {appointment.status === 'Completed' && (
                        <Fragment>
                          <CheckCircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} />
                        </Fragment>
                      )}
                      {appointment.status === 'Cancelled' && (
                        <Fragment>
                          <CancelIcon sx={{ color: 'red', mr: 1, width: 15, height: 15 }} />
                        </Fragment>
                      )}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {appointment.property?.address}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {appointment.type.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/appointments/${appointment.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      sx={link_styles}
                    >
                      {moment(appointment?.startTime).format('hh:mm A')} -{' '}
                      {moment(appointment?.endTime).format('hh:mm A')}
                    </Link>
                  </TableCell>
                  {(userRole === 'technician' || userRole === 'superadmin') && (
                    <TableCell
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {!appointment?.checkIn && (
                        <Tooltip title="Check In">
                          <IconButton onClick={() => addTimeStamp(appointment.id, 'checkIn')}>
                            <TimerIcon />
                          </IconButton>
                        </Tooltip>
                      )}

                      {appointment?.checkIn && (
                        <Tooltip title="Check Out" disabled={appointment?.checkOut}>
                          <IconButton onClick={() => addTimeStamp(appointment.id, 'checkOut')}>
                            <TimerOffIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={appointments?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page"
      />
    </Box>
  );
};

export default Schedule;
