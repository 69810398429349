import { useState, useEffect, Fragment } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { TwitterPicker } from 'react-color';

import {
  Avatar,
  Box,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  OutlinedInput,
  Button,
  FormHelperText,
  Divider,
  Typography,
  Checkbox,
  ListItemText,
} from '@mui/material';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Custom Components

// Utils
import messages from '../../static/messages';
import { internationalPhoneFormat, validateEmail } from '../../utils/formatter';

// Styles
import { button } from '../../static/theme-styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// External variables
const required_fields = ['firstName', 'lastName', 'email', 'identifier', 'phoneNumber', 'role', 'title', 'permissions'];

const permissionsList = [
  'matrix',
  'schedule',
  'proposals',
  'clients',
  'items',
  'properties',
  'settings',
  'logs',
  'timer',
];

const colorsList = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
];

const UserDrawer = (props) => {
  const { currentUser, setCurrentUser, setUserPhoto, setFile, createUser, updateUser } = props;

  const [errors, setErrors] = useState({});
  const [isVisible, setVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  // Action functions
  const resetErrors = () => {
    setErrors({});
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const onChangeImage = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUserPhoto(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);

      setFile(event.target.files[0]);

      setCurrentUser((prevState) => ({
        ...prevState,
        isEdited: true,
      }));
    }
  };

  // Handler functions
  const handleClose = () => props.onCloseDrawer(resetErrors);

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    switch (key) {
      case 'phoneNumber':
        setCurrentUser((prevState) => ({
          ...prevState,
          [key]: val === '1' ? '' : internationalPhoneFormat(val),
          isEdited: true,
        }));
        break;
      default:
        setCurrentUser((prevState) => ({
          ...prevState,
          [key]: val,
          isEdited: true,
        }));
        break;
    }
  };

  const handleChangeUserColor = (color) => {
    setCurrentUser((prevState) => ({
      ...prevState,
      preferences: {
        ...prevState.preferences,
        color: color.hex,
      },
      isEdited: true,
    }));
  };

  const handleValidate = () => {
    resetErrors();

    required_fields.forEach((field) => {
      if (currentUser[field] === '' || !String(currentUser[field]).length) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });

    if (!validateEmail(currentUser.email)) {
      toast.error(messages.errors.invalid_format('Email'));
      setErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (currentUser.phoneNumber.length !== 12) {
      toast.error(messages.errors.invalid_format('Phone'));
      setErrors((prevState) => ({
        ...prevState,
        phoneNumber: true,
      }));
    }

    if (currentUser.password) {
      if (currentUser.password !== currentUser.confirmPassword) {
        toast.error(messages.errors.password.not_match);
        setErrors((prevState) => ({
          ...prevState,
          confirmPassword: true,
        }));
      }

      if (currentUser.password.length < 8) {
        toast.error(messages.errors.password.rule);
        setErrors((prevState) => ({
          ...prevState,
          password: true,
        }));
      }
    }

    if (props.isNewUser) {
      if (!currentUser.password) {
        setErrors((prevState) => ({
          ...prevState,
          password: true,
        }));
      }

      if (!currentUser.confirmPassword) {
        setErrors((prevState) => ({
          ...prevState,
          confirmPassword: true,
        }));
      }
    }
  };

  const handleClickShowPassword = (id) => {
    setVisibility((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  // Hooks
  useEffect(() => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  }, []);

  useEffect(() => {
    if (currentUser?.isEdited) {
      !Object.values(errors).includes(true) && (props.isNewUser ? createUser() : updateUser());
    }
  }, [errors]);

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        {props.currentUser && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  {props.isNewUser ? 'Add New User' : 'Update User'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                name="firstName"
                label="First Name"
                defaultValue={props.currentUser.firstName}
                fullWidth
                onChange={handleChange}
                error={errors.firstName}
                helperText={errors.firstName && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                name="lastName"
                label="Last Name"
                defaultValue={props.currentUser.lastName}
                fullWidth
                onChange={handleChange}
                error={errors.lastName}
                helperText={errors.lastName && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="password" error={errors.password}>
                  Password {props.isNewUser && '*'}
                </InputLabel>
                <OutlinedInput
                  error={errors.password}
                  name="password"
                  type={isVisible.password ? 'text' : 'password'}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('password')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {isVisible.password ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {errors.password && <FormHelperText error>{messages.errors.required_field}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="confirmPassword" error={errors.confirmPassword}>
                  Confirm Password {props.isNewUser && '*'}
                </InputLabel>
                <OutlinedInput
                  error={errors.confirmPassword}
                  name="confirmPassword"
                  type={isVisible.confirmPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('confirmPassword')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {isVisible.confirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                {errors.confirmPassword && <FormHelperText error>{messages.errors.password.rule}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <InputMask
                mask="+1 (999) 999-9999"
                value={props.currentUser.phoneNumber}
                disabled={false}
                maskChar=" "
                onChange={handleChange}
              >
                {() => (
                  <TextField
                    required
                    name="phoneNumber"
                    label="Phone"
                    fullWidth
                    error={errors.phoneNumber}
                    helperText={errors.phoneNumber && messages.errors.required_field}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                name="email"
                label="Email"
                defaultValue={props.currentUser.email}
                sx={{ width: '100%' }}
                onChange={handleChange}
                error={errors.email}
                helperText={errors.email && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {!props.isNewUser && (
              <Fragment>
                <Grid item xs={6}>
                  <Avatar
                    alt={props.currentUser.firstName}
                    src={props.userPhoto ? props.userPhoto : props.currentUser.photo?.url}
                    className="user_avatar"
                    sx={{ width: 100, height: 100, m: '0 auto', mb: 3 }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ mb: 3 }} fullWidth>
                    <Button variant="contained" component="label">
                      Upload Photo
                      <input hidden accept="image/*" type="file" name="photo" onChange={onChangeImage} />
                    </Button>
                  </FormControl>
                </Grid>
              </Fragment>
            )}

            <Grid item xs={6}>
              <TextField
                required
                name="identifier"
                label="Proposal Identifier"
                defaultValue={props.currentUser.identifier}
                fullWidth
                onChange={handleChange}
                error={errors.identifier}
                helperText={errors.identifier && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                name="title"
                label="Title"
                defaultValue={props.currentUser.title}
                fullWidth
                onChange={handleChange}
                error={errors.title}
                helperText={errors.title && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="user-status">Status</InputLabel>
                <Select
                  labelId="user-status"
                  name="isActive"
                  value={props.currentUser.isActive}
                  label="Status"
                  onChange={handleChange}
                  disabled
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="user-role" error={errors.role}>
                  Role *
                </InputLabel>
                <Select
                  labelId="user-role"
                  name="role"
                  value={props.currentUser.role}
                  label="Role"
                  onChange={handleChange}
                  error={errors.role}
                >
                  <MenuItem value="installer">Installer</MenuItem>
                  <MenuItem value="technician">Technician</MenuItem>
                  <MenuItem value="dispatcher">Dispatcher</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="superadmin">Super Admin</MenuItem>
                </Select>
                {errors.role && <FormHelperText error>{messages.errors.required_field}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="user-permissions" error={errors.permissions}>
                  Module Permissions *
                </InputLabel>
                <Select
                  error={errors.permissions}
                  labelId="user-permissions"
                  name="permissions"
                  multiple
                  value={props.currentUser.permissions}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Module Permissions" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {permissionsList.map((el) => (
                    <MenuItem value={el} key={el}>
                      <Checkbox checked={props.currentUser.permissions.indexOf(el) > -1} />
                      <ListItemText primary={el.charAt(0).toUpperCase() + el.slice(1)} />
                    </MenuItem>
                  ))}
                </Select>
                {errors.permissions && (
                  <FormHelperText error>This field must contain at least one value</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {currentUser.role === 'technician' && (
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="body1" sx={{ m: 0 }}>
                    User Color:
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        padding: '5px',
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setColorPickerVisible(true)}
                    >
                      <Box
                        sx={{
                          width: '36px',
                          height: '14px',
                          borderRadius: '2px',
                          background: currentUser.preferences?.color,
                        }}
                      />
                    </Box>

                    {colorPickerVisible && (
                      <Box sx={{ position: 'absolute', zIndex: '2' }}>
                        <Box
                          sx={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                          onClick={() => setColorPickerVisible(false)}
                        />
                        <TwitterPicker
                          colors={colorsList}
                          color={currentUser.preferences?.color || '#fff'}
                          onChange={handleChangeUserColor}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                disabled={!props.currentUser.isEdited}
                onClick={handleValidate}
              >
                {props.isNewUser ? 'Add New User' : 'Update User'}
              </Button>

              <Button variant="contained" onClick={handleClose} sx={button('secondary')}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default UserDrawer;
