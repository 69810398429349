const update = {
  common: 'All changes saved!',
  account: 'Account details updated successfully!',
  photo: 'Your photo has been successfully updated!',
  password: 'Password updated successfully!',
  note: {
    save: 'The note has been saved successfully!',
    remove: 'The note has been deleted successfully!',
  },
  property: 'Property data updated successfully',
  item: {
    save: 'A new item has been successfully created!',
    remove: 'The item has been successfully deleted!',
    restore: 'The item has been successfully restored!',
  },
};

const client = {
  create: 'A new client was created successfully!',
  update: 'Client data updated successfully!',
  remove: 'The client has been successfully deleted!',
  restore: 'The client has been successfully restored',
};

const user = {
  create: 'A new user was created successfully!',
  update: 'User data updated successfully!',
  remove: 'The user has been successfully deleted!',
  restore: 'The user has been successfully restored',
};

const supplier = {
  create: 'A new supplier was created successfully!',
  update: 'Supplier data updated successfully!',
  remove: 'The supplier has been successfully deleted!',
  restore: 'The supplier has been successfully restored',
};

const property = {
  create: 'A new property was created successfully!',
  update: 'Property data updated successfully!',
  remove: 'The property has been successfully deleted!',
  restore: 'The property has been successfully restored',
};

const proposal = {
  create: 'A new proposal was created successfully!',
  update: 'Proposal data updated successfully!',
  remove: 'The proposal has been successfully deleted!',
  restore: 'The proposal has been successfully restored',
};

const appointment = {
  create: 'A new appointment was created successfully!',
  update: 'Appointment data updated successfully!',
  remove: 'The appointment has been successfully deleted!',
  restore: 'The appointment has been successfully restored',
};

const appointment_types = {
  create: 'A new Appointment Type created successfully!',
  update: 'Appointment Type updated successfully!',
  remove: 'The Appointment Type has been successfully deleted!',
  restore: 'The Appointment Type has been successfully restored',
};

const appointment_sources = {
  create: 'A new Appointment Source created successfully!',
  update: 'Appointment Source updated successfully!',
  remove: 'The Appointment Source has been successfully deleted!',
  restore: 'The Appointment Source has been successfully restored',
};

const error_data_loaning = 'Oops! Something went wrong! Please reload the page!';
const error_try_again = 'Oops! Something went wrong! Please try again!';

const invalid_format = (fieldName) => `Invalid ${fieldName} format!`;
const invalid_format_helper = 'Invalid Format!';

const success_import = 'Table successfully imported';

const password = {
  rule: 'Password must contain at least 8 characters!',
  not_match: 'Passwords does not match!',
  error: 'Wrong password!',
};

const required_field = 'This field is required';

const cancel_message = 'Action canceled!';

const confirm_message = {
  remove: 'Confirm deletion!',
  remove_description: (data) => `Are you sure you want to remove the ${data}?`,
  restore: 'Confirm restore!',
  restore_description: (data) => `Are you sure you want to restore the ${data}?`,
  copy: 'Confirm duplication!',
  copy_description: (data) => `Are you sure you want to create a copy of the ${data}?`,
  close_drawer: 'Confirm closing!',
  close_drawer_description: 'You have not saved your changes! After closing the window, all data will be lost!',
  update: 'Confirm data update',
  update_description: (data) =>
    `Are you sure you wanted to update the ${data}? Changes will take effect immediately upon confirmation!`,
  clear: 'Confirm clearing',
  clear_description: 'You have not saved your changes! After clearing the form, all data will be lost!',
};

const success = {
  update,
  success_import,
};

const errors = {
  invalid_format,
  invalid_format_helper,
  password,
  error_data_loaning,
  error_try_again,
  required_field,
};

const messages = {
  errors,
  success,
  client,
  user,
  property,
  proposal,
  appointment,
  cancel_message,
  confirm_message,
  supplier,
  appointment_types,
  appointment_sources,
};

export default messages;
