import api from '../api/axios';

const sendEmail = (data) => {
  const response = api.post(`/support/send`, data);

  return response;
};

const supportServices = {
  sendEmail,
};

export default supportServices;
