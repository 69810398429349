export const nullClear = (arr) => {
  return arr.map((item) => {
    Object.keys(item).forEach((key) => {
      if (item[key] === null) {
        delete item[key];
      }

      if (typeof item[key] === 'object') {
        const obj = item[key];
        Object.keys(obj).forEach((k) => {
          if (obj[k] === null) {
            delete obj[k];
          }
        });
      }
    });
    return item;
  });
};

export const emptyStringClear = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === '') {
      delete obj[key];
    }
  });

  return obj;
};

export const nullClearObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });

  return obj;
};
