import api from '../api/axios';

const getAllWarranty = (skip = 0, take = 1000000, withDeleted) => {
  const response = api.get(
    `/warranty?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  );

  return response;
};

const getWarrantyById = (id) => {
  const response = api.get(`/warranty/${id}`);

  return response;
};

const create = (item) => {
  const response = api.post(`/warranty`, item);

  return response;
};

const update = (id, updatedItem) => {
  const response = api.patch(`/warranty/${id}`, updatedItem);

  return response;
};

const remove = (id) => {
  const response = api.delete(`/warranty/${id}`);

  return response;
};

const restore = (id) => {
  const response = api.get(`/warranty/${id}/restore`);

  return response;
};

const search = (skip = 0, take = 1000000, query, widthDeleted = false, options = {}) => {
  const response = api.get(`/warranty?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&query=${query}`, { ...options });

  return response;
};

const fileImport = (newFile) => {
  const response = api.post(
    '/warranty/import',
    {
      file: newFile,
    },
    {
      headers: { accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    },
  );

  return response;
};

const fileExport = () => {
  const response = api.get(`/warranty/export`);

  return response;
};

const warrantyServices = {
  getAllWarranty,
  getWarrantyById,
  create,
  update,
  remove,
  restore,
  search,
  fileImport,
  fileExport,
};

export default warrantyServices;
