import { useState, useContext, Fragment } from 'react';

import {
  // Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../context/AuthProvider';
import axios from '../api/axios';
import { BrowserTitle } from '../components';

const buttonStyles = {
  mt: 3,
  mb: 2,
  p: 1.25,
};

// const Copyright = (props) => {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Designed by '}
//       <Link color="inherit" href="https://toporin.com/">
//         Toporin Studio®
//       </Link>
//       {' in California'}
//     </Typography>
//   );
// };

export const Login = () => {
  const auth = useContext(AuthContext);
  const [isLoginPage, setPageMode] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const response = await axios.post('/auth/login', {
        email: formData.get('email'),
        password: formData.get('password'),
      });

      if (response.data) {
        auth.login(response.data.token, response.data.refreshToken);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handlePasswordRecover = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const response = await axios.post('/auth/passwordRecovery', {
        email: formData.get('email'),
      });

      if (response.data) {
        toast.success(response.data.message);
        setPageMode(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: 'url(../images/login_bg.jpeg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square sx={{ backgroundColor: '#F0F3F5' }}>
        <Box
          sx={{
            // mx: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            padding: '0 60px',
          }}
        >
          <Box sx={{ mb: 2 }}>
            <img src="/images/crm_logo.svg" alt="Logo" style={{ height: 60 }} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              textAlign="left"
              sx={{
                fontFamily: '"Days One", sans-serif',
                fontSize: 24,
                color: '#0D3B59',
              }}
            >
              {isLoginPage ? 'Sign In' : 'Reset your password'}
            </Typography>
            {!isLoginPage && (
              <Typography
                component="p"
                variant="caption"
                sx={{
                  fontFamily: '"Lato", sans-serif',
                  fontSize: 14,
                }}
              >
                Enter the email address associated with your account.
              </Typography>
            )}
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={isLoginPage ? handleSubmit : handlePasswordRecover}
            sx={{ mt: 1, width: '100%' }}
          >
            {isLoginPage ? <SignInForm setPageMode={setPageMode} /> : <ForgotPasswordForm setPageMode={setPageMode} />}
          </Box>
        </Box>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

const SignInForm = ({ setPageMode }) => {
  const [isVisible, setVisibility] = useState(false);
  const [isDisabled, setDisabled] = useState({
    email: false,
    password: false,
  });

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    if ((key === 'email' && val) || (key === 'password' && val)) {
      setDisabled((prevState) => ({
        ...prevState,
        [key]: true,
      }));
    } else {
      setDisabled((prevState) => ({
        ...prevState,
        [key]: false,
      }));
    }
  };

  return (
    <Fragment>
      <BrowserTitle title="Login | AirMaxx Pro" />

      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        onChange={handleChange}
        sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
      />

      <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
        <InputLabel htmlFor="password">Password *</InputLabel>
        <OutlinedInput
          onChange={handleChange}
          id="password"
          name="password"
          type={isVisible ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setVisibility(!isVisible)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password *"
          sx={{ backgroundColor: '#fff' }}
        />
      </FormControl>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ ...buttonStyles, fontFamily: '"Lato", sans-serif', textTransform: 'none' }}
        disabled={!isDisabled.email || !isDisabled.password}
      >
        Sign In
      </Button>
      <Grid container>
        <Grid item xs sx={{ textAlign: 'center' }}>
          <Button
            variant="text"
            onClick={() => setPageMode(false)}
            sx={{ fontFamily: '"Lato", sans-serif', textTransform: 'none' }}
          >
            Forgot password?
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const ForgotPasswordForm = ({ setPageMode }) => {
  const [isDisabled, setDisabled] = useState(true);

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    if (key === 'email' && val) setDisabled(false);
    else setDisabled(true);
  };

  return (
    <Fragment>
      <BrowserTitle title="Reset Password | AirMaxx Pro" />

      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        onChange={handleChange}
        sx={{ backgroundColor: '#fff' }}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ ...buttonStyles, fontFamily: '"Lato", sans-serif', textTransform: 'none' }}
        disabled={isDisabled}
      >
        Send New Password
      </Button>
      <Grid container>
        <Grid item xs sx={{ textAlign: 'center' }}>
          <Button
            variant="text"
            onClick={() => setPageMode(true)}
            sx={{ fontFamily: '"Lato", sans-serif', textTransform: 'none' }}
          >
            Back to Login
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};
