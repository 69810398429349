import axios from 'axios';

import tokenServices from '../services/tokenServices';

export const API_URL = `${window.location.origin}/api/v1`;

axios.defaults.baseURL = window.location.origin;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = tokenServices.getLocalToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== '/auth/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const refreshToken = tokenServices.getLocalRefreshToken();

          const response = await axios.get(`/api/v1/auth/refreshToken`, {
            headers: { Authorization: `Bearer ${refreshToken}` },
          });

          if ((response.statusText = 'OK')) {
            tokenServices.updateLocalTokens(response.data.token, response.data.refreshToken);
          }

          return instance(originalConfig);
        } catch (_error) {
          console.log('err');
          window.location.replace('/');
          tokenServices.removeTokens();
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

export default instance;
