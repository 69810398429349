import { useState, useEffect, lazy, Suspense } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TabPanel, BrowserTitle, Spinner } from '../components';

const EquipmentTable = lazy(() => import('../components/Tables/Items/items.equipment'));
const ScopeOfWorkTable = lazy(() => import('../components/Tables/Items/items.works'));
const WarrantyTable = lazy(() => import('../components/Tables/Items/items.warranty'));

function addTabIndex(index) {
  return {
    id: `items-tab-${index}`,
    'aria-controls': `items-tabpanel-${index}`,
  };
}

export const Items = () => {
  const location = window.location.pathname;
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const tabSwitcher = (e, val) => {
    setActiveTab(val);

    switch (val) {
      case 1:
        history('/items/scope-of-work');
        break;
      case 2:
        history('/items/warranty');
        break;
      default:
        history('/items');
        break;
    }
  };

  useEffect(() => {
    switch (location) {
      case '/items/scope-of-work':
        setActiveTab(1);
        break;
      case '/items/warranty':
        setActiveTab(2);
        break;
      default:
        setActiveTab(0);
        break;
    }
  }, [location]);

  return (
    <Box component="section" id="items-page">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={tabSwitcher} aria-label="settings tabs">
            <Tab
              label="Equipment"
              {...addTabIndex(0)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Scope of Work"
              {...addTabIndex(1)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Warranty"
              {...addTabIndex(2)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          {activeTab === 0 && <BrowserTitle title="Equipment | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <EquipmentTable />
          </Suspense>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          {activeTab === 1 && <BrowserTitle title="Scope of Work | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <ScopeOfWorkTable />
          </Suspense>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          {activeTab === 2 && <BrowserTitle title="Warranty | AirMaxx Pro" />}
          <Suspense fallback={<Spinner />}>
            <WarrantyTable />
          </Suspense>
        </TabPanel>
      </Box>
    </Box>
  );
};
