import { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';

import {
  Box,
  Button,
  Chip,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  useMediaQuery,
} from '@mui/material';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';

// Icons for the Completion
import ConstructionIcon from '@mui/icons-material/Construction';
import HouseIcon from '@mui/icons-material/House';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PercentIcon from '@mui/icons-material/Percent';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// Custom Components
import { Spinner, SearchBox, ProposalDrawer, BrowserTitle } from '../components';

// Utils
import messages from '../static/messages';
import { AuthContext } from '../context/AuthProvider';

// Services
import proposalsServices from '../services/proposalsServices';
import userServices from '../services/userServices';

// Hooks
import useSearch from '../hooks/useSearch';
import usePagination from '../hooks/usePagination';
import useDrawer from '../hooks/useDrawer';

// Styles
import {
  table_header_with_search,
  table_container_base,
  check_for_deleted_row,
  actions_column,
  flex_center,
} from '../static/styles';

import { button } from '../static/theme-styles';

// External variables
const base_proposal = {
  status: 'Active',
  property: {
    id: '',
  },
  emailTo: [''],
  client: {
    id: '',
  },
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function fillYearsArray() {
  const currentYear = new Date().getFullYear();
  const endYear = 2016;
  const yearsArr = [];

  for (let year = currentYear; year >= endYear; year--) {
    yearsArr.push(year);
  }

  return yearsArr;
}

const statuses = ['Active', 'Pending', 'Cancelled', 'Completed'];

export const Proposals = () => {
  const { userData } = useContext(AuthContext);

  const [proposalsList, setProposalsList] = useState();
  const [techniciansList, setTechniciansList] = useState();
  const [showDeleted, setShowDeleted] = useState(userData.preferences.showDeletedRows);
  const [totalCount, setTotalCount] = useState();
  const [currentProposal, setCurrentProposal] = useState();
  const [isNewProposal, setProposalMode] = useState(false);
  const [filters, setFilters] = useState({
    user: 'all',
    month: 'all',
    year: 'all',
    status: 'all',
  });
  const [switchStatus, setSwitchStatus] = useState(false);

  const { searchQuery, setSearchQuery, isSearching, searchRequest } = useSearch(proposalsServices.searchRequest);
  const { rowsPerPage, setRowsPerPage, page, setPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { drawer, openDrawer, foolCheck } = useDrawer(currentProposal);

  const confirm = useConfirm();
  const smallScreen = useMediaQuery('(max-width: 1440px)');
  const navigate = useNavigate();
  const location = useLocation();

  const years = fillYearsArray();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setCurrentProposal(null);
      setProposalMode(false);
      resetErrors && resetErrors();
    }
  };

  const onAddProposal = () => {
    setProposalMode(true);
    setCurrentProposal(base_proposal);
    openDrawer();
  };

  // Handler functions
  const handleRemove = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Proposal'),
    })
      .then(() => removeProposal(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleRestore = (id) => {
    confirm({
      title: messages.confirm_message.restore,
      description: messages.confirm_message.restore_description('Proposal'),
    })
      .then(() => restoreProposal(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleFilterChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setFilters((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  // Async functions
  const getProposalsList = async (skip, take, widthDeleted = true) => {
    try {
      const response = await proposalsServices.getAllProposals(skip, take, widthDeleted);
      setTotalCount(response.data.total);
      setProposalsList(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getSearchResult = useCallback(
    async (skip, take) => {
      const result = await searchRequest(skip, take);
      setTotalCount(result.total);
      setProposalsList(result.data);
      // setPage(0);
    },
    [searchRequest],
  );

  const getTechniciansList = async () => {
    try {
      const response = await userServices.getAllUsers(0, 1000000, false);

      if (response.status === 206) setTechniciansList(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeProposal = async (id) => {
    try {
      const response = await proposalsServices.remove(id);

      if (response.status === 200) {
        toast.success(messages.proposal.remove);
        getProposalsList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const restoreProposal = async (id) => {
    try {
      const response = await proposalsServices.restore(id);

      if (response.status === 200) {
        toast.success(messages.proposal.restore);
        getProposalsList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const createProposal = async () => {
    delete currentProposal.isEdited;

    try {
      const response = await proposalsServices.create(currentProposal);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.proposal.create);
        navigate(`/proposals/${response.data.id}`);
      }
    } catch (error) {
      if (error.response.status === 409) toast.error(error.response.data.message);
      else toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getSearchByFiltersResult = async () => {
    try {
      const response = await proposalsServices.searchByFilters(
        filters.year,
        filters.month,
        filters.status,
        filters.user,
      );
      setTotalCount(response.data.total);
      setProposalsList(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getDeletedProposals = async (skip, take) => {
    try {
      const response = await proposalsServices.getDeletedProposals(skip, take);

      setTotalCount(response.data.total);
      setProposalsList(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    searchQuery === ''
      ? (() => {
          getProposalsList(page * rowsPerPage, rowsPerPage, showDeleted);
          setSwitchStatus(false);
          getTechniciansList();
        })()
      : (() => {
          getSearchResult(page * rowsPerPage, rowsPerPage, showDeleted);
          setSwitchStatus(true);
        })();
  }, [searchQuery, getSearchResult, page, rowsPerPage, showDeleted]);

  useEffect(() => {
    if (filters.year !== 'all' || filters.month !== 'all' || filters.status !== 'all' || filters.user !== 'all') {
      getSearchByFiltersResult();
      setSwitchStatus(true);
    } else {
      getProposalsList(page * rowsPerPage, rowsPerPage, showDeleted);
      setSwitchStatus(false);
    }
  }, [filters]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const page = params.get('page');
      const rowsPerPage = params.get('per_page');

      setPage(Number(page));
      setRowsPerPage(Number(rowsPerPage));
    }
  }, [location.search]);

  // Check for data loading
  if (!proposalsList) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title="Proposals | AirMaxx Pro" />
      <Box
        sx={(theme) => ({
          ...table_header_with_search,
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
          },
        })}
      >
        <Button variant="contained" endIcon={<AddIcon />} onClick={onAddProposal} sx={button('primary', 'secondary')}>
          Add new
        </Button>

        <Box
          sx={(theme) => ({
            ...flex_center,
            maxWidth: smallScreen ? '62vw' : '50vw',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              flexWrap: 'wrap',
              rowGap: '15px',
              mt: 2,
            },
          })}
        >
          <FormControl fullWidth sx={{ mr: 2 }} size="small">
            <InputLabel id="user">User</InputLabel>
            <Select labelId="user" label="User" value={filters.user} name="user" onChange={handleFilterChange}>
              <MenuItem value="all">All</MenuItem>
              {techniciansList?.map((el) => (
                <MenuItem value={el.id} key={el.id}>
                  {el.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mr: 2 }} size="small">
            <InputLabel id="month">Month</InputLabel>
            <Select labelId="month" label="Month" value={filters.month} name="month" onChange={handleFilterChange}>
              <MenuItem value="all">All</MenuItem>
              {months.map((el, index) => (
                <MenuItem key={index} value={index + 1}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mr: 2 }} size="small">
            <InputLabel id="year">Year</InputLabel>
            <Select labelId="year" label="Year" value={filters.year} name="year" onChange={handleFilterChange}>
              <MenuItem value="all">All</MenuItem>
              {years
                .sort((a, b) => b - a)
                .map((el, index) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mr: 2 }} size="small">
            <InputLabel id="status">Status</InputLabel>
            <Select labelId="status" label="Status" value={filters.status} name="status" onChange={handleFilterChange}>
              <MenuItem value="all">All</MenuItem>
              {statuses.map((el, index) => (
                <MenuItem key={index} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            sx={(theme) => ({
              alignItems: 'flex-end',
              [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
              },
            })}
          >
            <FormControlLabel
              value="end"
              control={<Switch color="primary" checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />}
              label="Show Deleted"
              labelPlacement="end"
              disabled={switchStatus}
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={() => getDeletedProposals(page * rowsPerPage, rowsPerPage)}
            sx={{
              ...button('secondary'),
              width: '100%',
            }}
          >
            Show Only Deleted
          </Button>
        </Box>
      </Box>

      <Paper>
        <SearchBox searchRequest={searchRequest} value={searchQuery} setValue={setSearchQuery} />
        <TableContainer sx={table_container_base}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="10%">Proposal ID</TableCell>
                <TableCell width="25%">Address</TableCell>
                <TableCell width="15%">Date</TableCell>
                <TableCell width="15%">Completion</TableCell>
                <TableCell width="20%">Comment</TableCell>
                <TableCell width="5%">Status</TableCell>
                <TableCell width="10%">Total</TableCell>
                <TableCell width="5%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSearching ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Spinner />
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment>
                  {proposalsList.length ? (
                    proposalsList.map((proposal) => (
                      <TableRow key={proposal.id} sx={check_for_deleted_row(!!proposal.deletedAt)} hover>
                        <TableCell scope="row">
                          <NavLink
                            className="full-col-flex-link"
                            to={`/proposals/view/${proposal.id}`}
                            onClick={!!proposal.deletedAt ? (e) => e.preventDefault() : null}
                          >
                            {proposal.publicId}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/proposals/view/${proposal.id}`}
                            onClick={!!proposal.deletedAt ? (e) => e.preventDefault() : null}
                          >
                            {proposal.property.address}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/proposals/view/${proposal.id}`}
                            onClick={!!proposal.deletedAt ? (e) => e.preventDefault() : null}
                          >
                            {proposal.nextInstallation &&
                              `${moment(proposal.nextInstallation.date).format('MM/DD/YYYY')} ${
                                proposal.nextInstallation.installationTime
                              }`}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <Completion data={proposal.additionalNotes} />
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/proposals/view/${proposal.id}`}
                            onClick={!!proposal.deletedAt ? (e) => e.preventDefault() : null}
                          >
                            <Tooltip title={proposal.additionalNotes?.commentFromAlex?.comment}>
                              <span>{proposal.additionalNotes?.commentFromAlex?.comment}</span>
                            </Tooltip>
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/proposals/view/${proposal.id}`}
                            onClick={!!proposal.deletedAt ? (e) => e.preventDefault() : null}
                          >
                            <Chip
                              label={proposal.status}
                              sx={{
                                maxWidth: 100,
                                margin: '0 auto',
                                backgroundColor: () => {
                                  if (proposal.status === 'Active') return '#d3d3d3';
                                  else if (proposal.status === 'Pending') return '#ffa500';
                                  else if (proposal.status === 'Completed') return '#008000';

                                  return '#ff0000';
                                },
                                color: () => {
                                  if (proposal.status === 'Pending') return '#ffa500';
                                  else if (proposal.status === 'Completed') return '#fff';

                                  return '#000000de';
                                },
                              }}
                            />
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/proposals/view/${proposal.id}`}
                            onClick={!!proposal.deletedAt ? (e) => e.preventDefault() : null}
                          >
                            {proposal.data?.totalPay > 0
                              ? (proposal.data?.totalPay).toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })
                              : 0}
                          </NavLink>
                        </TableCell>
                        <TableCell sx={actions_column}>
                          {!!proposal.deletedAt ? (
                            <Tooltip title="Restore Proposal">
                              <IconButton aria-label="restore" onClick={() => handleRestore(proposal.id)}>
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Fragment>
                              <NavLink
                                className="full-col-flex-link"
                                to={`/proposals/${proposal.id}`}
                                onClick={!!proposal.deletedAt ? (e) => e.preventDefault() : null}
                                state={{
                                  page: page,
                                  rowsPerPage: rowsPerPage,
                                }}
                              >
                                <Tooltip title="Edit Proposal">
                                  <IconButton aria-label="edit">
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </NavLink>

                              <a href={`/proposals/view/${proposal.id}`} target="_blank" rel="noReferrer">
                                <Tooltip title="See Proposal">
                                  <IconButton
                                    aria-label="pdf"
                                    sx={{
                                      color: proposal.readAt
                                        ? proposal.signature
                                          ? '#2e7d32'
                                          : 'orange'
                                        : 'rgba(0, 0, 0, 0.54)',
                                    }}
                                  >
                                    <PictureAsPdfIcon />
                                  </IconButton>
                                </Tooltip>
                              </a>
                              <Tooltip title="Delete Proposal">
                                <IconButton aria-label="remove" onClick={() => handleRemove(proposal.id)}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                        <strong>No Data</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Proposals per page"
      />

      <ProposalDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        currentProposal={currentProposal}
        setCurrentProposal={setCurrentProposal}
        isNewProposal={isNewProposal}
        createProposal={createProposal}
      />
    </Box>
  );
};

const Completion = ({ data }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    {/* EQUIPMENT */}
    <ConstructionIcon
      sx={{
        width: 20,
        height: 20,
        color: data?.orderEquipmentCompleted?.completed
          ? 'green'
          : data?.orderEquipmentCompleted?.comment
          ? 'red'
          : 'rgba(0, 0, 0, 0.54)',
      }}
    />

    {/* TITLE 24 */}
    <HouseIcon
      sx={{
        width: 20,
        height: 20,
        color: data?.title24?.completed ? 'green' : data?.title24?.comment ? 'red' : 'rgba(0, 0, 0, 0.54)',
      }}
    />

    {/* PERMIT */}
    {data?.additionalNotes?.permit?.completed ? (
      <VpnKeyIcon sx={{ width: 20, height: 20, color: 'green' }} />
    ) : (
      <KeyOffIcon
        sx={{
          width: 20,
          height: 20,
          color: data?.permit?.comment ? 'red' : 'rgba(0, 0, 0, 0.87)',
        }}
      />
    )}

    {/* REBATE */}
    <PercentIcon
      sx={{
        width: 20,
        height: 20,
        color: data?.rebate?.completed ? 'green' : data?.rebate?.comment ? 'red' : 'rgba(0, 0, 0, 0.54)',
      }}
    />

    {/* PAYMENT */}
    {data?.payment?.completed ? (
      <MonetizationOnIcon sx={{ width: 20, height: 20, color: 'green' }} />
    ) : (
      <CurrencyExchangeIcon
        sx={{
          width: 20,
          height: 20,
          color: data?.payment?.comment ? 'red' : 'rgba(0, 0, 0, 0.87)',
        }}
      />
    )}

    {/* OTHER */}
    <AddIcon
      sx={{
        width: 20,
        height: 20,
        color: data?.other?.completed ? 'green' : data?.other?.comment ? 'red' : 'rgba(0, 0, 0, 0.54)',
      }}
    />
  </Box>
);
