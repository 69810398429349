import api from '../api/axios';

const getAllWorks = (skip = 0, take = 1000000, withDeleted) => {
  const response = api.get(
    `/scopeOfWork?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  );

  return response;
};

const getWorkById = (id) => {
  const response = api.get(`/scopeOfWork/${id}`);

  return response;
};

const create = (item) => {
  const response = api.post(`/scopeOfWork`, item);

  return response;
};

const update = (id, updatedItem) => {
  const response = api.patch(`/scopeOfWork/${id}`, updatedItem);

  return response;
};

const remove = (id) => {
  const response = api.delete(`/scopeOfWork/${id}`);

  return response;
};

const restore = (id) => {
  const response = api.get(`/scopeOfWork/${id}/restore`);

  return response;
};

const search = (skip = 0, take = 1000000, query, widthDeleted = false, options = {}) => {
  const response = api.get(`/scopeOfWork?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&query=${query}`, {
    ...options,
  });

  return response;
};

const fileImport = (newFile) => {
  const response = api.post(
    '/scopeOfWork/import',
    {
      file: newFile,
    },
    {
      headers: { accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    },
  );

  return response;
};

const fileExport = () => {
  const response = api.get(`/scopeOfWork/export`);

  return response;
};

const scopeOfWorkServices = {
  getAllWorks,
  getWorkById,
  create,
  update,
  remove,
  restore,
  search,
  fileImport,
  fileExport,
};

export default scopeOfWorkServices;
