import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';

import messages from '../static/messages';

export const useDrawer = (currentItem) => {
  const confirm = useConfirm();
  const [drawer, setDrawer] = useState(false);

  const openDrawer = () => setDrawer(true);

  const closeDrawer = () => setDrawer(false);

  const foolCheck = () => {
    if (currentItem.isEdited) {
      return confirm({
        title: messages.confirm_message.close_drawer,
        description: messages.confirm_message.close_drawer_description,
      })
        .then(() => {
          closeDrawer();
          return true;
        })
        .catch(() => toast.info(messages.cancel_message));
    } else {
      closeDrawer();
      return true;
    }
  };

  return { drawer, openDrawer, foolCheck };
};

export default useDrawer;
