import { InputAdornment, InputLabel, IconButton, FormControl, OutlinedInput } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

const SearchBox = ({ value, setValue, searchRequest }) => {
  const handleMouseDown = (event) => event.preventDefault();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="search">Search</InputLabel>
      <OutlinedInput
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="search box" onClick={searchRequest} onMouseDown={handleMouseDown} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        label="Search"
        sx={{ fieldset: { borderRadius: '0 !important', borderBottom: 0 } }}
      />
    </FormControl>
  );
};

export default SearchBox;
