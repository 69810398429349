import api from '../api/axios';

const getAllProposals = (skip = 0, take = 1000000, withDeleted) => {
  const response = api.get(
    `/proposal?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  );

  return response;
};

const getDeletedProposals = (skip = 0, take = 1000000) => {
  const response = api.get(`/proposal?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&onlyDeleted=true`);

  return response;
};

const getProposalById = (id) => {
  const response = api.get(`/proposal/${id}`);

  return response;
};

const create = (proposal) => {
  const response = api.post(`/proposal`, proposal);

  return response;
};

const update = (id, updatedProposal) => {
  const response = api.patch(`/proposal/${id}`, updatedProposal);

  return response;
};

const remove = (id) => {
  const response = api.delete(`/proposal/${id}`);

  return response;
};

const restore = (id) => {
  const response = api.get(`/proposal/${id}/restore`);

  return response;
};

const searchRequest = (skip = 0, take = 1000000, query, widthDeleted = false, options = {}) => {
  const response = api.get(`/proposal?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&query=${query}`, { ...options });

  return response;
};

const searchByFilters = (year, month, status, user) => {
  const response = api.get(
    `proposal?skip=0&take=1000000&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=true${
      year === 'all' ? '' : `&year=${year}`
    }${month === 'all' ? '' : `&month=${month}`}${status === 'all' ? '' : `&status=${status}`}${
      user === 'all' ? '' : `&createdById=${user}`
    }`,
  );

  return response;
};

const sendEquipmentOrder = (id, content) => {
  const response = api.post(`/equipmentOrder/${id}/sendEmail`, {
    content,
  });

  return response;
};

const sendEmail = (id, email) => {
  const response = api.post(`/proposal/${id}/sendEmail`, email);

  return response;
};

// System Templates
const getSystemTemplates = () => {
  const response = api.get('/proposalTemplate?skip=0&withDeleted=false');

  return response;
};

const getSystemTemplateById = (id) => {
  const response = api.get(`/proposalTemplate/${id}`);

  return response;
};

const createSystemTemplate = (data) => {
  const response = api.post('/proposalTemplate', data);

  return response;
};

const deleteSystemTemplate = (id) => {
  const response = api.delete(`/proposalTemplate/${id}`);

  return response;
};

const restoreSystemTemplate = (id) => {
  const response = api.get(`/proposalTemplate/${id}/restore`);

  return response;
};

const proposalsServices = {
  getAllProposals,
  getDeletedProposals,
  getProposalById,
  create,
  update,
  remove,
  restore,
  searchRequest,
  searchByFilters,
  sendEquipmentOrder,
  sendEmail,
  getSystemTemplates,
  getSystemTemplateById,
  createSystemTemplate,
  deleteSystemTemplate,
  restoreSystemTemplate,
};

export default proposalsServices;
