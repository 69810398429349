import api from '../api/axios';

const getSuggestion = (query) => {
  const response = api.get(`/location/suggestion?query=${query}`);

  return response;
};

const getPlace = (query) => {
  const response = api.get(`/location/place?query=${query}`);

  return response;
};

const getList = (query, signal) => {
  const response = api.get(`/location/list?query=${query}&skip=0&take=20&sort=id%2Cdesc&withDeleted=false`, {
    signal,
  });

  return response;
};

const locationServices = {
  getSuggestion,
  getPlace,
  getList,
};

export default locationServices;
