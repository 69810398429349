import { Helmet } from 'react-helmet';

const BrowserTitle = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    {description && <meta name="description" content={description} />}
  </Helmet>
);

export default BrowserTitle;
