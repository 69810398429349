export const formatPhone = (dirtyNumber) => {
  if (!dirtyNumber) {
    return ''
  }
  return dirtyNumber
    ?.slice(2)
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

export const internationalPhoneFormat = (phone) => {
  return phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '+$1$2$3')
}

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

// let isPhone = (phone) => {
//   //normalize string and remove all unnecessary characters
//   phone = phone.replace(/\D+/g, '');
//   return phone.length == 10? true : false;
// }
