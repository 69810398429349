import { useState, useCallback, useEffect } from 'react';
import axios from '../api/axios';

export const useAuth = () => {
  const [token, setToken] = useState();
  const [loading, setReady] = useState(false);
  const [userData, setUserData] = useState();

  const getUserData = useCallback(async (token) => {
    try {
      const response = await axios.get('/auth/userInfo', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setUserData(response.data);

        const userInfo = {
          id: response.data.id,
          permissions: response.data.permissions,
          isDrawerOpen: response.data.preferences.drawer,
          isDeletedRowsVisible: response.data.preferences.showDeletedRows,
          isDarkTheme: response.data.preferences.lightTheme,
          role: response.data.role,
        };

        localStorage.setItem('user', JSON.stringify(userInfo));
      }
    } catch (error) {}
  }, []);

  const login = useCallback(
    (token, refreshToken) => {
      setToken(token);

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);

      getUserData(token);
    },
    [getUserData],
  );

  const logout = useCallback(() => {
    setToken(null);
    localStorage.clear();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    if (token && refreshToken) {
      login(token, refreshToken);
    }

    setReady(true);
  }, [login]);

  return { login, logout, token, loading, setToken, userData };
};
