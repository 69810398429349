import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import {
  Divider,
  Grid,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  IconButton,
} from '@mui/material';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Utils
import api from '../../api/axios';
import { AuthContext } from '../../context/AuthProvider';
import messages from '../../static/messages';

// Services
import userServices from '../../services/userServices';

// Styles
import { button } from '../../static/theme-styles';

const Security = ({ updateProfile }) => {
  const auth = useContext(AuthContext);

  const [userInfo, setUserInfo] = useState(null);
  const [passwordError, setPasswordError] = useState({
    currentPassword: false,
    match: false,
  });
  const [isDisabled, setDisabled] = useState(true);
  const [isVisible, setVisibility] = useState({
    currentPassword: false,
    password: false,
    confirmPassword: false,
  });
  const [password, setPassword] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  });

  // Action functions
  const onUpdateAccountPassword = () => {
    const data = {
      password: password.password,
    };

    updateProfile(
      data,
      setPassword({
        currentPassword: '',
        password: '',
        confirmPassword: '',
      }),
    );
  };

  // Handler functions
  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setPassword((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleClickShowPassword = (id) => {
    setVisibility((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  // Async functions
  const getUserData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));

    try {
      const response = await userServices.getUserById(user.id);

      if (response.status === 200) setUserInfo(response.data.email);
    } catch (error) {
      console.log(error);
    }
  };

  const check = async () => {
    setPasswordError({
      match: false,
      currentPassword: false,
    });

    if (password.password === password.confirmPassword) {
      try {
        const response = await api.post('/auth/login', {
          email: userInfo,
          password: password.currentPassword,
        });

        if (response.status === 200) {
          auth.login(response.data.token, response.data.refreshToken);

          onUpdateAccountPassword();
        }
      } catch (error) {
        console.log(error);
        toast.error(messages.errors.password.error);
        setPasswordError((prevState) => ({
          ...prevState,
          currentPassword: true,
        }));
      }
    } else {
      toast.error(messages.errors.password.not_match);
      setPasswordError((prevState) => ({
        ...prevState,
        match: true,
      }));
    }
  };

  // Hooks
  useEffect(() => {
    if (password.currentPassword !== '' && password.password !== '' && password.confirmPassword !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password]);

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <InputLabel htmlFor="currentPassword">Current Password</InputLabel>
          <OutlinedInput
            error={passwordError.currentPassword}
            name="currentPassword"
            value={password.currentPassword}
            type={isVisible.currentPassword ? 'text' : 'password'}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('currentPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isVisible.currentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Current Password"
          />
          {passwordError.currentPassword && <FormHelperText error>Wrong Password</FormHelperText>}
        </FormControl>

        <FormControl sx={{ width: '100%', mt: 3 }} variant="outlined">
          <InputLabel htmlFor="password">New Password</InputLabel>
          <OutlinedInput
            error={passwordError.match}
            name="password"
            value={password.password}
            type={isVisible.password ? 'text' : 'password'}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('password')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isVisible.password ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="New Password"
          />
          {passwordError.match && <FormHelperText error>Passwords does not match</FormHelperText>}
        </FormControl>

        <FormControl sx={{ width: '100%', mt: 3 }} variant="outlined">
          <InputLabel htmlFor="password">Confirm Password</InputLabel>
          <OutlinedInput
            error={passwordError.match}
            name="confirmPassword"
            value={password.confirmPassword}
            type={isVisible.confirmPassword ? 'text' : 'password'}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('confirmPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isVisible.confirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
          />
          {passwordError.match && <FormHelperText error>Passwords does not match</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          sx={{ ...button('primary', 'secondary'), mr: 3 }}
          onClick={check}
          disabled={isDisabled}
        >
          Save
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Security;
