import { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Modal,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material';

// Icons
import ReceiptIcon from '@mui/icons-material/Receipt';

// Utils
import messages from '../../static/messages';

// Services
import proposalsServices from '../../services/proposalsServices';

// Styles
const modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: '#fff',
  boxShadow: 24,
  p: 4,
};

const CheckNumber = ({ data, id, refresh }) => {
  const confirm = useConfirm();

  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState({
    number: data?.receiptNumber,
    isEdited: false,
  });

  // Handler functions
  const handleClose = () => {
    if (number.isEdited) {
      confirm({
        title: messages.confirm_message.close_drawer,
        description: 'You have unsaved changes! All unsaved data will be lost!',
      })
        .then(() => {
          setOpen(false);
          setNumber({
            number: data?.receiptNumber,
            isEdited: false,
          });
        })
        .catch(() => toast.info(messages.cancel_message));
    } else setOpen(false);
  };

  const handleChange = (event, currentItem) => {
    const val = event.target.value;

    setNumber({
      number: val,
      isEdited: true,
    });
  };

  // Async functions
  const save = async () => {
    try {
      const response = await proposalsServices.update(id, {
        receiptNumber: number.number,
      });

      if (response.status === 200) {
        setOpen(false);
        toast.success(`Receipt Number successfully updated`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        <Box sx={{ pt: 1, pb: 1 }}>{number.number || 'No Data'}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ReceiptIcon />
        </Box>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...modal_style, width: 320 }}>
          <Typography variant="h5" align="center">
            <strong>Receipt check number</strong>
          </Typography>

          <br />

          <TextField label="Add Number" value={number.number || ''} fullWidth onChange={handleChange} />

          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" onClick={save}>
              Save
            </Button>

            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default CheckNumber;
