import { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Divider,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Icons
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

// External variables
const base_filters = [`Qualifies for "Carrier" rebate`, 'Qualifies for EUC rebate', 'Qualifies for Federal Tax Credit'];

const RebatesModule = ({ systemName, data, setSystems, system }) => {
  const [rebates, setRebates] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        rebates: [
          ...prevState.filter((system) => system.name === systemName)[0].rebates,
          {
            flexibleItems: [
              {
                item: event.target.value,
                price: null,
              },
            ],
            selectedItemIndex: 0,
          },
        ],
      },
    ]);
  };

  const onAddNewItemToGroup = (event, rebateID) => {
    const newItem = event.target.value;

    const updatedList = [
      ...rebates.filter((el) => el.rebateId !== rebateID),
      {
        ...rebates.filter((el) => el.rebateId === rebateID)[0],
        flexibleItems: [
          ...rebates.filter((el) => el.rebateId === rebateID)[0].flexibleItems,
          {
            item: newItem,
            price: null,
            itemId: rebates.filter((el) => el.rebateId === rebateID)[0].flexibleItems.length,
          },
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((el) => el.name !== systemName),
      {
        ...prevState.filter((el) => el.name === systemName)[0],
        rebates: updatedList,
      },
    ]);
  };

  const onRemoveItem = (item) => {
    const updatedList = [
      ...rebates.filter((el) => !el.flexibleItems.includes(item)),
      {
        ...rebates.filter((el) => el.flexibleItems.includes(item))[0],
        flexibleItems: [
          ...rebates.filter((el) => el.flexibleItems.includes(item))[0].flexibleItems.filter((i) => i !== item),
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        rebates: updatedList,
      },
    ]);
  };

  // Handler functions
  const handleChange = (event, rebateID, itemID) => {
    const key = event.target?.name || 'expires';
    const val = event.target?.value || moment(new Date(`${event.$M + 1}/${event.$D}/${event.$y}`)).format();

    const updatedRebates = [
      ...rebates.filter((el) => el.rebateId !== rebateID),
      {
        ...rebates.filter((el) => el.rebateId === rebateID)[0],
        ...(key === 'selectedItemIndex' && {
          [key]: Number(val),
          flexibleItems: [...rebates.filter((el) => el.rebateId === rebateID)[0].flexibleItems].sort(function (a, b) {
            if (a.itemId < b.itemId) {
              return -1;
            }
            if (a.itemId > b.itemId) {
              return 1;
            }
            return 0;
          }),
        }),
        ...(key !== 'selectedItemIndex' && {
          flexibleItems: [
            ...rebates
              .filter((el) => el.rebateId === rebateID)[0]
              .flexibleItems.filter((item) => item.itemId !== itemID),
            {
              ...rebates
                .filter((el) => el.rebateId === rebateID)[0]
                .flexibleItems.filter((item) => item.itemId === itemID)[0],
              ...(key !== 'expires' && { [key]: Number(val) }),
              ...(key === 'expires' && {
                item: {
                  ...rebates
                    .filter((el) => el.rebateId === rebateID)[0]
                    .flexibleItems.filter((item) => item.itemId === itemID)[0].item,
                  [key]: val,
                },
              }),
            },
          ].sort(function (a, b) {
            if (a.itemId < b.itemId) {
              return -1;
            }
            if (a.itemId > b.itemId) {
              return 1;
            }
            return 0;
          }),
        }),
      },
    ].sort(function (a, b) {
      if (a.rebateId < b.rebateId) {
        return -1;
      }
      if (a.rebateId > b.rebateId) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        rebates: updatedRebates,
      },
    ]);
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleGroupItems = (item) => {
    const updatedList = [
      ...rebates.filter((el) => el !== item),
      {
        ...item,
        grouped: item.grouped ? false : true,
      },
    ].sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        rebates: updatedList,
      },
    ]);
  };

  const dragStartHandler = (e, item) => setCurrentItem(item);

  const dragEndHandler = (e) => setCurrentItem(null);

  const dragOverHandler = (e) => e.preventDefault();

  const dragLeaveHandler = (e) => (e.target.style.boxShadow = 'none');

  const dropHandler = (e, item) => {
    e.preventDefault();
    e.target.style.boxShadow = 'none';

    const currentIndex = rebates.indexOf(currentItem);

    rebates.splice(currentIndex, 1);

    const dropIndex = rebates.indexOf(item);

    rebates.splice(dropIndex, 0, currentItem);

    system.rebates = rebates;
  };

  // Async functions

  // Hooks
  useEffect(() => {
    if (base_filters) {
      let unique_values = base_filters.reduce(
        (acc, el) => {
          if (acc.map[el]) return acc;

          acc.map[el] = true;
          acc.rebates.push({
            description: el,
            price: 0,
            expires: moment(new Date(new Date().getFullYear(), 11, 31)).format(),
          });

          return acc;
        },
        {
          map: {},
          rebates: [],
        },
      );

      setFiltersData(unique_values.rebates);
    }

    if (data) {
      const listOfRebates = [];

      data?.forEach((el, index) => {
        listOfRebates.push({
          ...el,
          rebateId: index,
          grouped: el.flexibleItems.length > 1 || el.grouped ? true : false,
          flexibleItems: [
            ...(() => {
              const items = [];

              el.flexibleItems.forEach((item, idx) => {
                items.push({
                  ...item,
                  itemId: idx,
                });
              });
              return items;
            })(),
          ],
        });
      });

      setRebates(listOfRebates);
    }
  }, [data]);

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Rebates</strong>
      </Typography>

      {rebates.map((el) => (
        <Box key={el.rebateId} sx={{ mt: 2, ...(el.grouped && proposal_grouped_items) }}>
          <RadioGroup value={el.selectedItemIndex}>
            {el.flexibleItems
              ?.sort(function (a, b) {
                if (el.flexibleItems.indexOf(a) < el.flexibleItems.indexOf(b)) {
                  return -1;
                }
                if (el.flexibleItems.indexOf(a) > el.flexibleItems.indexOf(b)) {
                  return 1;
                }
                return 0;
              })
              .map((item, index) => (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}
                  key={item.itemId}
                  draggable={true}
                  onDragStart={(e) => dragStartHandler(e, el)}
                  onDragEnd={(e) => dragEndHandler(e)}
                  onDragOver={(e) => dragOverHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDrop={(e) => dropHandler(e, el)}
                  className="item"
                >
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    {el.grouped && (
                      <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                        <Radio
                          name="selectedItemIndex"
                          value={index}
                          checked={index === el.selectedItemIndex}
                          onChange={(event) => handleChange(event, el.rebateId, item.itemId)}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <TextField label="Description" value={item.item.description} readOnly fullWidth size="small" />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel required htmlFor="price">
                          Price
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="price"
                          name="price"
                          value={item.price !== null ? item.price || '' : item.item.price}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          label="Price"
                          onChange={(event) => handleChange(event, el.rebateId, item.itemId)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Expires"
                          inputFormat="MM/DD/YYYY"
                          value={moment(item.item.expires).format('MM/DD/YYYY')}
                          onChange={(date) => handleChange(date, el.rebateId, item.itemId)}
                          renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                      {el.flexibleItems.length < 2 && (
                        <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                          <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                            {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete Item">
                        <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </RadioGroup>
          {el.grouped && (
            <FormControl>
              <InputLabel id="rebates">Add New</InputLabel>
              <Select
                labelId="rebates"
                name="item"
                value="1"
                label="Add New"
                onChange={(event) => onAddNewItemToGroup(event, el.rebateId)}
                size="small"
              >
                <MenuItem value="1">Select an item from the list to add</MenuItem>
                {filtersData?.map((el, index) => (
                  <MenuItem key={index} value={el}>
                    {el.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      ))}

      <Divider sx={{ margin: '0 auto 25px' }} />

      <FormControl>
        <InputLabel id="rebates">Add New</InputLabel>
        <Select
          labelId="rebates"
          name="item"
          value="1"
          label="Add New"
          onChange={(event) => onAddNewItem(event)}
          size="small"
        >
          <MenuItem value="1">Select an item from the list to add</MenuItem>
          {filtersData?.map((el, index) => (
            <MenuItem key={index} value={el}>
              {el.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RebatesModule;
