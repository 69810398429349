import moment from 'moment-timezone';

import { Box, IconButton, Button, TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { button } from '../../static/theme-styles';

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
};

const DateSwitcher = ({ date, setDate }) => {
  const nextDay = () => {
    const nextDay = moment(date).add(1, 'day').format();
    setDate(nextDay);
  };

  const prevDay = () => {
    const prevDay = moment(date).subtract(1, 'day').format();
    setDate(prevDay);
  };

  const today = () => {
    const today = moment().format();
    setDate(today);
  };

  return (
    <Box sx={styles}>
      <IconButton onClick={prevDay}>
        <ChevronLeftIcon />
      </IconButton>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <MobileDatePicker
          label="Date"
          value={date}
          onChange={(newDate) => setDate(moment(new Date(newDate)).format())}
          renderInput={(params) => <TextField size="small" {...params} />}
        /> */}
        <DatePicker
          // label="Date"
          label={moment(date).format('dddd')}
          value={date}
          onChange={(newDate) => setDate(moment(new Date(newDate)).format())}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>

      <IconButton onClick={nextDay}>
        <ChevronRightIcon />
      </IconButton>

      <Button variant="contained" onClick={today} sx={button('secondary')}>
        Today
      </Button>
    </Box>
  );
};

export default DateSwitcher;
