import { useState, useEffect } from 'react';

import { Modal, Box, Typography, TextField, Button } from '@mui/material';

import { modal_style } from '../../../../static/styles';

const SystemTemplateCreateModal = ({ open, close, saveTemplate }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName('');
  }, [open]);

  return (
    <Modal open={open} onClose={close}>
      <Box sx={modal_style}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Add New Template
        </Typography>

        <TextField
          required
          label="Template Title"
          name="name"
          value={name}
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => saveTemplate(name)}
            size="small"
            sx={{ mr: 2 }}
            disabled={!name}
          >
            Save
          </Button>

          <Button variant="contained" color="error" onClick={close} size="small">
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SystemTemplateCreateModal;
