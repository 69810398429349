import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Divider,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';

// Icons
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services
import scopeOfWorkServices from '../../../../services/scopeOfWorkServices';

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

const ScopeOfWorkModule = ({ systemName, data, setSystems, system }) => {
  const [scopeOfWork, setScopeOfWork] = useState([]);
  const [filtersData, setFiltersData] = useState();
  const [currentItem, setCurrentItem] = useState(null);

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        scopeOfWorks: [
          ...prevState.filter((system) => system.name === systemName)[0].scopeOfWorks,
          {
            flexibleItems: [
              {
                item: event.target.value,
                price: null,
              },
            ],
            selectedItemIndex: 0,
          },
        ],
      },
    ]);
  };

  const onAddNewItemToGroup = (event, workID) => {
    const newItem = event.target.value;

    const updatedList = [
      ...scopeOfWork.filter((el) => el.workId !== workID),
      {
        ...scopeOfWork.filter((el) => el.workId === workID)[0],
        flexibleItems: [
          ...scopeOfWork.filter((el) => el.workId === workID)[0].flexibleItems,
          {
            item: newItem,
            price: null,
            itemId: scopeOfWork.filter((el) => el.workId === workID)[0].flexibleItems.length,
          },
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((el) => el.name !== systemName),
      {
        ...prevState.filter((el) => el.name === systemName)[0],
        scopeOfWorks: updatedList,
      },
    ]);
  };

  const onRemoveItem = (item) => {
    const updatedList = [
      ...scopeOfWork.filter((el) => !el.flexibleItems.includes(item)),
      {
        ...scopeOfWork.filter((el) => el.flexibleItems.includes(item))[0],
        flexibleItems: [
          ...scopeOfWork.filter((el) => el.flexibleItems.includes(item))[0].flexibleItems.filter((i) => i !== item),
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        scopeOfWorks: updatedList,
      },
    ]);
  };

  // Handler functions
  const handleChange = (event, workID, itemID) => {
    const key = event.target.name;
    const val = event.target.value;

    const updatedWorks = [
      ...scopeOfWork.filter((el) => el.workId !== workID),
      {
        ...scopeOfWork.filter((el) => el.workId === workID)[0],
        ...(key === 'selectedItemIndex' && {
          [key]: Number(val),
          flexibleItems: [...scopeOfWork.filter((el) => el.workId === workID)[0].flexibleItems].sort(function (a, b) {
            if (a.itemId < b.itemId) {
              return -1;
            }
            if (a.itemId > b.itemId) {
              return 1;
            }
            return 0;
          }),
        }),
        ...(key !== 'selectedItemIndex' && {
          flexibleItems: [
            ...scopeOfWork
              .filter((el) => el.workId === workID)[0]
              .flexibleItems.filter((item) => item.itemId !== itemID),
            {
              ...scopeOfWork
                .filter((el) => el.workId === workID)[0]
                .flexibleItems.filter((item) => item.itemId === itemID)[0],
              [key]: Number(val),
            },
          ].sort(function (a, b) {
            if (a.itemId < b.itemId) {
              return -1;
            }
            if (a.itemId > b.itemId) {
              return 1;
            }
            return 0;
          }),
        }),
      },
    ].sort(function (a, b) {
      if (a.workId < b.workId) {
        return -1;
      }
      if (a.workId > b.workId) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        scopeOfWorks: updatedWorks,
      },
    ]);
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleGroupItems = (item) => {
    const updatedList = [
      ...scopeOfWork.filter((el) => el !== item),
      {
        ...item,
        grouped: item.grouped ? false : true,
      },
    ].sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        scopeOfWorks: updatedList,
      },
    ]);
  };

  const dragStartHandler = (e, item) => setCurrentItem(item);

  const dragEndHandler = (e) => setCurrentItem(null);

  const dragOverHandler = (e) => e.preventDefault();

  const dragLeaveHandler = (e) => (e.target.style.boxShadow = 'none');

  const dropHandler = (e, item) => {
    e.preventDefault();
    e.target.style.boxShadow = 'none';

    const currentIndex = scopeOfWork.indexOf(currentItem);

    scopeOfWork.splice(currentIndex, 1);

    const dropIndex = scopeOfWork.indexOf(item);

    scopeOfWork.splice(dropIndex, 0, currentItem);

    system.scopeOfWorks = scopeOfWork;
  };

  // Async functions
  const getScopeOfWorkList = async () => {
    try {
      const response = await scopeOfWorkServices.getAllWorks(0, 1000000, true);

      if (response.status === 206) {
        let unique_values = response.data.data.reduce(
          (acc, el) => {
            if (acc.map[el.description]) return acc;

            acc.map[el.description] = true;
            acc.works.push(el);

            return acc;
          },
          {
            map: {},
            works: [],
          },
        );

        setFiltersData(unique_values.works);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getScopeOfWorkList();

    if (data) {
      const listOfWorks = [];

      data?.forEach((el, index) => {
        listOfWorks.push({
          ...el,
          workId: index,
          grouped: el.flexibleItems.length > 1 || el.grouped ? true : false,
          flexibleItems: [
            ...(() => {
              const items = [];

              el.flexibleItems.forEach((item, idx) => {
                items.push({
                  ...item,
                  itemId: idx,
                });
              });
              return items;
            })(),
          ],
        });
      });

      setScopeOfWork(listOfWorks);
    }
  }, [data]);

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Scope of Work</strong>
      </Typography>

      {scopeOfWork.map((el) => (
        <Box key={el.workId} sx={{ mt: 2, ...(el.grouped && proposal_grouped_items) }}>
          <RadioGroup value={el.selectedItemIndex}>
            {el.flexibleItems
              ?.sort(function (a, b) {
                if (a.itemId < b.itemId) {
                  return -1;
                }
                if (a.itemId > b.itemId) {
                  return 1;
                }
                return 0;
              })
              .map((item, index) => (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}
                  key={item.itemId}
                  draggable={true}
                  onDragStart={(e) => dragStartHandler(e, el)}
                  onDragEnd={(e) => dragEndHandler(e)}
                  onDragOver={(e) => dragOverHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDrop={(e) => dropHandler(e, el)}
                  className="item"
                >
                  <Grid container columnSpacing={1} rowSpacing={2}>
                    {el.grouped && (
                      <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                        <Radio
                          name="selectedItemIndex"
                          value={index}
                          checked={index === el.selectedItemIndex}
                          onChange={(event) => handleChange(event, el.workId, item.itemId)}
                        />
                      </Grid>
                    )}

                    {/* DESCRIPTION */}
                    <Grid item xs={12} md={8}>
                      <TextField label="Description" value={item.item.description} readOnly fullWidth size="small" />
                    </Grid>

                    {/* PRICE */}
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel required htmlFor="price">
                          Price
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="price"
                          name="price"
                          value={item.price !== null ? item.price : item.item.price}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          label="Price"
                          onChange={(event) => handleChange(event, el.workId, item.itemId)}
                        />
                      </FormControl>
                    </Grid>

                    {/* BUTTONS */}
                    <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                      {el.flexibleItems.length < 2 && (
                        <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                          <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                            {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete Item">
                        <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </RadioGroup>
          {el.grouped && (
            <FormControl>
              <InputLabel id="scope_of_work">Add New</InputLabel>
              <Select
                labelId="scope_of_work"
                name="item"
                value="1"
                label="Add New"
                onChange={(event) => onAddNewItemToGroup(event, el.workId)}
                size="small"
              >
                <MenuItem value="1">Select an item from the list to add</MenuItem>
                {filtersData?.map((el) => (
                  <MenuItem key={el.id} value={el}>
                    {el.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      ))}

      <Divider sx={{ margin: '0 auto 25px' }} />

      <FormControl>
        <InputLabel id="scope_of_work">Add New</InputLabel>
        <Select
          labelId="scope_of_work"
          name="item"
          value="1"
          label="Add New"
          onChange={(event) => onAddNewItem(event)}
          size="small"
        >
          <MenuItem value="1">Select an item from the list to add</MenuItem>
          {filtersData?.map((el) => (
            <MenuItem key={el.id} value={el}>
              {el.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ScopeOfWorkModule;
