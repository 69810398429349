import { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useConfirm } from 'material-ui-confirm';

import {
  Avatar,
  Box,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

// Custom Components
import { Spinner, SearchBox, UserDrawer } from '../..';

// Utils
import messages from '../../../static/messages';
import { formatPhone } from '../../../utils/formatter';
import { AuthContext } from '../../../context/AuthProvider';

// Services
import userServices from '../../../services/userServices';

// Hooks
import usePagination from '../../../hooks/usePagination';
import useSearch from '../../../hooks/useSearch';
import useDrawer from '../../../hooks/useDrawer';

// Styles
import {
  table_header_with_search,
  table_container_base,
  check_for_deleted_row,
  actions_column,
} from '../../../static/styles';

import { button } from '../../../static/theme-styles';

// External variables
const base_user = {
  email: '',
  firstName: '',
  lastName: '',
  identifier: '',
  title: '',
  role: '',
  phoneNumber: '',
  permissions: [],
  password: '',
  confirmPassword: '',
  isActive: true,
  preferences: {
    color: '',
    drawer: true,
    lightTheme: true,
    showDeletedRows: true,
  },
};

const UsersTable = () => {
  const { userData } = useContext(AuthContext);

  const [users, setUsers] = useState();
  const [totalCount, setTotalCount] = useState();
  const [showDeleted, setShowDeleted] = useState(userData.preferences.showDeletedRows);
  const [currentUser, setCurrentUser] = useState(null);
  const [isNewUser, setUserMode] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [file, setFile] = useState(null);

  const { drawer, openDrawer, foolCheck } = useDrawer(currentUser);
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { searchQuery, setSearchQuery, isSearching, searchRequest } = useSearch(userServices.search);
  const confirm = useConfirm();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setCurrentUser(null);
      setUserMode(false);
      setUserPhoto(null);
      setFile(null);
      resetErrors && resetErrors();
    }
  };

  const onEditUser = (id) => {
    const user = users.filter((el) => el.id === id)[0];
    delete user.createdAt;
    delete user.deletedAt;
    delete user.updatedAt;
    delete user.lastLoginAt;
    !user.photo && delete user.photo;
    setCurrentUser(user);
    openDrawer();
  };

  const onAddUser = () => {
    setUserMode(true);
    setCurrentUser(base_user);
    openDrawer();
  };

  // Handler functions
  const handleDelete = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('user'),
    })
      .then(() => removeUser(id))
      .catch(() => toast.warning(messages.cancel_message));
  };

  const handleRestore = (id) => {
    confirm({
      title: messages.confirm_message.restore,
      description: messages.confirm_message.restore_description('user'),
    })
      .then(() => restoreUser(id))
      .catch(() => toast.warning(messages.cancel_message));
  };

  // Async functions
  const getUsersList = async (skip, take, widthDeleted = true) => {
    try {
      const response = await userServices.getAllUsers(skip, take, widthDeleted);
      setTotalCount(response.data.total);
      setUsers(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
    }
  };

  const getSearchResult = useCallback(
    async (skip, take) => {
      const result = await searchRequest(skip, take);
      setTotalCount(result.total);
      setUsers(result.data);
      setShowDeleted(true);
      setPage(0);
    },
    [searchRequest],
  );

  const createUser = async () => {
    delete currentUser.isEdited;
    delete currentUser.confirmPassword;

    try {
      const response = await userServices.create(currentUser);

      if (response.status === 201) {
        onCloseDrawer();
        toast.success(messages.user.create);
        getUsersList(page * rowsPerPage, rowsPerPage, showDeleted);
        setUserMode(false);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateUser = async () => {
    delete currentUser.isEdited;
    delete currentUser.confirmPassword;

    try {
      const response = await userServices.update(currentUser.id, currentUser);

      if (userPhoto) updateUserPhoto();

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.user.update);
        getUsersList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateUserPhoto = async () => {
    try {
      const response = await userServices.updateUserPhoto(currentUser.id, file);

      if (response.status === 200) {
        getUsersList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeUser = async (id) => {
    try {
      const response = await userServices.remove(id);

      if (response.status === 200) {
        toast.success(messages.user.remove);
        getUsersList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const restoreUser = async (id) => {
    try {
      const response = await userServices.restore(id);

      if (response.status === 200) {
        toast.success(messages.user.restore);
        getUsersList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    searchQuery === ''
      ? getUsersList(page * rowsPerPage, rowsPerPage, showDeleted)
      : getSearchResult(page * rowsPerPage, rowsPerPage, showDeleted);
  }, [searchQuery, getSearchResult, page, rowsPerPage, showDeleted]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setShowDeleted(userData.isDeletedRowsVisible);
  }, []);

  // Check for data loading
  if (!users) return <Spinner />;

  return (
    <Fragment>
      <Box sx={table_header_with_search}>
        <Button variant="contained" endIcon={<AddIcon />} onClick={onAddUser} sx={button('primary', 'secondary')}>
          Add new
        </Button>

        <FormControlLabel
          value="end"
          control={<Switch color="primary" checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />}
          label="Show Deleted"
          labelPlacement="end"
          disabled={searchQuery !== ''}
        />
      </Box>

      <Paper>
        <SearchBox searchRequest={searchRequest} value={searchQuery} setValue={setSearchQuery} />
        <TableContainer sx={table_container_base}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="5%">Photo</TableCell>
                <TableCell width="15%">Full Name</TableCell>
                <TableCell width="15%">Title</TableCell>
                <TableCell width="15%">Email</TableCell>
                <TableCell width="15%">Phone</TableCell>
                <TableCell width="10%">Role</TableCell>
                <TableCell width="20%">Last Login</TableCell>
                <TableCell width="5%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSearching ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Spinner />
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment>
                  {users.length ? (
                    users.map((user) => (
                      <TableRow key={user.id} sx={check_for_deleted_row(!!user.deletedAt)} hover>
                        <TableCell scope="row">
                          {user.photo?.url && (
                            <Avatar alt={user.fullName} src={user.photo.url} sx={{ margin: '0 auto' }} />
                          )}
                        </TableCell>
                        <TableCell>{user.fullName}</TableCell>
                        <TableCell>{user.title}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{formatPhone(user.phoneNumber)}</TableCell>
                        <TableCell>{user.role}</TableCell>
                        <TableCell>
                          {user.lastLoginAt ? moment(user.lastLoginAt).format('MMM Do YYYY, h:mm:ss a') : 'No data'}
                        </TableCell>
                        <TableCell sx={actions_column}>
                          {!!user.deletedAt ? (
                            <Tooltip title="Restore User">
                              <IconButton onClick={() => handleRestore(user.id)}>
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Fragment>
                              <Tooltip title="Edit User">
                                <IconButton onClick={() => onEditUser(user.id)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete User">
                                <IconButton onClick={() => handleDelete(user.id)}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                        <strong>No Data</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Users per page"
      />

      <UserDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        isNewUser={isNewUser}
        userPhoto={userPhoto}
        setUserPhoto={setUserPhoto}
        setFile={setFile}
        createUser={createUser}
        updateUser={updateUser}
      />
    </Fragment>
  );
};

export default UsersTable;
