import { Grid } from '@mui/material';

import { no_print } from '../../pages/ProposalView';

const ProposalViewAwards = () => (
  <Grid container spacing={2}>
    <Grid
      item
      xs={12}
      md={4}
      sx={{
        ...no_print,
        textAlign: {
          xs: 'center',
        },
      }}
    >
      <img src="/images/awards/1.svg" alt="award" height="70" style={{ marginRight: '15%' }} />
      <img src="/images/awards/2.svg" alt="award" height="70" />
    </Grid>

    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
      <img src="/images/logo.svg" />
    </Grid>

    <Grid
      item
      xs={12}
      md={4}
      sx={{
        ...no_print,
        textAlign: {
          xs: 'center',
        },
      }}
    >
      <img src="/images/awards/3.png" alt="award" height="70" />
      <img src="/images/awards/4.svg" alt="award" height="70" style={{ margin: '0 5%' }} />
      <img src="/images/awards/5.svg" alt="award" height="70" />
    </Grid>
  </Grid>
);

export default ProposalViewAwards;
