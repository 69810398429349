import { Box, Link, Typography, FormControlLabel, Checkbox } from '@mui/material';

import { container } from '../../pages/ProposalView';

const ProposalViewEditSection = ({ id, client }) => (
  <Box
    component="section"
    sx={{
      ...container,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: {
        xs: '50px 15px 0',
        md: '15px 0 0',
      },
      flexWrap: {
        xs: 'wrap',
        md: 'nowrap',
      },
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!client && <Link href={`/proposals/${id}`}>Edit Proposal</Link>}
      <FormControlLabel control={<Checkbox />} label="Remove logo for print" />
    </Box>

    <Typography variant="body2" sx={{ mr: 0, ml: 'auto' }}>
      Questions? Contact your tech or call <Link href={`tel:619-572-6219`}>619-572-6219</Link>
    </Typography>
  </Box>
);

export default ProposalViewEditSection;
