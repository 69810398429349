import { Fragment } from 'react';
import moment from 'moment';

import { Grid, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const ProposalViewSystemRebates = ({ el, handleChange, system_index }) => (
  <Fragment>
    {el.flexibleItems.length < 2 ? (
      <Grid container sx={{ borderBottom: '1px solid #ccc', pb: 1, pt: 2 }}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 12 }}>{el.flexibleItems[0].item.description}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 12 }} textAlign="center">
            Expires {moment(el.flexibleItems[0].item.expires).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 12 }} textAlign="right">
            $
            {el.flexibleItems[0].price === null
              ? Number(el.flexibleItems[0].item.price).toFixed(2)
              : Number(el.flexibleItems[0].price).toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <FormControl sx={{ mt: 1, borderBottom: '1px solid #ccc', pb: 1, pt: 1 }} fullWidth>
        <FormLabel id="option" sx={{ fontSize: 12 }}>
          Select an option
        </FormLabel>
        <RadioGroup
          aria-labelledby="option"
          name="option"
          value={el.selectedItemIndex}
          onChange={(event) => handleChange(event, system_index, 'rebates', el)}
          sx={{
            '& .MuiFormControlLabel-label': {
              width: '100%',
            },
          }}
        >
          {el.flexibleItems.map((item, item_index) => (
            <FormControlLabel
              value={item_index}
              key={item_index}
              control={<Radio size="small" />}
              sx={{
                width: '100%',
              }}
              label={
                <Grid container>
                  <Grid item xs={4}>
                    <Typography sx={{ fontSize: 12 }}>{item.item.description}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontSize: 12 }} textAlign="center">
                      Expires {moment(item.item.expires).format('MM/DD/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontSize: 12 }} textAlign="right">
                      ${item.price === null ? Number(item.item.price).toFixed(2) : Number(item.price).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    )}
  </Fragment>
);

export default ProposalViewSystemRebates;
