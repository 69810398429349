const getLocalToken = () => {
  return localStorage.getItem('token');
};

const getLocalRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

const updateLocalTokens = (token, refreshToken) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

const removeTokens = () => {
  localStorage.clear();
};

const tokenServices = {
  getLocalToken,
  getLocalRefreshToken,
  updateLocalTokens,
  removeTokens,
};

export default tokenServices;
