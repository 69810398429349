import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Typography } from '@mui/material';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// Custom Components
import {
  Spinner,
  ClientDetailsTable,
  ClientPropertiesTable,
  BrowserTitle,
  ClientAppointmentsTable,
  ClientProposalsTable,
  ClientArchiveTable,
} from '../components';

// Utils
import messages from '../static/messages';

// Services
import clientsServices from '../services/clientsServices';

// Hooks
import useAccordion from '../hooks/useAccordion';

// External variables
const blocks = ['details', 'properties', 'appointments', 'proposals'];

const Client = () => {
  const [client, setClient] = useState();
  const [archive, setArchive] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { open, accordionToggle } = useAccordion(blocks);

  // Async functions
  const getClientData = async () => {
    try {
      const response = await clientsServices.getClientById(id);

      setClient(response.data);
    } catch (error) {
      if (error.response.data.status === 404) {
        navigate('/404');
      } else {
        toast.error(messages.errors.error_data_loaning);
        console.log(error);
      }
    }
  };

  // Hooks
  useEffect(() => {
    getClientData();
    accordionToggle('details');
  }, []);

  useEffect(() => {
    client?.properties.map((el) => {
      if (el.property.archiveAppointments) {
        setArchive((prevState) => [...prevState, ...el.property.archiveAppointments]);
      }
    });
  }, [client]);

  // Check for data loading
  if (!client) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title={`Client: ${client.fullName} | AirMaxx Pro`} />
      <Button
        variant="text"
        onClick={() =>
          navigate(
            `/clients${
              location.state ? `?page=${location?.state?.page}&per_page=${location?.state?.rowsPerPage}` : null
            }`,
          )
        }
        startIcon={<KeyboardBackspaceIcon />}
        sx={{ mb: 2 }}
      >
        Back to Clients
      </Button>

      {/* Client Details */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.details}
        onChange={() => accordionToggle('details')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Client Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ClientDetailsTable {...{ client, id, refresh: getClientData }} />
        </AccordionDetails>
      </Accordion>

      {/* Properties */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.properties}
        onChange={() => accordionToggle('properties')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Properties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ClientPropertiesTable properties={client?.properties} id={id} refresh={getClientData} />
        </AccordionDetails>
      </Accordion>

      {/* Appointments */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.appointments}
        onChange={() => accordionToggle('appointments')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Appointments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ClientAppointmentsTable appointments={client?.appointments} />
        </AccordionDetails>
      </Accordion>

      {/* Appointments */}
      {archive && (
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.appointments}
          onChange={() => accordionToggle('appointments')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Archive</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ClientArchiveTable appointments={archive} />
          </AccordionDetails>
        </Accordion>
      )}

      {/* Proposals */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.proposals}
        onChange={() => accordionToggle('proposals')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Proposals</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ClientProposalsTable proposals={client?.proposals} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Client;
