import api from '../api/axios';

const getAllProperties = (skip = 0, take = 1000000, withDeleted) => {
  const response = api.get(
    `/property?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  );

  return response;
};

const getPropertyById = (id) => {
  const response = api.get(`/property/${id}`);

  return response;
};

const create = (property) => {
  const response = api.post('/property', property);

  return response;
};

const update = (id, updatedProperty) => {
  const response = api.patch(`/property/${id}`, updatedProperty);

  return response;
};

const remove = (id) => {
  const response = api.delete(`/property/${id}`);

  return response;
};

const restore = (id) => {
  const response = api.get(`/property/${id}/restore`);

  return response;
};

const searchRequest = (skip = 0, take = 1000000, query, withDeleted = true, options = {}) => {
  const response = api.get(
    `/property?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&query=${query.replace(
      /#/g,
      '%23',
    )}&withDeleted=${withDeleted}`,
    { ...options },
  );

  return response;
};

const getPropertyTypes = (widthDeleted = false) => {
  const response = api.get(`/propertyType?take=1000000&skip=0&sort=deletedAt%2Cdesc&withDeleted=${widthDeleted}`);

  return response;
};

const getPropertyTypeById = (id) => {
  const response = api.get(`/propertyType/${id}`);

  return response;
};

const createPropertyType = (name) => {
  const response = api.post(`/propertyType`, {
    name,
  });

  return response;
};

const removePropertyType = (id) => {
  const response = api.delete(`/propertyType/${id}`);

  return response;
};

const restorePropertyType = (id) => {
  const response = api.get(`/propertyType/${id}/restore`);

  return response;
};

const searchPropertyType = (skip = 0, take = 1000000, query, withDeleted = true) => {
  const response = api.get(
    `/propertyType?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&query=${query}&withDeleted=${withDeleted}`,
  );

  return response;
};

const getInvalidProperties = (skip = 0, take = 1000000) => {
  const response = api.get(`/property?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&onlyUndetectedAddresses=true`);

  return response;
};

const propertiesServices = {
  getAllProperties,
  getPropertyById,
  create,
  update,
  remove,
  restore,
  searchRequest,
  getPropertyTypes,
  getPropertyTypeById,
  createPropertyType,
  removePropertyType,
  restorePropertyType,
  searchPropertyType,
  getInvalidProperties,
};

export default propertiesServices;
