import api from '../api/axios';

const getAllUsers = (skip = 0, take = 1000000, withDeleted) => {
  const response = api.get(
    `/user?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&withDeleted=${withDeleted}`,
  );

  return response;
};

const getUserById = (id) => {
  const response = api.get(`/user/${id}`);

  return response;
};

const getUserByRole = (role, withDeleted = false) => {
  const response = api.get(`/user?skip=0&sort=id%2Cdesc&withDeleted=${withDeleted}&role=${role}`);

  return response;
};

const create = (newUser) => {
  const response = api.post('/user', newUser);

  return response;
};

const update = (id, updatedUser) => {
  const response = api.patch(`/user/${id}`, updatedUser);

  return response;
};

const updateUserPhoto = (id, photo) => {
  const response = api.patch(
    `/user/${id}/photo`,
    { file: photo },
    {
      headers: { accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    },
  );

  return response;
};

const remove = (id) => {
  const response = api.delete(`/user/${id}`);

  return response;
};

const restore = (id) => {
  const response = api.get(`/user/${id}/restore`);

  return response;
};

const search = (skip = 0, take = 1000000, query, widthDeleted = false, options = {}) => {
  const response = api.get(`/user?skip=${skip}&take=${take}&sort=deletedAt%2Cdesc&sort=id%2Casc&query=${query}`, {
    ...options,
  });

  return response;
};

const getUserInfo = () => {
  const response = api.get('/auth/userInfo');

  return response;
};

const userServices = {
  getAllUsers,
  getUserById,
  create,
  update,
  updateUserPhoto,
  remove,
  restore,
  search,
  getUserByRole,
  getUserInfo,
};

export default userServices;
