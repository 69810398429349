import { useState, useEffect, useCallback, Fragment } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';

// Custom Components
import { Spinner, BrowserTitle, DateSwitcher, Calendar } from '../components';
import DayOffModal from '../components/Appointments/DayOffModal';

// Utils
import messages from '../static/messages';

// Services
import appointmentsServices from '../services/appointmentsServices';
import userServices from '../services/userServices';

// Styles
import { button } from '../static/theme-styles';

// External variables
import { defineTime } from '../components/Appointments/Calendar';

export const Appointments = () => {
  const [date, setDate] = useState(moment().format());
  const [appointments, setAppointments] = useState();
  const [queue, setQueue] = useState();
  const [columns, setColumns] = useState();
  const [techniciansList, setTechniciansList] = useState();
  const [dayOffModal, setDayOffModal] = useState(false);
  const [socket, setSocket] = useState();

  const navigate = useNavigate();
  moment.tz.setDefault('America/Los_Angeles');

  // Action functions
  const onAddAppointment = () => navigate('/appointments/create');

  const defineColumns = useCallback(
    (list) => {
      let dynamicColumns = list
        ?.map((tech) => ({
          info: tech,
          items: Array.from(Array(16), (_, index) => ({
            dump: true,
            time: defineTime(index),
          })),
        }))
        .sort(function (a, b) {
          if (a.info.createdAt < b.info.createdAt) return -1;
          if (a.info.createdAt > b.info.createdAt) return 1;

          return 0;
        });

      const queueColumn = {
        info: {
          name: 'Queue',
          color: '#808080',
        },
      };

      const misc = {
        info: {
          name: 'Misc',
          color: 'green',
        },
        items: Array.from(Array(16), (_, index) => ({
          dump: true,
          time: defineTime(index),
        })),
      };

      const installations = {
        info: {
          name: 'Installations',
          color: 'purple',
        },
        items: Array.from(Array(16), (_, index) => ({
          dump: true,
          time: defineTime(index),
        })),
      };

      setColumns([...dynamicColumns, installations, queueColumn, misc]);
    },
    [appointments],
  );

  const onAddDayOff = () => setDayOffModal(true);

  const onCloseModal = () => setDayOffModal(false);

  // Async functions
  const getTechniciansList = async () => {
    try {
      const response = await userServices.getUserByRole('technician');

      if (response.status === 206) {
        const technicians = response.data.data.map((tech) => ({
          tech_id: tech.id,
          name: `${tech.firstName} ${tech.lastName}`,
          color: tech.preferences?.color || 'red',
          photo: tech.photo?.url,
          createdAt: tech.createdAt,
        }));

        const alexData = await userServices.search(0, 10, 'alex@airmaxx.com');

        if (alexData.status === 206) {
          const alex = {
            tech_id: alexData.data.data[0].id,
            name: `${alexData.data.data[0].firstName} ${alexData.data.data[0].lastName}`,
            color: 'green',
            photo: alexData.data.data[0].photo?.url,
          };

          technicians.unshift(alex);
        }

        setTechniciansList(technicians);

        defineColumns(technicians);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getAppointments = async (date) => {
    setAppointments();
    try {
      const response = await appointmentsServices.getAllAppointments(date + 'T13:00:00.000Z');

      if (response.status === 206) {
        const appointments_la_time = response.data.data.map((el) => ({
          ...el,
          startTime: moment(el.startTime).tz('America/Los_Angeles').format(),
          endTime: moment(el.endTime).tz('America/Los_Angeles').format(),
        }));

        setAppointments(appointments_la_time);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getQueue = async () => {
    try {
      const response = await appointmentsServices.getAllAppointments(null, null, true);

      if (response.status === 206) setQueue(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getTechniciansList();
    // getAlexInfo();
  }, []);

  useEffect(() => {
    setAppointments([]);
    getAppointments(date.split('T')[0]);
    getQueue();
    if (techniciansList) defineColumns(techniciansList);
  }, [date]);

  // Socket
  useEffect(() => {
    // get all appointments
    // set current date
  }, [socket]);

  if (!columns) return <Spinner />;

  return (
    <Fragment>
      <Box component="section">
        <BrowserTitle title="Appointments | AirMaxx Pro" />

        <Box
          sx={{
            mt: 1,
            mb: 4,
            backgroundColor: '#F0F3F5',
            padding: '10px 0',
            display: 'flex',
            alignItems: 'center',
            gap: 50,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1, pl: 2 }}>
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              onClick={onAddAppointment}
              sx={button('primary', 'secondary')}
            >
              Add New
            </Button>

            <Button variant="contained" endIcon={<AddIcon />} onClick={onAddDayOff} sx={button('secondary')}>
              Add Time Off
            </Button>
          </Box>
          <DateSwitcher date={date} setDate={setDate} />
        </Box>

        <Calendar
          columns={columns}
          setColumns={setColumns}
          date={date}
          appointments={appointments}
          queue={queue}
          getAppointments={getAppointments}
          getQueue={getQueue}
        />
      </Box>

      {dayOffModal && (
        <DayOffModal
          open={dayOffModal}
          close={onCloseModal}
          technicians={techniciansList}
          getAppointments={getAppointments}
          getQueue={getQueue}
          date={date.split('T')[0]}
        />
      )}
    </Fragment>
  );
};
