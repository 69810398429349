import './userProfilePhoto.scss';

import { Box, Avatar, IconButton } from '@mui/material';

import PhotoCamera from '@mui/icons-material/PhotoCamera';

const UserProfilePhoto = ({ userInfo, onChangeImage, imgData }) => (
  <Box component="div" className="user_profile-photo-wrapper" sx={{ margin: '0 auto' }}>
    <Box component="label">
      <Avatar alt={userInfo.firstName} src={imgData ? imgData : userInfo.photo?.url} className="user_avatar" />

      <IconButton color="primary" aria-label="upload picture" className="update_photo-btn" component="div">
        <input hidden accept="image/*" type="file" onChange={onChangeImage} />
        <PhotoCamera />
      </IconButton>
    </Box>
  </Box>
);

export default UserProfilePhoto;
