import { Fragment } from 'react';

import { Typography } from '@mui/material';

import ProposalViewSystemRebates from './proposalView.systemRebates';
import ProposalViewSystemInstants from './proposalView.systemInstants';

const ProposalViewSystemRebatesAndInstants = ({ rebates, instants, handleChange, system_index }) => (
  <Fragment>
    <Typography variant="body2" sx={{ fontWeight: 600, pt: 2, mb: 1 }}>
      Instant Rebate(s)
    </Typography>

    {rebates.map(
      (el, idx) =>
        el.flexibleItems.length > 0 && (
          <ProposalViewSystemRebates key={idx} el={el} handleChange={handleChange} system_index={system_index} />
        ),
    )}

    {instants.map(
      (el, idx) =>
        el.flexibleItems.length > 0 && (
          <ProposalViewSystemInstants key={idx} el={el} handleChange={handleChange} system_index={system_index} />
        ),
    )}
  </Fragment>
);

export default ProposalViewSystemRebatesAndInstants;
