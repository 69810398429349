import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import { Divider, Grid, TextField, Button } from '@mui/material';

// Custom Components
import { UserProfilePhoto } from '../';

// Utils
import { internationalPhoneFormat } from '../../utils/formatter';
import messages from '../../static/messages';

// Services
import profileServices from '../../services/profileServices';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['firstName', 'lastName', 'phoneNumber'];

const Account = ({ userInfo, getUserData, setUserInfo, updateProfile, email }) => {
  const [errors, setErrors] = useState({});

  // Action functions
  const resetErrors = () => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const onUpdateAccountInfo = () => {
    const data = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phoneNumber: userInfo.phoneNumber,
    };

    updateProfile(data);
  };

  // Handler functions
  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    switch (key) {
      case 'phoneNumber':
        setUserInfo((prevState) => ({
          ...prevState,
          [key]: val === '1' ? '' : internationalPhoneFormat(val),
          isEdited: true,
        }));
        break;
      default:
        setUserInfo((prevState) => ({
          ...prevState,
          [key]: val,
          isEdited: true,
        }));
        break;
    }
  };

  const handleValidate = () => {
    required_fields.forEach((field) => {
      const el = userInfo[field];
      if (el === '' || el === null) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });

    if (userInfo.phoneNumber.length !== 12) {
      toast.error(messages.errors.invalid_format('Phone'));
      setErrors((prevState) => ({
        ...prevState,
        phoneNumber: true,
      }));
    }
  };

  // Async functions
  const uploadPhoto = async (event) => {
    const photo = event.target.files[0];

    try {
      const response = await profileServices.updatePhoto(photo);

      if (response.status === 200) {
        toast.success(messages.success.update.photo);
        getUserData();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    if (userInfo?.isEdited) {
      !Object.values(errors).includes(true) && onUpdateAccountInfo();
    }
  }, [errors]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <TextField
          label="Email"
          value={userInfo.email}
          readOnly
          fullWidth
          sx={{ mb: 3, input: { backgroundColor: '#eee !important', borderRadius: '10px' } }}
        />

        <TextField
          required
          name="firstName"
          label="Fist Name"
          defaultValue={userInfo.firstName}
          fullWidth
          sx={{ mb: 3 }}
          onChange={handleChange}
          error={errors.firstName}
          helperText={errors.firstName && messages.errors.required_field}
        />

        <TextField
          required
          name="lastName"
          label="Last Name"
          defaultValue={userInfo.lastName}
          fullWidth
          sx={{ mb: 3 }}
          onChange={handleChange}
          error={errors.lastName}
          helperText={errors.lastName && messages.errors.required_field}
        />

        <InputMask mask="+1 (999) 999-9999" defaultValue={userInfo.phoneNumber} maskChar=" " onChange={handleChange}>
          {() => (
            <TextField
              required
              name="phoneNumber"
              label="Phone"
              fullWidth
              sx={{ mb: 3 }}
              error={errors.phoneNumber}
              helperText={errors.phoneNumber && messages.errors.required_field}
            />
          )}
        </InputMask>

        <Button
          variant="contained"
          color="primary"
          sx={{ ...button('primary', 'secondary'), mr: 3 }}
          onClick={handleValidate}
          disabled={!userInfo.isEdited}
        >
          Save
        </Button>
      </Grid>

      <Grid item xs={12} md={3}>
        <UserProfilePhoto userInfo={userInfo} onChangeImage={uploadPhoto} />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Account;
