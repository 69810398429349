import { useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Button,
  IconButton,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Menu,
  MenuItem,
  Link,
} from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

// Custom Components
import { PropertyClientsDrawer } from '../..';

// Utils
import { formatPhone } from '../../../utils/formatter';
import { nullClear, nullClearObject, emptyStringClear } from '../../../utils/cleaners';
import messages from '../../../static/messages';

// Services
import propertiesServices from '../../../services/propertiesServices';

// Hooks
import usePagination from '../../../hooks/usePagination';
import useDrawer from '../../../hooks/useDrawer';

// Styles
import { actions_column, table_container_base } from '../../../static/styles';
import { button } from '../../../static/theme-styles';

const menuPaperProps = {
  elevation: 0,
  sx: {
    left: '105px !important',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiSvgIcon-root': {
      width: 25,
      height: 25,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

// External variables
export const base_client = {
  client: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  relation: '',
};

const PropertyClientsTable = ({ clientsList, id, refresh }) => {
  const [currentClient, setCurrentClient] = useState();
  const [drawerMode, setDrawerMode] = useState('find');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMore = Boolean(anchorEl);

  const confirm = useConfirm();
  const { drawer, openDrawer, foolCheck } = useDrawer(currentClient);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setDrawerMode('find');
      setCurrentClient(null);
      resetErrors && resetErrors();
    }
  };

  const onAddClient = () => {
    setCurrentClient(base_client);
    setDrawerMode('new');
    openDrawer();
  };

  const onFindClient = () => {
    setCurrentClient(base_client);
    openDrawer();
  };

  const onEditClient = (id) => {
    const client = clientsList.filter((el) => el.client.id === id)[0];
    delete client.createdAt;
    delete client.deletedAt;
    delete client.updatedAt;
    setCurrentClient(client);
    setDrawerMode('edit');
    openDrawer();
  };

  // Handler functions
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleDelete = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Client'),
    })
      .then(() => removeClient(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const addNewClient = async () => {
    delete currentClient.isEdited;

    const filterForEmptyValue = emptyStringClear(currentClient.client);
    const filterForNullValue = nullClear(clientsList);

    const newClient = {
      client: filterForEmptyValue,
      relation: currentClient.relation || '',
    };

    const updatedClients = {
      clients: [...filterForNullValue, newClient],
    };

    try {
      const response = await propertiesServices.update(id, updatedClients);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.property.update);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeClient = async (clientId) => {
    const updatedList = { clients: [...nullClear(clientsList.filter((el) => el.client.id !== clientId))] };

    try {
      const response = await propertiesServices.update(id, updatedList);

      if (response.status === 200) {
        toast.success(messages.property.update);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateClient = async () => {
    delete currentClient.isEdited;

    const filterForNullValue = nullClear(clientsList);

    const updatedClients = {
      clients: [...filterForNullValue.filter((el) => el.id !== currentClient.id), nullClearObject(currentClient)],
    };

    try {
      const response = await propertiesServices.update(id, updatedClients);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.property.update);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Button variant="contained" onClick={handleClick} endIcon={<AddIcon />} sx={{ ...button('secondary'), mb: 2 }}>
        Add Client
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMore}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        PaperProps={menuPaperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onFindClient}>
          <PersonSearchIcon /> Existing Client
        </MenuItem>
        <MenuItem onClick={onAddClient}>
          <PersonAddIcon /> New Client
        </MenuItem>
      </Menu>

      <TableContainer component={Paper} sx={table_container_base}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="25%">Name</TableCell>
              <TableCell width="25%">Email</TableCell>
              <TableCell width="20%">Phone</TableCell>
              <TableCell width="25%">Relation to Property</TableCell>
              <TableCell width="5%">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsList.length ? (
              clientsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client) => (
                <TableRow key={client.client.id}>
                  <TableCell scope="row">
                    <Link
                      className="full-col-flex-link"
                      href={`/clients/${client.client.id}`}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {client.client.firstName} {client.client.lastName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className="full-col-flex-link"
                      href={`/clients/${client.client.id}`}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {client.client.email}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className="full-col-flex-link"
                      href={`/clients/${client.client.id}`}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {formatPhone(client.client.phoneNumber)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className="full-col-flex-link"
                      href={`/clients/${client.client.id}`}
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {client.relation}
                    </Link>
                  </TableCell>
                  <TableCell sx={actions_column}>
                    <Tooltip title="Edit Client">
                      <IconButton aria-label="edit" onClick={() => onEditClient(client.client.id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Client">
                      <IconButton aria-label="remove" onClick={() => handleDelete(client.client.id)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={clientsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Clients per page"
      />

      <PropertyClientsDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        drawerMode={drawerMode}
        currentClient={currentClient}
        setCurrentClient={setCurrentClient}
        addNewClient={addNewClient}
        updateClient={updateClient}
      />
    </Fragment>
  );
};

export default PropertyClientsTable;
