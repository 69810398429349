const permission = {
  matrix: 'matrix',
  schedule: 'schedule',
  timer: 'timer',
  messages: 'messages',
  proposals: 'proposals',
  clients: 'clients',
  invoices: 'invoices',
  items: 'items',
  properties: 'properties',
  installers: 'installers',
  settings: 'settings',
  reminders: 'reminders',
  logs: 'logs',
};

export default permission;
