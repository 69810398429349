import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import locationServices from '../services/locationServices';
import propertiesServices from '../services/propertiesServices';
import clientsServices from '../services/clientsServices';
import appointmentsServices from '../services/appointmentsServices';

import messages from '../static/messages';

const useLocationAutocomplete = (minLength = 2) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [query, setQuery] = useState();

  const handleSearchLocation = (str) => (str.length > minLength ? setQuery(str) : setLocationOptions([]));

  const getOptions = async () => {
    try {
      const response = await locationServices.getSuggestion(query);

      if (response.status === 200) {
        setLocationOptions(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  useEffect(() => {
    if (query) getOptions();
  }, [query]);

  return { handleSearchLocation, locationOptions };
};

useLocationAutocomplete.PropTypes = {
  minLength: PropTypes.number,
};

const useClientsAutocomplete = (minLength = 1) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [query, setQuery] = useState();

  const handleSearchClient = (str) => (String(str).length > minLength ? setQuery(str) : setClientOptions([]));

  const getOptions = async () => {
    try {
      const response = await clientsServices.searchRequest(0, 20, query);

      if (response.status === 206) {
        setClientOptions(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  useEffect(() => {
    if (query) getOptions();
  }, [query]);

  return { handleSearchClient, clientOptions };
};

useClientsAutocomplete.PropTypes = {
  minLength: PropTypes.number,
};

const usePropertiesAutocomplete = (minLength = 2, withDeleted) => {
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [query, setQuery] = useState();

  const handleSearchProperty = (str) => (String(str).length > minLength ? setQuery(str) : setPropertyOptions([]));

  const getOptions = async () => {
    try {
      const response = await propertiesServices.searchRequest(0, 20, query, withDeleted);

      if (response.status === 206) {
        setPropertyOptions(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  useEffect(() => {
    if (query) getOptions();
  }, [query]);

  return { handleSearchProperty, propertyOptions };
};

usePropertiesAutocomplete.PropTypes = {
  minLength: PropTypes.number,
};

const useAppointmentTypeAutocomplete = (minLength = 1) => {
  const [typeOptions, setTypeOptions] = useState([]);
  const [query, setQuery] = useState();

  const handleSearchType = (str) => (String(str).length > minLength ? setQuery(str) : setTypeOptions([]));

  const getOptions = async () => {
    try {
      const response = await appointmentsServices.getAppointmentTypes(query);

      if (response.status === 206) {
        setTypeOptions(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  useEffect(() => {
    if (query) getOptions();
  }, [query]);

  return { handleSearchType, typeOptions };
};

useAppointmentTypeAutocomplete.PropTypes = {
  minLength: PropTypes.number,
};

const usePropertyTypeAutocomplete = (minLength = 1) => {
  const [typeOptions, setTypeOptions] = useState([]);
  const [query, setQuery] = useState();

  const handleSearchType = (str) => (String(str).length > minLength ? setQuery(str) : setTypeOptions([]));

  const getOptions = async () => {
    try {
      const response = await propertiesServices.searchPropertyType(0, 1000000, query);

      if (response.status === 206) {
        setTypeOptions(response.data.data);
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  useEffect(() => {
    if (query) getOptions();
  }, [query]);

  return { handleSearchType, typeOptions };
};

usePropertyTypeAutocomplete.PropTypes = {
  minLength: PropTypes.number,
};

const useSmartLocationAutocomplete = (minLength = 2) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    let controller = new AbortController(); // Create a new AbortController instance for each request
    const signal = controller.signal;

    const getOptions = async () => {
      try {
        const response = await locationServices.getList(query, signal);

        if (response.status === 206) {
          const suggestion = [
            ...response.data.properties.data,
            ...response.data.locations.data.map((el) => ({
              address: el,
            })),
          ];

          setLocationOptions(suggestion);
        }
      } catch (error) {
        if (error.name !== 'TypeError') {
          toast.error(messages.errors.error_data_loaning);
          console.log(error);
        }
      }
    };

    if (query.length >= minLength) getOptions();
    else setLocationOptions([]);

    // Clean up previous request before making a new one
    return () => {
      setLocationOptions([]);
      controller.abort();
      controller = new AbortController();
    };
  }, [query, minLength]);

  const handleSearchLocation = (str) => setQuery(str);

  return { handleSearchLocation, locationOptions };
};

useSmartLocationAutocomplete.PropTypes = {
  minLength: PropTypes.number,
};

export {
  useLocationAutocomplete,
  useClientsAutocomplete,
  usePropertiesAutocomplete,
  useAppointmentTypeAutocomplete,
  usePropertyTypeAutocomplete,
  useSmartLocationAutocomplete,
};
