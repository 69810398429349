import axios from 'axios';

import { API_URL } from '../api/axios';

const getPublicProposal = (proposal_id, client_token) => {
  const response = axios.get(`${API_URL}/client/proposal/${proposal_id}?token=${client_token}`);

  return response;
};

const updatePublicProposal = (proposal_id, client_token, data) => {
  const response = axios.patch(`${API_URL}/client/proposal/${proposal_id}?token=${client_token}`, data);

  return response;
};

const getPublicSettings = () => {
  const response = axios.get(`${API_URL}/setting/company`);

  return response;
};

const publicServices = {
  getPublicProposal,
  updatePublicProposal,
  getPublicSettings,
};

export default publicServices;
