import { useState, useEffect, Fragment } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import {
  Grid,
  Box,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// Utils
import messages from '../../static/messages';

// Services
import appointmentsServices from '../../services/appointmentsServices';

// External variables
import { modal_style } from '../../static/styles';
import { button } from '../../static/theme-styles';

const base_day_off = {
  startAt: null,
  finishAt: null,
  startDate: null,
  endDate: null,
  notes: '',
  user: '',
  typeId: 1, // Day Off Appointment Type
};

const required_fields = ['user', 'startDate', 'endDate', 'startAt', 'finishAt'];

const appointment_times = [
  { time12: '07:00 AM', time24: '07:00' },
  { time12: '08:00 AM', time24: '08:00' },
  { time12: '09:00 AM', time24: '09:00' },
  { time12: '10:00 AM', time24: '10:00' },
  { time12: '11:00 AM', time24: '11:00' },
  { time12: '12:00 PM', time24: '12:00' },
  { time12: '01:00 PM', time24: '13:00' },
  { time12: '02:00 PM', time24: '14:00' },
  { time12: '03:00 PM', time24: '15:00' },
  { time12: '04:00 PM', time24: '16:00' },
  { time12: '05:00 PM', time24: '17:00' },
  { time12: '06:00 PM', time24: '18:00' },
  { time12: '07:00 PM', time24: '19:00' },
  { time12: '08:00 PM', time24: '20:00' },
  { time12: '09:00 PM', time24: '21:00' },
  { time12: '10:00 PM', time24: '22:00' },
];

const DayOffModal = ({ open, close, technicians, getAppointments, getQueue, date }) => {
  const [errors, setErrors] = useState({});
  const [dayOff, setDayOff] = useState(base_day_off);

  moment.tz.setDefault('America/Los_Angeles');
  const confirm = useConfirm();

  // Action functions
  const resetErrors = () => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  // Handler functions
  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setDayOff((prevState) => ({
      ...prevState,
      [key]: val,
      isEdited: true,
    }));
  };

  const handleClose = () => {
    if (dayOff.isEdited) {
      confirm({
        title: messages.confirm_message.clear,
        description: messages.confirm_message.clear_description,
      })
        .then(() => close())
        .catch(() => toast.info(messages.cancel_message));
    } else close();
  };

  const handleValidate = () => {
    required_fields.forEach((field) => {
      if (dayOff[field] === '' || dayOff[field] === null || !dayOff[field]) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
  };

  // Async functions
  const createDayOff = async () => {
    const start = moment(dayOff.startDate).set({
      hour: dayOff.startAt?.split(':')[0],
      minute: dayOff.startAt?.split(':')[1],
      second: '0',
    });

    const end = moment(dayOff.endDate).set({
      hour: dayOff.finishAt?.split(':')[0],
      minute: dayOff.finishAt?.split(':')[1],
      second: '0',
    });

    const data = {
      type: {
        id: dayOff.typeId,
      },
      user: {
        id: dayOff.user,
      },
      startTime: moment.utc(start).format(),
      endTime: moment.utc(end).format(),
      ...(dayOff.notes && {
        notes: [
          {
            note: dayOff.notes,
          },
        ],
      }),
    };

    try {
      const response = await appointmentsServices.create(data);

      if (response.status === 201) {
        toast.success(messages.appointment.create);
        close();
        getAppointments(date);
        getQueue();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    if (dayOff?.isEdited) {
      !Object.values(errors).includes(true) && createDayOff();
    }
  }, [errors]);

  return (
    <Modal open={open} onClose={close}>
      <Box sx={modal_style}>
        <Typography variant="h5">Add Tech Time Off</Typography>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="user" error={errors.user}>
                Technician *
              </InputLabel>
              <Select
                required
                labelId="user"
                name="user"
                value={dayOff?.user}
                label="Technician *"
                onChange={handleChange}
                error={errors.user}
              >
                {technicians?.map((tech) => (
                  <MenuItem key={tech.tech_id} value={tech.tech_id}>
                    {tech.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.user && (
                <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date *"
                inputFormat="MM/DD/YYYY"
                value={dayOff.startDate}
                onChange={(date) =>
                  setDayOff((prevState) => ({
                    ...prevState,
                    startDate: moment.utc(date.$d).format('MM/DD/YYYY'),
                  }))
                }
                disablePast
                renderInput={(params) => (
                  <Fragment>
                    <TextField {...params} name="startDate" fullWidth error={errors.startDate} />
                    {errors.startDate && (
                      <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                    )}
                  </Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="startAt" error={errors.startAt}>
                Start Time *
              </InputLabel>
              <Select
                labelId="startAt"
                name="startAt"
                value={dayOff?.startAt || ''}
                label="Start Time *"
                onChange={handleChange}
                error={errors.startAt}
              >
                {appointment_times.map((time, index) => (
                  <MenuItem key={index} value={time.time24}>
                    {time.time12}
                  </MenuItem>
                ))}
              </Select>
              {errors.startAt && (
                <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="End Date *"
                inputFormat="MM/DD/YYYY"
                value={dayOff.endDate}
                onChange={(date) =>
                  setDayOff((prevState) => ({
                    ...prevState,
                    endDate: moment.utc(date.$d).format('MM/DD/YYYY'),
                  }))
                }
                // disabled
                disablePast
                renderInput={(params) => (
                  <Fragment>
                    <TextField {...params} name="endDate" fullWidth error={errors.endDate} />
                    {errors.endDate && (
                      <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                    )}
                  </Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="finishAt" error={errors.finishAt}>
                End Time *
              </InputLabel>
              <Select
                labelId="finishAt"
                name="finishAt"
                value={dayOff?.finishAt || ''}
                label="End Time *"
                onChange={handleChange}
                error={errors.finishAt}
              >
                {appointment_times.map((time, index) => (
                  <MenuItem key={index} value={time.time24}>
                    {time.time12}
                  </MenuItem>
                ))}
              </Select>
              {errors.finishAt && (
                <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="notes"
              label="Notes"
              multiline
              rows={2}
              value={dayOff.notes}
              fullWidth
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" onClick={handleValidate} sx={button('primary', 'secondary')}>
              Save
            </Button>
            <Button variant="contained" sx={{ ...button('secondary'), ml: 2 }} onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DayOffModal;
