import { Fragment } from 'react';
import moment from 'moment';

import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Hooks
import usePagination from '../../../hooks/usePagination';

// Styles
import { table_container_base } from '../../../static/styles';

const ClientProposalsTable = ({ proposals }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();

  return (
    <Fragment>
      <TableContainer component={Paper} sx={table_container_base}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="20%">Status</TableCell>
              <TableCell width="20%">Public ID</TableCell>
              <TableCell width="40%">Property</TableCell>
              <TableCell width="20%">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposals.length ? (
              proposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((proposal) => (
                <TableRow key={proposal.id}>
                  <TableCell scope="row">
                    <Link
                      href={`/proposals/${proposal.id}`}
                      sx={{ fontWeight: 'bold', justifyContent: 'center' }}
                      className="full-col-flex-link"
                      underline="none"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {proposal.status === 'Active' && (
                        <Fragment>
                          <CircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} /> Active
                        </Fragment>
                      )}
                      {proposal.status === 'Pending' && (
                        <Fragment>
                          <CircleIcon sx={{ color: 'orange', mr: 1, width: 15, height: 15 }} /> Pending
                        </Fragment>
                      )}
                      {proposal.status === 'Completed' && (
                        <Fragment>
                          <CheckCircleIcon sx={{ color: 'green', mr: 1, width: 15, height: 15 }} /> Completed
                        </Fragment>
                      )}
                      {proposal.status === 'Cancelled' && (
                        <Fragment>
                          <CancelIcon sx={{ color: 'red', mr: 1, width: 15, height: 15 }} /> Cancelled
                        </Fragment>
                      )}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/proposals/${proposal.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      className="full-col-flex-link"
                      // sx={link_styles}
                    >
                      {proposal.publicId}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/proposals/${proposal.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      className="full-col-flex-link"
                      // sx={link_styles}
                    >
                      {proposal.property.address}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/proposals/${proposal.id}`}
                      color="rgba(0, 0, 0, 0.87)"
                      underline="none"
                      className="full-col-flex-link"
                      // sx={link_styles}
                    >
                      {moment.utc(proposal.createdAt).format('MM/DD/YYYY hh:mm A')}
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                  <strong>No Data</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={proposals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Clients per page"
      />
    </Fragment>
  );
};

export default ClientProposalsTable;
