import { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import { toast } from 'react-toastify';

import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  ButtonGroup,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileCopyIcon from '@mui/icons-material/FileCopy';

// Custom Components
import { Spinner, SearchBox, ScopeOfWorkDrawer } from '../..';

// Utils
import messages from '../../../static/messages';
import { AuthContext } from '../../../context/AuthProvider';

// Services
import scopeOfWorkServices from '../../../services/scopeOfWorkServices';

// Hooks
import usePagination from '../../../hooks/usePagination';
import useSearch from '../../../hooks/useSearch';
import useDrawer from '../../../hooks/useDrawer';

// Styles
import { table_container_base, check_for_deleted_row, actions_column } from '../../../static/styles';

import { button } from '../../../static/theme-styles';

// External variables
const base_item = {
  price: '',
  description: '',
};

const ScopeOfWorkTable = () => {
  const { userData } = useContext(AuthContext);

  const [works, setWorks] = useState();
  const [showDeleted, setShowDeleted] = useState(userData.preferences.showDeletedRows);
  const [totalCount, setTotalCount] = useState();
  const [currentItem, setCurrentItem] = useState(null);
  const [isNewItem, setItemMode] = useState(false);

  const { searchQuery, setSearchQuery, isSearching, searchRequest } = useSearch(scopeOfWorkServices.search);
  const { rowsPerPage, page, setPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { drawer, openDrawer, foolCheck } = useDrawer(currentItem);
  const confirm = useConfirm();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setCurrentItem(null);
      setItemMode(false);
      resetErrors && resetErrors();
    }
  };

  const onEditItem = (item) => {
    delete item.createdAt;
    delete item.deletedAt;
    delete item.updatedAt;
    setCurrentItem(item);
    openDrawer();
  };

  const onAddItem = () => {
    setItemMode(true);
    setCurrentItem(base_item);
    openDrawer();
  };

  const onCopyItem = (item) => {
    setItemMode(true);
    setCurrentItem({
      ...item,
      isEdited: true,
    });
    openDrawer();
  };

  // Handler functions
  const handleDelete = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Item'),
    })
      .then(() => removeItem(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleRestore = (id) => {
    confirm({
      title: messages.confirm_message.restore,
      description: messages.confirm_message.restore_description('Item'),
    })
      .then(() => restoreItem(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleCopy = (item) => {
    confirm({ title: messages.confirm_message.copy, description: messages.confirm_message.copy_description('Item') })
      .then(() => onCopyItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const getWorksList = async (skip, take, widthDeleted = true) => {
    try {
      const response = await scopeOfWorkServices.getAllWorks(skip, take, widthDeleted);
      setTotalCount(response.data.total);
      setWorks(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getSearchResult = useCallback(
    async (skip, take) => {
      const result = await searchRequest(skip, take);
      setTotalCount(result.total);
      setWorks(result.data);
      // setPage(0);
    },
    [searchRequest],
  );

  const createItem = async () => {
    delete currentItem.isEdited;

    try {
      const response = await scopeOfWorkServices.create(currentItem);

      if (response.status === 201) {
        onCloseDrawer();
        toast.success(messages.success.update.item.save);
        getWorksList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateItem = async () => {
    delete currentItem.isEdited;

    try {
      const response = await scopeOfWorkServices.update(currentItem.id, currentItem);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.success.update.common);
        getWorksList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const removeItem = async (id) => {
    try {
      const response = await scopeOfWorkServices.remove(id);

      if (response.status === 200) {
        toast.success(messages.success.update.item.remove);
        getWorksList(page * rowsPerPage, rowsPerPage, showDeleted);
        setSearchQuery('');
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const restoreItem = async (id) => {
    try {
      const response = await scopeOfWorkServices.restore(id);

      if (response.status === 200) {
        toast.success(messages.success.update.item.restore);
        getWorksList(page * rowsPerPage, rowsPerPage, showDeleted);
        setSearchQuery('');
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const exportTable = async () => {
    try {
      const response = await scopeOfWorkServices.fileExport();

      if (response.status === 200) {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.headers['content-disposition'].split(`"`)[1];
        link.click();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const importTable = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const response = await scopeOfWorkServices.fileImport(file);

        if (response.status === 204) {
          toast.success(messages.success.success_import);
          getWorksList(page * rowsPerPage, rowsPerPage, showDeleted);
        }
      } catch (error) {
        toast.error(messages.errors.error_try_again);
        console.log(error);
      }
    }
  };

  // Hooks
  useEffect(() => {
    searchQuery === ''
      ? getWorksList(page * rowsPerPage, rowsPerPage, showDeleted)
      : getSearchResult(page * rowsPerPage, rowsPerPage, showDeleted);
  }, [searchQuery, getSearchResult, page, rowsPerPage, showDeleted]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setShowDeleted(userData.isDeletedRowsVisible);
  }, []);

  // Check for data loading
  if (!works) return <Spinner />;

  return (
    <Box component="section">
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            mb: 2,
          },
        })}
      >
        <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" endIcon={<AddIcon />} onClick={onAddItem} sx={button('primary', 'secondary')}>
            Add new
          </Button>

          <ButtonGroup aria-label="import and export" sx={{ ml: 3 }}>
            <Tooltip title="Import">
              <IconButton aria-label="import" component="label">
                <CloudUploadIcon />
                <input hidden accept=".csv" type="file" onChange={importTable} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export">
              <IconButton aria-label="export" onClick={exportTable}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Box>

        <FormControlLabel
          value="end"
          control={<Switch color="primary" checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />}
          label="Show Deleted"
          labelPlacement="end"
          disabled={searchQuery !== ''}
        />
      </Box>

      <Paper>
        <SearchBox searchRequest={searchRequest} value={searchQuery} setValue={setSearchQuery} />
        <TableContainer sx={table_container_base}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="85%">Description</TableCell>
                <TableCell width="10%">Price</TableCell>
                <TableCell width="5%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSearching ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Spinner />
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment>
                  {works.length ? (
                    works.map((item) => (
                      <TableRow key={item.id} sx={check_for_deleted_row(!!item.deletedAt)} hover>
                        <TableCell scope="row">{item.description}</TableCell>
                        <TableCell>
                          {item.price.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </TableCell>
                        <TableCell sx={actions_column}>
                          {!!item.deletedAt ? (
                            <Tooltip title="Restore Item">
                              <IconButton aria-label="restore" onClick={() => handleRestore(item.id)}>
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Fragment>
                              <Tooltip title="Edit Item">
                                <IconButton aria-label="edit" onClick={() => onEditItem(item)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Duplicate Item">
                                <IconButton aria-label="duplicate" onClick={() => handleCopy(item)}>
                                  <FileCopyIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete Item">
                                <IconButton aria-label="remove" onClick={() => handleDelete(item.id)}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                        <strong>No Data</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Items per page"
      />

      <ScopeOfWorkDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        isNewItem={isNewItem}
        createItem={createItem}
        updateItem={updateItem}
      />
    </Box>
  );
};

export default ScopeOfWorkTable;
