import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Typography } from '@mui/material';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// Custom Components
import {
  Spinner,
  PropertyDetailsTable,
  PropertyClientsTable,
  PropertyMediaTable,
  BrowserTitle,
  PropertyProposalsTable,
  PropertyAppointmentsTable,
  PropertyArchiveTable,
} from '../components';

// Utils
import messages from '../static/messages';

// Services
import propertiesServices from '../services/propertiesServices';

// Hooks
import useAccordion from '../hooks/useAccordion';

// External variables
const blocks = ['details', 'clients', 'appointments', 'archive', 'proposals', 'media'];

export const Property = () => {
  const [property, setProperty] = useState();

  const { id } = useParams();
  const { open, accordionToggle } = useAccordion(blocks);
  const navigate = useNavigate();
  const location = useLocation();

  // Async functions
  const getPropertyData = async () => {
    try {
      const response = await propertiesServices.getPropertyById(id);

      if (response.status === 200) {
        setProperty(response.data);
      }
    } catch (error) {
      if (error.response.data.status === 404) {
        navigate('/404');
      } else {
        toast.error(messages.errors.error_data_loaning);
        console.log(error);
      }
    }
  };

  // Hooks
  useEffect(() => {
    getPropertyData();
    accordionToggle('details');
  }, []);

  if (!property) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title={`Property: ${property?.address} | AirMaxx Pro`} />
      <Button
        variant="text"
        onClick={() =>
          navigate(
            `/properties${
              location.state ? `?page=${location?.state?.page}&per_page=${location?.state?.rowsPerPage}` : null
            }`,
          )
        }
        startIcon={<KeyboardBackspaceIcon />}
        sx={{ mb: 2 }}
      >
        Back to Properties
      </Button>

      {/* Property Details */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.details}
        onChange={() => accordionToggle('details')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Property Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PropertyDetailsTable {...{ property, id, refresh: getPropertyData }} />
        </AccordionDetails>
      </Accordion>

      {/* Clients */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.clients}
        onChange={() => accordionToggle('clients')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Clients</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PropertyClientsTable clientsList={property?.clients} id={id} refresh={getPropertyData} />
        </AccordionDetails>
      </Accordion>

      {/* Appointments */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.appointments}
        onChange={() => accordionToggle('appointments')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Appointments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PropertyAppointmentsTable appointments={property?.appointments} />
        </AccordionDetails>
      </Accordion>

      {/* Archive */}
      {property?.archiveAppointments && (
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.archive}
          onChange={() => accordionToggle('archive')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Archive</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PropertyArchiveTable appointments={property?.archiveAppointments} />
          </AccordionDetails>
        </Accordion>
      )}

      {/* Proposals */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.proposals}
        onChange={() => accordionToggle('proposals')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Proposals</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PropertyProposalsTable proposals={property?.proposals} />
        </AccordionDetails>
      </Accordion>

      {/* Media */}
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={open.media}
        onChange={() => accordionToggle('media')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Media</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PropertyMediaTable mediaList={property?.media} id={id} refresh={getPropertyData} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
