import { useState, useEffect, Fragment } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';

import {
  Grid,
  Box,
  Button,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from '@mui/material';

// Utils
import messages from '../../static/messages';

// Services
import appointmentsServices from '../../services/appointmentsServices';

// External variables
import { modal_style } from '../../static/styles';
import { button } from '../../static/theme-styles';

const required_fields = ['note'];

const CancelModal = ({ open, close, data, setCancelData, cancellation }) => {
  const [errors, setErrors] = useState({});

  const confirm = useConfirm();

  // Handler functions
  const handleClose = () => {
    if (data.reason) {
      confirm({
        title: messages.confirm_message.clear,
        description: messages.confirm_message.clear_description,
      })
        .then(() => close())
        .catch(() => toast.info(messages.cancel_message));
    } else close();
  };

  const handleChange = (event) => {
    setCancelData((prevState) => ({
      ...prevState,
      reason: event.target.value,
    }));
  };

  return (
    <Modal open={open} onClose={close}>
      <Box sx={modal_style}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              // name="note"
              label="Cancel Reason"
              multiline
              rows={2}
              // value={dayOff.notes}
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              disabled={!data?.reason}
              variant="contained"
              onClick={cancellation}
              sx={button('primary', 'secondary')}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CancelModal;
