import { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TabPanel, BrowserTitle } from '../components';

function addTabIndex(index) {
  return {
    id: `logs-tab-${index}`,
    'aria-controls': `logs-tabpanel-${index}`,
  };
}

export const Logs = () => {
  const location = window.location.pathname;
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const tabSwitcher = (e, val) => {
    setActiveTab(val);

    switch (val) {
      case 1:
        history('/logs/clients');
        break;
      case 2:
        history('/logs/properties');
        break;
      case 3:
        history('/logs/proposals');
        break;
      case 4:
        history('/logs/invoices');
        break;
      case 5:
        history('/logs/items');
        break;
      default:
        history('/logs');
        break;
    }
  };

  useEffect(() => {
    switch (location) {
      case '/logs/clients':
        setActiveTab(1);
        break;
      case '/logs/properties':
        setActiveTab(2);
        break;
      case '/logs/proposals':
        setActiveTab(3);
        break;
      case '/logs/invoices':
        setActiveTab(4);
        break;
      case '/logs/items':
        setActiveTab(5);
        break;
      default:
        setActiveTab(0);
        break;
    }
  }, [location]);

  return (
    <section>
      <BrowserTitle title="Logs | AirMaxx Pro" />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={tabSwitcher} aria-label="logs tabs">
            <Tab
              label="Appointments"
              {...addTabIndex(0)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Clients"
              {...addTabIndex(1)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Properties"
              {...addTabIndex(2)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Proposals"
              {...addTabIndex(3)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Invoices"
              {...addTabIndex(4)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
            <Tab
              label="Items"
              {...addTabIndex(5)}
              sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
            />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}></TabPanel>
        <TabPanel value={activeTab} index={1}></TabPanel>
        <TabPanel value={activeTab} index={2}></TabPanel>
        <TabPanel value={activeTab} index={3}></TabPanel>
        <TabPanel value={activeTab} index={4}></TabPanel>
        <TabPanel value={activeTab} index={6}></TabPanel>
      </Box>
    </section>
  );
};
