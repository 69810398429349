import { Box, ButtonGroup, Button, Typography } from '@mui/material';

const header_styles = {
  background: '#fff',
  boxShadow: '0 3px 6px -1px rgba(0,0,0,0.3)',
  padding: '1rem 0',
  position: 'fixed',
  width: '100%',
  zIndex: 10,
};

const container = {
  maxWidth: 950,
  margin: '0 auto',
  display: 'flex',
  flexWrap: {
    md: 'nowrap',
    xs: 'wrap',
  },
  justifyContent: {
    xs: 'center',
    md: 'space-between',
  },
  alignItems: 'center',
};

const price_styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: { xs: '15px 0 0' },
};

const ProposalViewHeader = ({
  handlePrint,
  handleEmailModalOpen,
  handleSignatureModalOpen,
  isAccepted,
  token,
  totalPay,
}) => (
  <Box component="header" sx={header_styles}>
    <Box sx={container}>
      <ButtonGroup>
        {/* <Button variant="contained" disabled>
          PDF
        </Button> */}
        <Button variant="contained" onClick={handlePrint}>
          PRINT
        </Button>
        {!token && (
          <Button variant="text" onClick={handleEmailModalOpen}>
            Email this proposal
          </Button>
        )}
      </ButtonGroup>

      <Box sx={price_styles}>
        <Typography variant="h6" sx={{ mr: 2, height: 36.5, lineHeight: 2 }}>
          TOTAL AFTER:{` `}
          {totalPay?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </Typography>
        {!isAccepted && (
          <Button
            variant="contained"
            color="success"
            size="medium"
            onClick={handleSignatureModalOpen}
            disabled={!token}
          >
            Accept
          </Button>
        )}
      </Box>
    </Box>
  </Box>
);

export default ProposalViewHeader;
