import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { EditorProvider, Editor, Toolbar, BtnBold, BtnNumberedList, BtnBulletList } from 'react-simple-wysiwyg';
import moment from 'moment';

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tooltip,
  Modal,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

// Icons
import ConstructionIcon from '@mui/icons-material/Construction';
import HouseIcon from '@mui/icons-material/House';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PercentIcon from '@mui/icons-material/Percent';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddIcon from '@mui/icons-material/Add';
// import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import FeedbackIcon from '@mui/icons-material/Feedback';

import { Spinner } from '../index';

// Utils
import messages from '../../static/messages';

// Services
import proposalsServices from '../../services/proposalsServices';
import suppliersServices from '../../services/suppliersServices';

// Styles
import { button } from '../../static/theme-styles';
import { modal_style } from '../../static/styles';

const NotesBar = ({ data, id, refresh, systems, orders, publicId }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState();
  const [suppliers, setSuppliers] = useState();
  const [additionalNotes, setAdditionNotes] = useState({
    orderEquipmentCompleted: {
      completed: false,
    },
    title24: {
      comment: null,
      completed: false,
    },
    permit: {
      comment: null,
      completed: false,
    },
    rebate: {
      comment: null,
      completed: false,
    },
    payment: {
      comment: null,
      completed: false,
    },
    other: {
      comment: null,
      completed: false,
    },
    commentFromAlex: {
      comment: null,
      completed: false,
    },
  });
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [equipmentOrderStep, setEquipmentOrderStep] = useState(1);
  const [equipmentOrder, setEquipmentOrder] = useState({
    items: [],
    emails: [],
    instruction: '',
    content: '',
    itemsData: [],
  });
  const [emails, setEmails] = useState({
    other: false,
    custom: '',
  });
  const [currentEmailId, setCurrentEmailId] = useState();

  // Handler functions
  const handleOpen = (mode) => {
    setMode(mode);
    setOpen(true);
  };

  const handleClose = () => {
    setMode();
    setOpen(false);
  };

  const handleEquipmentModalOpen = () => {
    const uniq_equipment = [];
    const uniq_equipment_data = [];

    setEquipmentModal(true);
    setMode('orderEquipmentCompleted');

    systems?.forEach((system) => {
      const items = system.equipments;

      items.forEach((item) => {
        item.flexibleItems.forEach((el) => {
          if (!uniq_equipment.includes(el.item.id)) {
            uniq_equipment.push(el.item.id);
            uniq_equipment_data.push({
              ...el.item,
              isChecked: false,
            });
          }
        });
      });
    });

    setEquipmentOrder((prevState) => ({
      ...prevState,
      items: uniq_equipment,
      itemsData: uniq_equipment_data,
    }));
  };

  const handleEquipmentModalClose = () => {
    setEquipmentModal(false);
    setEquipmentOrderStep(1);
    setEquipmentOrder(null);
    setEmails({
      other: false,
      custom: '',
    });
  };

  const handleChange = (event) => {
    const key = event.target.name;
    const val = key === 'completed' ? event.target.checked : event.target.value;

    setAdditionNotes((prevState) => ({
      ...prevState,
      [mode]: {
        ...prevState[mode],
        [key]: val,
      },
    }));
  };

  const handleAddToOrder = (event, id) => {
    const isChecked = event.target.checked;

    if (id) {
      setEquipmentOrder((prevState) => ({
        ...prevState,
        itemsData: [
          ...prevState.itemsData.filter((item) => item.id !== id),
          {
            ...prevState.itemsData.filter((item) => item.id === id)[0],
            isChecked,
          },
        ],
      }));
    } else {
      const key = event.target.name;
      const val = event.target.value;

      setEquipmentOrder((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };

  const handleAddEmail = (event) => {
    const key = event.target.name;
    const val = event.target.value;
    const isChecked = event.target.checked;

    if (key === 'custom') {
      setEmails((prevState) => ({
        ...prevState,
        custom: val,
      }));
    } else {
      setEmails((prevState) => ({
        ...prevState,
        [key]: isChecked,
      }));
    }
  };

  const onChangeTemplate = (event) => setEquipmentOrder((prevState) => ({ ...prevState, content: event.target.value }));

  // Async functions
  const saveComment = async () => {
    try {
      const response = await proposalsServices.update(id, {
        additionalNotes,
      });

      if (response.status === 200) {
        mode === 'orderEquipmentCompleted' ? handleEquipmentModalClose() : handleClose();
        toast.success(`The comment was successfully updated`);
        refresh();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getSuppliers = async () => {
    try {
      const response = await suppliersServices.getSuppliers();

      if (response.status === 200) {
        setSuppliers(response.data.value.data);

        response.data.value.data.forEach((el) => {
          setEmails((prevState) => ({
            ...prevState,
            [el.name.toLowerCase().split(' ').join('_')]: false,
          }));
        });
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const updateEquipmentOrder = async () => {
    const emailsToSend = [];

    Object.entries(emails).forEach((el) => {
      suppliers.forEach((sp) => {
        if (el[0] === sp.name.toLowerCase().split(' ').join('_') && el[1] === true) emailsToSend.push(sp.email);
      });
    });

    if (emails.other) {
      const customEmails = emails.custom.split(',');
      customEmails.forEach((el) => emailsToSend.push(el.trim()));
    }

    const emailTemplate = `
        <p>Dear Supplier,</p>
        <p>Please process this order as follows:</p>
        <ol>
          <li>Job is scheduled for:</li>
          <li>PO: ${publicId}</li>
        </ol>
        <p>Equipment in this order:</p>
        <ul>${equipmentOrder.itemsData.map((item) => {
          if (item.isChecked) return `<li>${item.type} | ${item.make} | ${item.model} | ${item.size}</li>`;
        })}</ul>
        ${equipmentOrder.instruction !== undefined && `<p>${equipmentOrder.instruction}</p>`}
        <p>Thank you, Alex Glozman</p>
      `.replaceAll(',', '');

    const newOrder = [
      ...orders,
      {
        items: equipmentOrder.items,
        emails: emailsToSend,
        ...(equipmentOrder.instruction && { instruction: equipmentOrder.instruction }),
        content: emailTemplate,
      },
    ];

    setEquipmentOrder((prevState) => ({
      ...prevState,
      content: emailTemplate,
    }));

    try {
      const response = await proposalsServices.update(id, {
        equipmentOrders: newOrder,
      });

      const orderID = response?.data?.equipmentOrders
        .map((el) => ({
          ...el,
          createdAt: moment.utc(el.createdAt).format(),
        }))
        .sort(function (a, b) {
          if (a.createdAt > b.createdAt) return -1;
          return 0;
        })[0].id;

      if (response.status === 200) {
        setEquipmentOrderStep(3);
        refresh();
        setCurrentEmailId(orderID);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const sendEmail = async () => {
    try {
      const response = await proposalsServices.sendEquipmentOrder(currentEmailId, equipmentOrder.content);

      if (response.status === 200) {
        setCurrentEmailId();
        handleEquipmentModalClose();
        refresh();
        toast.success('Email Sent!');
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    if (data) setAdditionNotes(data);
  }, [data]);

  useEffect(() => {
    if (equipmentOrderStep === 2) getSuppliers();
  }, [equipmentOrderStep]);

  return (
    <Fragment>
      <Box sx={{ mt: 2, p: 2 }}>
        <ButtonGroup
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            '> button:not(:last-of-type)': {
              mr: 3,
              [theme.breakpoints.down('sm')]: {
                mr: 0,
              },
            },
          })}
        >
          <Tooltip title="Equipment">
            <IconButton onClick={handleEquipmentModalOpen}>
              <ConstructionIcon
                sx={{
                  width: { md: 50, xs: 25 },
                  height: { md: 50, xs: 25 },
                  color: additionalNotes?.orderEquipmentCompleted?.completed
                    ? 'green'
                    : orders.length
                    ? 'red'
                    : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Title 24">
            <IconButton onClick={() => handleOpen('title24')}>
              <HouseIcon
                sx={{
                  width: { md: 50, xs: 25 },
                  height: { md: 50, xs: 25 },
                  color: additionalNotes?.title24.completed
                    ? 'green'
                    : additionalNotes?.title24.comment
                    ? 'red'
                    : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Permit">
            <IconButton onClick={() => handleOpen('permit')}>
              {additionalNotes?.permit.completed ? (
                <VpnKeyIcon
                  sx={{
                    width: { md: 50, xs: 25 },
                    height: { md: 50, xs: 25 },
                    color: 'green',
                  }}
                />
              ) : (
                <KeyOffIcon
                  sx={{
                    width: { md: 50, xs: 25 },
                    height: { md: 50, xs: 25 },
                    color: additionalNotes?.permit.comment ? 'red' : 'rgba(0, 0, 0, 0.54)',
                  }}
                />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title="Rebate">
            <IconButton onClick={() => handleOpen('rebate')}>
              <PercentIcon
                sx={{
                  width: { md: 50, xs: 25 },
                  height: { md: 50, xs: 25 },
                  color: additionalNotes?.rebate.completed
                    ? 'green'
                    : additionalNotes?.rebate.comment
                    ? 'red'
                    : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Payment">
            <IconButton onClick={() => handleOpen('payment')}>
              {additionalNotes?.payment.completed ? (
                <MonetizationOnIcon
                  sx={{
                    width: { md: 50, xs: 25 },
                    height: { md: 50, xs: 25 },
                    color: 'green',
                  }}
                />
              ) : (
                <CurrencyExchangeIcon
                  sx={{
                    width: { md: 50, xs: 25 },
                    height: { md: 50, xs: 25 },
                    color: additionalNotes?.payment.comment ? 'red' : 'rgba(0, 0, 0, 0.54)',
                  }}
                />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title="Other">
            <IconButton onClick={() => handleOpen('other')}>
              <AddIcon
                sx={{
                  width: { md: 50, xs: 25 },
                  height: { md: 50, xs: 25 },
                  color: additionalNotes?.other?.completed
                    ? 'green'
                    : additionalNotes?.other?.comment
                    ? 'red'
                    : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Comment">
            <IconButton onClick={() => handleOpen('commentFromAlex')}>
              <FeedbackIcon
                sx={{
                  width: { md: 50, xs: 25 },
                  height: { md: 50, xs: 25 },
                  color: additionalNotes?.commentFromAlex?.completed
                    ? 'green'
                    : additionalNotes?.commentFromAlex?.comment
                    ? 'red'
                    : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modal_style}>
          <Typography variant="h5">
            <strong>
              {mode === 'commentFromAlex'
                ? 'Comment'
                : mode?.charAt(0).toUpperCase() +
                  mode
                    ?.slice(1)
                    .split(/(?=[A-Z])/)
                    .join(' ')}
            </strong>
          </Typography>

          <FormControlLabel
            onChange={handleChange}
            control={<Checkbox name="completed" checked={additionalNotes[mode]?.completed || false} />}
            label="Completed"
            sx={{ mt: 2 }}
          />

          <TextField
            label="Add a comment"
            multiline
            name="comment"
            rows={4}
            value={additionalNotes[mode]?.comment || ''}
            variant="filled"
            fullWidth
            onChange={handleChange}
            sx={{ mt: 2 }}
          />

          <Box sx={{ mt: 2 }}>
            <Button variant="contained" sx={{ ...button('primary', 'secondary'), mr: 2 }} onClick={saveComment}>
              Save
            </Button>
            <Button variant="contained" onClick={handleClose} sx={button('secondary')}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={equipmentModal} onClose={handleEquipmentModalClose}>
        <Box sx={{ ...modal_style }}>
          <Typography
            variant="h5"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
          >
            {equipmentOrderStep !== 1 && (
              <IconButton onClick={() => setEquipmentOrderStep(equipmentOrderStep - 1)}>
                <ArrowBackIosNewIcon
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                />
              </IconButton>
            )}
            <strong>Order Equipment - Step {equipmentOrderStep}</strong>

            <IconButton onClick={handleEquipmentModalClose}>
              <CloseIcon
                sx={{
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          </Typography>

          {equipmentOrderStep === 1 && (
            <Fragment>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <FormControlLabel
                  onChange={handleChange}
                  control={<Checkbox name="completed" checked={additionalNotes[mode]?.completed || false} />}
                  label="All equipment has been ordered"
                />

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setEquipmentOrderStep(2)}
                  sx={button('secondary')}
                >
                  Order Equipment
                </Button>
              </Box>
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  <strong>Order History:</strong>
                </Typography>

                {!orders.length && <Typography variant="body1">No Data</Typography>}

                {orders?.map((order) => {
                  if (order.sentBy) {
                    return (
                      <Box key={order.id} sx={{ borderBottom: '1px solid #ccc', pb: 1 }}>
                        <Typography variant="body2" gutterBottom>
                          Order send on: {moment(order.createdAt).format('MM/DD/YYY')} at{' '}
                          {moment(order.updatedAt).format('hh:mm a')}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Sent by: {order.sentBy?.fullName}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Send to: {order.emails.map((email) => `${email}${order.emails.length > 1 ? ' | ' : ''}`)}
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: 1, mb: 0.5 }}>
                          <Grid item xs={0.75}>
                            <strong>#</strong>
                          </Grid>
                          <Grid item xs={2.5}>
                            <strong>Type</strong>
                          </Grid>
                          <Grid item xs={2.5}>
                            <strong>Make</strong>
                          </Grid>
                          <Grid item xs={3}>
                            <strong>Model</strong>
                          </Grid>
                          <Grid item xs={2.5}>
                            <strong>Size</strong>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          {order.items.map((item, index) => (
                            <Fragment key={item}>
                              <Grid item xs={0.75}>
                                <Typography variant="body2">{index + 1}</Typography>
                              </Grid>
                              <Grid item xs={2.5}>
                                <Typography variant="body2">
                                  {equipmentOrder?.itemsData?.find((el) => el.id === item)?.type}
                                </Typography>
                              </Grid>
                              <Grid item xs={2.5}>
                                <Typography variant="body2">
                                  {equipmentOrder?.itemsData?.find((el) => el.id === item)?.make}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="body2">
                                  {equipmentOrder?.itemsData?.find((el) => el.id === item)?.model}
                                </Typography>
                              </Grid>
                              <Grid item xs={2.5}>
                                <Typography variant="body2">
                                  {equipmentOrder?.itemsData?.find((el) => el.id === item)?.size}
                                </Typography>
                              </Grid>
                            </Fragment>
                          ))}
                        </Grid>
                      </Box>
                    );
                  }
                })}
              </Box>
            </Fragment>
          )}

          {equipmentOrderStep === 2 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Select the items you want to order:</Typography>

              <Grid container spacing={0.5} sx={{ mt: 2, mb: 2 }}>
                {equipmentOrder.itemsData
                  .sort((a, b) => a.id - b.id)
                  .map((item) => (
                    <Grid item key={item.id} xs={12}>
                      <FormControlLabel
                        onChange={(event) => handleAddToOrder(event, item.id)}
                        control={
                          <Checkbox
                            name={`${item.type} | ${item.make} | ${item.model} | ${item.size}`}
                            checked={item.isChecked || false}
                          />
                        }
                        label={`${item.type} | ${item.make} | ${item.model} | ${item.size}`}
                      />
                    </Grid>
                  ))}
              </Grid>

              <TextField
                name="instruction"
                label="Special Instructions"
                rows={2}
                onChange={handleAddToOrder}
                fullWidth
                multiline
                sx={{ mt: 1 }}
              />

              <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
                <Grid item xs={12}>
                  <Typography variant="body2">Send to:</Typography>
                </Grid>

                {suppliers?.length > 0 ? (
                  suppliers.map((el, index) => {
                    const name = el.name.toLowerCase().split(' ').join('_');

                    return (
                      <Grid item xs={6} key={index}>
                        <FormControlLabel
                          onChange={handleAddEmail}
                          control={<Checkbox name={name} checked={emails[name] || false} />}
                          label={el.name}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Spinner />
                )}

                <Grid item xs={12}>
                  <FormControlLabel
                    onChange={handleAddEmail}
                    control={<Checkbox name="other" />}
                    label="Other"
                    checked={emails.other}
                  />

                  <TextField
                    name="custom"
                    size="small"
                    fullWidth
                    disabled={!emails.other}
                    onChange={handleAddEmail}
                    helperText="add emails separated by commas"
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                size="small"
                onClick={updateEquipmentOrder}
                disabled={
                  !equipmentOrder.itemsData.filter((item) => item.isChecked).length ||
                  !Object.values(emails).some((val) => !!val === true) ||
                  (emails.other && !emails.custom)
                }
              >
                Review Order
              </Button>
            </Box>
          )}

          {equipmentOrderStep === 3 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Email to be send:
              </Typography>

              <EditorProvider>
                <Editor value={equipmentOrder.content} onChange={onChangeTemplate}>
                  <Toolbar>
                    <BtnBold />
                    <BtnBulletList />
                    <BtnNumberedList />
                  </Toolbar>
                </Editor>
              </EditorProvider>

              <Button variant="contained" sx={{ mt: 1 }} onClick={sendEmail}>
                Send Email
              </Button>
            </Box>
          )}

          {equipmentOrderStep === 1 && (
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" sx={{ ...button('primary', 'secondary'), mr: 2 }} onClick={saveComment}>
                Save
              </Button>
              <Button variant="contained" onClick={handleEquipmentModalClose} sx={button('secondary')}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </Fragment>
  );
};

export default NotesBar;
