import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Divider,
  Radio,
  RadioGroup,
} from '@mui/material';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ClearIcon from '@mui/icons-material/Clear';

// Custom components
import { Spinner } from '../../../';

// Utils
import messages from '../../../../static/messages';

// Services
import equipmentServices from '../../../../services/equipmentServices';

// Styles
import { proposal_grouped_items } from '../../../../static/styles';

const EquipmentModule = ({ systemName, data, setSystems, system }) => {
  const [equipmentList, setEquipmentList] = useState([]);
  const [filtersData, setFiltersData] = useState();

  const confirm = useConfirm();

  // Action functions
  const onAddNewItem = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        equipments: [
          ...prevState.filter((system) => system.name === systemName)[0].equipments,
          {
            flexibleItems: [
              {
                price: null,
                item: {
                  [key]: val,
                },
              },
            ],
            selectedItemIndex: 0,
          },
        ],
      },
    ]);
  };

  const onItemReplacement = (item, equipmentId, itemId) => {
    const updatedList = [
      ...equipmentList.filter((el) => el.equipmentId !== equipmentId),
      {
        ...equipmentList.filter((el) => el.equipmentId === equipmentId)[0],
        flexibleItems: [
          ...equipmentList
            .filter((el) => el.equipmentId === equipmentId)[0]
            .flexibleItems.filter((item) => item.itemId !== itemId),
          {
            ...equipmentList
              .filter((el) => el.equipmentId === equipmentId)[0]
              .flexibleItems.filter((item) => item.itemId === itemId)[0],
            item,
          },
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        equipments: updatedList,
      },
    ]);
  };

  const onAddNewItemToGroup = (event, equipmentID) => {
    const key = event.target.name;
    const val = event.target.value;

    const updatedList = [
      ...equipmentList.filter((el) => el.equipmentId !== equipmentID),
      {
        ...equipmentList.filter((el) => el.equipmentId === equipmentID)[0],
        flexibleItems: [
          ...equipmentList.filter((el) => el.equipmentId === equipmentID)[0].flexibleItems,
          {
            item: {
              [key]: val,
            },
            price: null,
            itemId: equipmentList.filter((el) => el.equipmentId === equipmentID)[0].flexibleItems.length,
          },
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((el) => el.name !== systemName),
      {
        ...prevState.filter((el) => el.name === systemName)[0],
        equipments: updatedList,
      },
    ]);
  };

  const onRemoveItem = (item) => {
    const updatedList = [
      ...equipmentList.filter((el) => !el.flexibleItems.includes(item)),
      {
        ...equipmentList.filter((el) => el.flexibleItems.includes(item))[0],
        flexibleItems: [
          ...equipmentList.filter((el) => el.flexibleItems.includes(item))[0].flexibleItems.filter((i) => i !== item),
        ],
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        equipments: updatedList,
      },
    ]);
  };

  // Handler functions
  const handleChange = (event, equipmentId, itemId) => {
    const key = event.target.name;
    const val = event.target.value;

    const updatedList = [
      ...equipmentList.filter((el) => el.equipmentId !== equipmentId),
      {
        ...equipmentList.filter((el) => el.equipmentId === equipmentId)[0],
        ...(key === 'selectedItemIndex' && {
          [key]: Number(val),
          flexibleItems: [...equipmentList.filter((el) => el.equipmentId === equipmentId)[0].flexibleItems].sort(
            function (a, b) {
              if (a.itemId < b.itemId) {
                return -1;
              }
              if (a.itemId > b.itemId) {
                return 1;
              }
              return 0;
            },
          ),
        }),
        ...(key !== 'selectedItemIndex' && {
          flexibleItems: [
            ...equipmentList
              .filter((el) => el.equipmentId === equipmentId)[0]
              .flexibleItems.filter((item) => item.itemId !== itemId),
            {
              ...equipmentList
                .filter((el) => el.equipmentId === equipmentId)[0]
                .flexibleItems.filter((item) => item.itemId === itemId)[0],
              item: {
                ...(key === 'type' && {
                  [key]: val,
                }),

                ...(key === 'make' && {
                  type: equipmentList
                    .filter((el) => el.equipmentId === equipmentId)[0]
                    .flexibleItems.filter((item) => item.itemId === itemId)[0].item.type,
                  [key]: val,
                }),

                ...(key === 'model' && {
                  type: equipmentList
                    .filter((el) => el.equipmentId === equipmentId)[0]
                    .flexibleItems.filter((item) => item.itemId === itemId)[0].item.type,
                  make: equipmentList
                    .filter((el) => el.equipmentId === equipmentId)[0]
                    .flexibleItems.filter((item) => item.itemId === itemId)[0].item.make,
                  [key]: val,
                }),

                ...(key === 'size' && {
                  type: equipmentList
                    .filter((el) => el.equipmentId === equipmentId)[0]
                    .flexibleItems.filter((item) => item.itemId === itemId)[0].item.type,
                  make: equipmentList
                    .filter((el) => el.equipmentId === equipmentId)[0]
                    .flexibleItems.filter((item) => item.itemId === itemId)[0].item.make,
                  model: equipmentList
                    .filter((el) => el.equipmentId === equipmentId)[0]
                    .flexibleItems.filter((item) => item.itemId === itemId)[0].item.model,
                  [key]: val,
                }),
              },
            },
          ].sort(function (a, b) {
            if (a.itemId < b.itemId) {
              return -1;
            }
            if (a.itemId > b.itemId) {
              return 1;
            }
            return 0;
          }),
        }),
      },
    ].sort(function (a, b) {
      if (a.equipmentId < b.equipmentId) {
        return -1;
      }
      if (a.equipmentId > b.equipmentId) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        equipments: updatedList,
      },
    ]);
  };

  const handleGroupItems = (item) => {
    const updatedList = [
      ...equipmentList.filter((el) => el !== item),
      {
        ...item,
        grouped: item.grouped ? false : true,
      },
    ].sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        equipments: updatedList,
      },
    ]);
  };

  const handleDelete = (item) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('item'),
    })
      .then(() => onRemoveItem(item))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleChangePrice = (event, equipmentID, itemID) => {
    const val = event.target.value;
    const key = event.target.name;

    const updatedList = [
      ...equipmentList.filter((el) => el.equipmentId !== equipmentID),
      {
        ...equipmentList.filter((el) => el.equipmentId === equipmentID)[0],
        flexibleItems: [
          ...equipmentList
            .filter((el) => el.equipmentId === equipmentID)[0]
            .flexibleItems.filter((item) => item.itemId !== itemID),
          {
            ...equipmentList
              .filter((el) => el.equipmentId === equipmentID)[0]
              .flexibleItems.filter((item) => item.itemId === itemID)[0],
            [key]: val,
          },
        ].sort((a, b) => {
          if (a.itemId < b.itemId) {
            return -1;
          }
          if (a.itemId > b.itemId) {
            return 1;
          }
          return 0;
        }),
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        equipments: updatedList,
      },
    ]);
  };

  const handleChangeDescription = (event, equipmentID, itemID) => {
    const val = event.target.value;
    const key = event.target.name;

    const updatedList = [
      ...equipmentList.filter((el) => el.equipmentId !== equipmentID),
      {
        ...equipmentList.filter((el) => el.equipmentId === equipmentID)[0],
        flexibleItems: [
          ...equipmentList
            .filter((el) => el.equipmentId === equipmentID)[0]
            .flexibleItems.filter((item) => item.itemId !== itemID),
          {
            ...equipmentList
              .filter((el) => el.equipmentId === equipmentID)[0]
              .flexibleItems.filter((item) => item.itemId === itemID)[0],
            item: {
              ...equipmentList
                .filter((el) => el.equipmentId === equipmentID)[0]
                .flexibleItems.filter((item) => item.itemId === itemID)[0].item,
              [key]: val,
            },
          },
        ].sort((a, b) => {
          if (a.itemId < b.itemId) {
            return -1;
          }
          if (a.itemId > b.itemId) {
            return 1;
          }
          return 0;
        }),
      },
    ];

    setSystems((prevState) => [
      ...prevState.filter((system) => system.name !== systemName),
      {
        ...prevState.filter((system) => system.name === systemName)[0],
        equipments: updatedList,
      },
    ]);
  };

  // Async functions
  const getEquipmentFilters = async () => {
    try {
      const response = await equipmentServices.getEquipmentKit();

      if (response.status === 200) setFiltersData(response.data);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const getItem = async (type, make, model, size, equipmentId, itemId) => {
    try {
      const response = await equipmentServices.find(type, make, model, size);

      if (response.status === 206) {
        const item = response.data.data[0];

        onItemReplacement(item, equipmentId, itemId);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getEquipmentFilters();

    if (data) {
      const listOfEquipment = [];

      data.forEach((el, index) => {
        listOfEquipment.push({
          ...el,
          equipmentId: index,
          grouped: el.flexibleItems.length > 1 || el.grouped ? true : false,
          flexibleItems: [
            ...(() => {
              const items = [];

              el.flexibleItems.forEach((item, idx) => {
                items.push({
                  ...item,
                  itemId: idx,
                });
              });
              return items;
            })(),
          ],
        });
      });

      setEquipmentList(listOfEquipment);
    }
  }, [data]);

  // Drag and drop
  const [currentItem, setCurrentItem] = useState(null);

  const dragStartHandler = (e, item) => setCurrentItem(item);

  const dragEndHandler = (e) => setCurrentItem(null);

  const dragOverHandler = (e) => e.preventDefault();

  const dragLeaveHandler = (e) => (e.target.style.boxShadow = 'none');

  const dropHandler = (e, item) => {
    e.preventDefault();
    e.target.style.boxShadow = 'none';

    const currentIndex = equipmentList.indexOf(currentItem);

    equipmentList.splice(currentIndex, 1);

    const dropIndex = equipmentList.indexOf(item);

    equipmentList.splice(dropIndex, 0, currentItem);

    system.equipments = equipmentList;
  };

  //

  if (!filtersData) return <Spinner />;

  return (
    <Box>
      <Typography variant="body1">
        <strong>Equipment</strong>
      </Typography>

      {equipmentList.map((el) => (
        <Box key={el.equipmentId} sx={{ mt: 2, ...(el.grouped && proposal_grouped_items) }} className="item">
          <RadioGroup value={el.selectedItemIndex}>
            {el.flexibleItems?.map((item, index) => (
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}
                key={item.itemId}
                draggable={true}
                onDragStart={(e) => dragStartHandler(e, el)}
                onDragEnd={(e) => dragEndHandler(e)}
                onDragOver={(e) => dragOverHandler(e)}
                onDragLeave={(e) => dragLeaveHandler(e)}
                onDrop={(e) => dropHandler(e, el)}
                className="item"
              >
                <Grid container columnSpacing={1} rowSpacing={2}>
                  {el.grouped && (
                    <Grid item sx={{ maxWidth: 42, pl: '0 !important' }}>
                      <Radio
                        name="selectedItemIndex"
                        value={index}
                        checked={index === el.selectedItemIndex}
                        onChange={(event) => handleChange(event, el.equipmentId, item.itemId)}
                      />
                    </Grid>
                  )}
                  {/* TYPE */}
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="equipment_type">Type</InputLabel>
                      <Select
                        labelId="equipment_type"
                        name="type"
                        value={item?.item?.type || 'other'}
                        label="Type"
                        onChange={(event) => handleChange(event, el.equipmentId, item.itemId)}
                        size="small"
                      >
                        {Object.keys(filtersData).map((el, index) => (
                          <MenuItem key={index} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* DESCRIPTION FOR OTHER */}
                  {item?.item?.type === 'other' && (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel required htmlFor="equipment_description">
                          Description
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="equipment_description"
                          name="description"
                          value={item?.item.description}
                          label="Description"
                          onChange={(event) => handleChangeDescription(event, el.equipmentId, item.itemId)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {/* MAKE */}
                  {item?.item?.type !== 'other' && (
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth>
                        <InputLabel id="equipment_make">Make</InputLabel>
                        {item.item.type && (
                          <Select
                            labelId="equipment_make"
                            name="make"
                            value={item?.item.make || '1'}
                            label="Make"
                            onChange={(event) => handleChange(event, el.equipmentId, item.itemId)}
                            size="small"
                            // disabled
                          >
                            <MenuItem value="1" sx={{ display: 'none' }}>
                              Choose Make
                            </MenuItem>
                            {Object.keys(filtersData[item.item.type])?.map((el, index) => (
                              <MenuItem key={index} value={el}>
                                {el}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* MODEL */}
                  {item?.item?.type !== 'other' && (
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth>
                        <InputLabel id="equipment_model">Model</InputLabel>
                        <Select
                          labelId="equipment_model"
                          name="model"
                          value={item.item.model || '1'}
                          label="Model"
                          onChange={(event) => handleChange(event, el.equipmentId, item?.itemId)}
                          size="small"
                          disabled={!item?.item.make}
                        >
                          <MenuItem value="1" sx={{ display: 'none' }}>
                            Choose Model
                          </MenuItem>
                          {item.item.make &&
                            Object.keys(filtersData[item.item.type][item.item.make]).map((el, index) => (
                              <MenuItem key={index} value={el}>
                                {el}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {/* SIZE */}
                  {item?.item?.type !== 'other' && (
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth>
                        <InputLabel id="equipment_size">Size</InputLabel>
                        <Select
                          labelId="equipment_size"
                          name="size"
                          value={item?.item.size || '1'}
                          label="Size"
                          onChange={(event) =>
                            getItem(
                              item.item.type,
                              item.item.make,
                              item.item.model,
                              event.target.value,
                              el.equipmentId,
                              item.itemId,
                            )
                          }
                          size="small"
                          disabled={!item.item.model}
                        >
                          <MenuItem value="1" sx={{ display: 'none' }}>
                            Choose Size
                          </MenuItem>
                          {item?.item.model &&
                            Object.keys(filtersData[item.item.type][item.item.make][item.item.model])?.map(
                              (el, index) => (
                                <MenuItem key={index} value={el}>
                                  {el}
                                </MenuItem>
                              ),
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {/* PRICE */}
                  {item?.item?.type !== 'other' && (
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel required htmlFor="equipment_price">
                          Price
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="equipment_price"
                          name="price"
                          value={
                            item?.item.size
                              ? item.price !== null
                                ? item.price
                                : filtersData[item.item.type][item.item.make][item?.item.model][item.item.size].price ||
                                  0
                              : ''
                          }
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          label="Price"
                          disabled={!item.item.size}
                          onChange={(event) => handleChangePrice(event, el.equipmentId, item.itemId)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {/* PRICE FOR OTHER */}
                  {item?.item?.type === 'other' && (
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel required htmlFor="equipment_price">
                          Price
                        </InputLabel>
                        <OutlinedInput
                          required
                          id="equipment_price"
                          name="price"
                          value={item.price || ''}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          label="Price"
                          onChange={(event) => handleChangePrice(event, el.equipmentId, item.itemId)}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {/* BUTTONS */}
                  <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    {el.flexibleItems.length < 2 && (
                      <Tooltip title={el.grouped ? 'Ungroup' : 'Create a group'}>
                        <IconButton aria-label="group" onClick={() => handleGroupItems(el)}>
                          {el.grouped ? <ClearIcon /> : <FormatListBulletedIcon />}
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete Item">
                      <IconButton aria-label="remove" onClick={() => handleDelete(item)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </RadioGroup>

          {el.grouped && (
            <FormControl sx={{ pb: 2 }}>
              <InputLabel id="equipment">Add New</InputLabel>
              <Select
                labelId="equipment"
                name="type"
                value="1"
                label="Add New"
                onChange={(event) => onAddNewItemToGroup(event, el.equipmentId)}
                size="small"
              >
                <MenuItem value="1">Select an item from the list to add</MenuItem>
                {Object.keys(filtersData).map((el, index) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      ))}

      <Divider sx={{ margin: '0 auto 25px' }} />

      <FormControl>
        <InputLabel id="warranties">Add New</InputLabel>
        <Select labelId="warranties" name="type" value="1" label="Add New" onChange={onAddNewItem} size="small">
          <MenuItem value="1">Select an item from the list to add</MenuItem>
          {Object.keys(filtersData).map((el, index) => (
            <MenuItem key={index} value={el}>
              {el}
            </MenuItem>
          ))}

          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default EquipmentModule;
