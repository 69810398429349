import { useState, useEffect } from 'react';

import { Modal, Box, Typography, TextField, Button } from '@mui/material';

import messages from '../../../../static/messages';

const modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#fff',
  boxShadow: 24,
  p: 4,
};

const SystemModal = ({ open, currentSystem, handleClose, onAddNewSystem, isNewSystem, handleSaveSystem }) => {
  const [systemData, setSystemData] = useState();
  const [isValid, setValid] = useState();

  // Handler functions
  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setSystemData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleValidate = () => {
    if (systemData.name === '') setValid(false);
    else setValid(true);
  };

  // Hooks
  useEffect(() => {
    if (isValid) {
      isNewSystem && onAddNewSystem(systemData);
      !isNewSystem && handleSaveSystem(systemData);
      setValid();
    }
  }, [isValid]);

  useEffect(() => {
    setSystemData(currentSystem);
  }, [currentSystem]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modal_style}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Add New System
        </Typography>

        <TextField
          required
          label="System Title"
          name="name"
          value={systemData?.name}
          fullWidth
          onChange={handleChange}
          error={systemData?.name === ''}
          helperText={systemData?.name === '' && messages.errors.required_field}
        />

        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="success" onClick={handleValidate} size="small" sx={{ mr: 2 }}>
            {isNewSystem ? 'Add' : 'Save'}
          </Button>

          <Button variant="contained" color="error" onClick={handleClose} size="small">
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SystemModal;
