import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import { Tabs, Tab, Box, Typography } from '@mui/material';

// Custom Components
import { Spinner, BrowserTitle, TabPanel, Account, Security, Preference } from '../components';

// Utils
import messages from '../static/messages';
// import { AuthContext } from '../context/AuthProvider';

// Services
import userServices from '../services/userServices';
import profileServices from '../services/profileServices';

// External variables
function addTabIndex(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabPanel-${index}`,
  };
}

export const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  // const { userData } = useContext(AuthContext);

  useEffect(() => {
    getUserData();
  }, []);

  const tabSwitcher = (event, val) => {
    event.preventDefault();
    setActiveTab(val);
  };

  // Async functions
  const getUserData = async () => {
    try {
      const response = await userServices.getUserInfo();

      if (response.status === 200) {
        setUserInfo({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          phoneNumber: response.data.phoneNumber,
          photo: response.data.photo,
          preferences: response.data.preferences,
          email: response.data.email,
        });
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const updateProfile = async (data, callback) => {
    try {
      const response = await profileServices.update(data);

      if (response.status === 200) {
        toast.success(messages.success.update.account);
        callback && callback();
        getUserData();
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  if (!userInfo) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title={`${userInfo.firstName} Profile | AirMaxx Pro`} />
      <Typography variant="h2" sx={{ mb: 5 }}>
        Hello, {userInfo.firstName}!
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={tabSwitcher} aria-label="profile tabs">
          <Tab
            label="Account"
            {...addTabIndex(0)}
            sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
          />
          <Tab
            label="Security"
            {...addTabIndex(1)}
            sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
          />
          <Tab
            label="Preference"
            {...addTabIndex(2)}
            sx={{ color: '#0D3B59 !important', fontFamily: 'Lato, sans-serif' }}
          />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        <Account
          userInfo={userInfo}
          getUserData={getUserData}
          setUserInfo={setUserInfo}
          updateProfile={updateProfile}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Security updateProfile={updateProfile} />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <Preference updateProfile={updateProfile} userInfo={userInfo} />
      </TabPanel>
    </Box>
  );
};
