import { useContext } from 'react';
import { toast } from 'react-toastify';
// import moment from 'moment';

import { Box, Button, TextField } from '@mui/material';

// Custom Components
import { Spinner, BrowserTitle } from '../components';

// Utils
import messages from '../static/messages';
import { AuthContext } from '../context/AuthProvider';

// Services
import supportServices from '../services/supportServices';

// Hooks

// Styles
import { button } from '../static/theme-styles';

const Support = () => {
  const { userData } = useContext(AuthContext);

  const submit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const response = await supportServices.sendEmail({
        subject: formData.get('subject'),
        message: formData.get('message'),
      });

      if (response.status === 204) {
        event.target.reset();
        toast.success('Request sent successfully');
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Check for data loading
  if (!userData) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title="Technical Support | AirMaxx Pro" />

      <Box component="form" onSubmit={submit} sx={{ maxWidth: 500 }}>
        <TextField required fullWidth name="subject" label="Subject" type="text" size="small" />

        <TextField
          margin="normal"
          required
          fullWidth
          name="message"
          label="Message"
          type="text"
          multiline
          rows={4}
          size="small"
        />

        <Button type="submit" variant="contained" sx={button('primary', 'secondary')}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Support;
