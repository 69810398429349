import { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Button,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

// Custom Components
import { Spinner, SearchBox, PropertyDrawer, BrowserTitle } from '../components';

// Utils
import messages from '../static/messages';
import { nullClearObject } from '../utils/cleaners';
import { AuthContext } from '../context/AuthProvider';

// Services
import propertiesServices from '../services/propertiesServices';

// Hooks
import useSearch from '../hooks/useSearch';
import usePagination from '../hooks/usePagination';
import useDrawer from '../hooks/useDrawer';

// Styles
import {
  table_header_with_search,
  table_container_base,
  check_for_deleted_row,
  actions_column,
} from '../static/styles';

import { button } from '../static/theme-styles';

// External variables
const base_property = {
  typeName: '',
  address: '',
};

export const Properties = () => {
  const { userData } = useContext(AuthContext);

  const [propertiesList, setPropertiesList] = useState();
  const [totalCount, setTotalCount] = useState();
  const [showDeleted, setShowDeleted] = useState(userData.preferences.showDeletedRows);
  const [showInvalid, setShowInvalid] = useState(false);
  const [currentProperty, setCurrentProperty] = useState();
  const [isNewProperty, setPropertyMode] = useState(false);

  const { searchQuery, setSearchQuery, isSearching, searchRequest } = useSearch(propertiesServices.searchRequest);
  const { rowsPerPage, setRowsPerPage, page, setPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { drawer, openDrawer, foolCheck } = useDrawer(currentProperty);

  const confirm = useConfirm();
  const navigate = useNavigate();
  const location = useLocation();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setCurrentProperty(null);
      setPropertyMode(false);
      resetErrors && resetErrors();
    }
  };

  const onEditProperty = (property) => {
    delete property.createdAt;
    delete property.deletedAt;
    delete property.updatedAt;
    const cleanObject = nullClearObject(property);
    setCurrentProperty(cleanObject);
    openDrawer();
  };

  const onAddProperty = () => {
    setPropertyMode(true);
    setCurrentProperty(base_property);
    openDrawer();
  };

  // Handler functions
  const handleRemove = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Property'),
    })
      .then(() => removeProperty(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleRestore = (id) => {
    confirm({
      title: messages.confirm_message.restore,
      description: messages.confirm_message.restore_description('Property'),
    })
      .then(() => restoreProperty(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const getPropertiesList = async (skip, take, widthDeleted = true) => {
    try {
      const response = await propertiesServices.getAllProperties(skip, take, widthDeleted);
      setTotalCount(response.data.total);
      setPropertiesList(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getInvalidProperties = async (skip, take) => {
    try {
      const response = await propertiesServices.getInvalidProperties(skip, take);
      setTotalCount(response.data.total);
      setPropertiesList(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getSearchResult = useCallback(
    async (skip, take) => {
      const result = await searchRequest(skip, take);
      setTotalCount(result.total);
      setPropertiesList(result.data);
      // setPage(0);
    },
    [searchRequest],
  );

  const removeProperty = async (id) => {
    try {
      const response = await propertiesServices.remove(id);

      if (response.status === 200) {
        toast.success(messages.property.remove);
        getPropertiesList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const restoreProperty = async (id) => {
    try {
      const response = await propertiesServices.restore(id);

      if (response.status === 200) {
        toast.success(messages.property.restore);
        getPropertiesList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const createProperty = async () => {
    delete currentProperty.isEdited;

    const data = {
      address: currentProperty.address,
      typeName: currentProperty.typeName.name,
      ...(currentProperty.unit && { unit: currentProperty.unit }),
    };

    try {
      const response = await propertiesServices.create(data);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.property.create);
        navigate(`/properties/${response.data.id}`);
      }
    } catch (error) {
      toast.error(error.response.status === 400 ? error.response.data.message : messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateProperty = async () => {
    delete currentProperty.isEdited;

    try {
      const response = await propertiesServices.update(currentProperty.id, {
        typeName: currentProperty.typeName.name,
        address: currentProperty.address,
        unit: currentProperty.unit || '',
        isCorrect: currentProperty.isCorrect, // TODO: Remove after fix addresses
      });

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.property.update);
        // getPropertiesList(page * rowsPerPage, rowsPerPage, showDeleted);

        if (showInvalid) {
          getInvalidProperties(page * rowsPerPage, rowsPerPage);
        } else {
          getPropertiesList(page * rowsPerPage, rowsPerPage, showDeleted);
        }
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    // searchQuery === ''
    //   ? getPropertiesList(page * rowsPerPage, rowsPerPage, showDeleted)
    //   : getSearchResult(page * rowsPerPage, rowsPerPage, showDeleted);

    searchQuery === ''
      ? showInvalid
        ? getInvalidProperties(page * rowsPerPage, rowsPerPage)
        : getPropertiesList(page * rowsPerPage, rowsPerPage, showDeleted)
      : getSearchResult(page * rowsPerPage, rowsPerPage, showDeleted);
  }, [searchQuery, getSearchResult, page, rowsPerPage, showDeleted]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setShowDeleted(userData.isDeletedRowsVisible);

    if (location.search) {
      const params = new URLSearchParams(location.search);
      const page = params.get('page');
      const rowsPerPage = params.get('per_page');

      setPage(Number(page));
      setRowsPerPage(Number(rowsPerPage));
    }
  }, []);

  useEffect(() => {
    setPage(0);
  }, [totalCount]);

  useEffect(() => {
    if (showInvalid) getInvalidProperties(page * rowsPerPage, rowsPerPage);
    else getPropertiesList(page * rowsPerPage, rowsPerPage, showDeleted);
  }, [showInvalid]);

  // Check for data loading
  if (!propertiesList) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title="Properties | AirMaxx Pro" />
      <Box sx={table_header_with_search}>
        <Button variant="contained" endIcon={<AddIcon />} onClick={onAddProperty} sx={button('primary', 'secondary')}>
          Add new
        </Button>

        <Box>
          <FormControlLabel
            value="end"
            control={<Switch color="primary" checked={showInvalid} onChange={() => setShowInvalid(!showInvalid)} />}
            label="Show Invalid"
            labelPlacement="end"
            disabled={searchQuery !== ''}
          />

          {!showInvalid && (
            <FormControlLabel
              value="end"
              control={<Switch color="primary" checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />}
              label="Show Deleted"
              labelPlacement="end"
              disabled={searchQuery !== ''}
            />
          )}
        </Box>
      </Box>

      <Paper>
        <SearchBox searchRequest={searchRequest} value={searchQuery} setValue={setSearchQuery} />
        <TableContainer sx={table_container_base}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="15%">Type</TableCell>
                <TableCell width="80%">Address</TableCell>
                <TableCell width="5%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSearching ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Spinner />
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment>
                  {propertiesList.length ? (
                    propertiesList.map((property) => (
                      <TableRow key={property.id} sx={check_for_deleted_row(!!property.deletedAt)} hover>
                        <TableCell scope="row">
                          <NavLink
                            className="full-col-flex-link"
                            to={`/properties/${property.id}`}
                            onClick={!!property.deletedAt ? (e) => e.preventDefault() : null}
                            state={{
                              page: page,
                              rowsPerPage: rowsPerPage,
                            }}
                          >
                            {property.type?.name || 'Not specified'}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/properties/${property.id}`}
                            onClick={!!property.deletedAt ? (e) => e.preventDefault() : null}
                            state={{
                              page: page,
                              rowsPerPage: rowsPerPage,
                            }}
                          >
                            {property.address}
                            {property?.source
                              ? !property?.address?.includes(property?.source?.zipcode) ||
                                (property.source?.address.includes(' -') && property?.unit === '')
                                ? '🚩 ' +
                                  JSON.stringify({
                                    city: property.source?.city,
                                    zipcode: property.source?.zipcode,
                                    address: property.source?.address,
                                  })
                                : ''
                              : ''}
                          </NavLink>
                        </TableCell>
                        <TableCell sx={actions_column}>
                          {!!property.deletedAt ? (
                            <Tooltip title="Restore Property">
                              <IconButton aria-label="restore" onClick={() => handleRestore(property.id)}>
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Fragment>
                              <Tooltip title="Edit Property">
                                <IconButton aria-label="edit" onClick={() => onEditProperty(property)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Property">
                                <IconButton aria-label="remove" onClick={() => handleRemove(property.id)}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                        <strong>No Data</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Properties per page"
      />

      <PropertyDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        currentProperty={currentProperty}
        setCurrentProperty={setCurrentProperty}
        isNewProperty={isNewProperty}
        createProperty={createProperty}
        updateProperty={updateProperty}
        // TODO: Remove after fix
        showInvalid={showInvalid}
      />
    </Box>
  );
};
