import { createTheme } from '@mui/material/styles';

export const light_theme = createTheme({
  palette: {
    mode: 'light',
    proposal_view_bg: '#f2f2f4',
    link: '#1976d2',
  },
});

export const dark_theme = createTheme({
  palette: {
    mode: 'dark',
    proposal_view_bg: '#f2f2f4',
    link: '#1976d2',
  },
});
