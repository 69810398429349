import { useState, useEffect, Fragment } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import { Autocomplete, Box, Drawer, Grid, TextField, Button, Divider, Typography, Modal } from '@mui/material';

// Services
import clientsServices from '../../services/clientsServices';
import propertiesServices from '../../services/propertiesServices';

// Utils
import { internationalPhoneFormat, validateEmail } from '../../utils/formatter';
import messages from '../../static/messages';

// Hooks
import { useClientsAutocomplete } from '../../hooks/useAutocomplete';

// Styles
import { button } from '../../static/theme-styles';
import { modal_style } from '../../static/styles';

// External variables
const required_fields = ['firstName', 'lastName', 'email', 'phoneNumber'];

const PropertyClientsDrawer = (props) => {
  const { currentClient, setCurrentClient, addNewClient, updateClient } = props;
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [existingClient, setExistingClient] = useState();

  const { handleSearchClient, clientOptions } = useClientsAutocomplete();

  // Action functions
  const resetErrors = () => {
    setErrors({});
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const resetQueries = () => {
    handleSearchClient('');
  };

  const onExistingClientSelected = (e, val) => {
    e.preventDefault();
    if (val) {
      setCurrentClient((prevState) => ({
        ...prevState,
        client: {
          id: val.id,
        },
        isEdited: true,
      }));
    } else {
      // setCurrentClient(null);
    }

    resetQueries();
  };

  const addExistingClient = () => {
    props.onAddNewClientFromExisting(
      {
        email: existingClient.email,
        firstName: existingClient.firstName,
        lastName: existingClient.lastName,
        id: existingClient.id,
        companyName: existingClient.companyName,
        phoneNumber: existingClient.phoneNumber,
      },
      () => setOpen(false),
    );
  };

  // Handler functions
  const handleClose = () => props.onCloseDrawer(resetErrors);

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    switch (key) {
      case 'relation':
        setCurrentClient((prevState) => ({
          ...prevState,
          [key]: val,
          isEdited: true,
        }));
        break;
      case 'phoneNumber':
      case 'fax':
      case 'mobile':
        setCurrentClient((prevState) => ({
          ...prevState,
          client: {
            ...prevState.client,
            [key]: val === '1' ? '' : internationalPhoneFormat(val),
          },
          isEdited: true,
        }));
        break;
      default:
        setCurrentClient((prevState) => ({
          ...prevState,
          client: {
            ...prevState.client,
            [key]: val,
          },
          isEdited: true,
        }));
        break;
    }
  };

  const handleValidate = () => {
    resetErrors();

    if (props.drawerMode === 'new') {
      required_fields.forEach((field) => {
        const el = currentClient.client[field];
        if (el === '' || el === null) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: true,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [field]: false,
          }));
        }
      });

      if (!validateEmail(currentClient.client.email)) {
        toast.error(messages.errors.invalid_format('Email'));
        setErrors((prevState) => ({
          ...prevState,
          email: true,
        }));
      }

      if (currentClient.client.phoneNumber.length !== 12) {
        toast.error(messages.errors.invalid_format('Phone'));
        setErrors((prevState) => ({
          ...prevState,
          phoneNumber: true,
        }));
      }

      if (currentClient.client.mobile && currentClient.client.mobile.length !== 12) {
        toast.error(messages.errors.invalid_format('Mobile'));
        setErrors((prevState) => ({
          ...prevState,
          mobile: true,
        }));
      }

      if (currentClient.client.fax && currentClient.client.fax.length !== 12) {
        toast.error(messages.errors.invalid_format('Fax'));
        setErrors((prevState) => ({
          ...prevState,
          fax: true,
        }));
      }
    } else {
      const el = currentClient.client.id;
      if (!el) {
        setErrors((prevState) => ({
          ...prevState,
          id: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          id: false,
        }));
      }
    }
  };

  // Async functions
  const searchClient = async () => {
    const rqst = String(currentClient.client.phoneNumber).substring(2);

    try {
      const response = await clientsServices.searchRequest(0, 1000000, rqst);

      if (response.status === 206) {
        if (response.data.data.length) {
          setOpen(true);
          setExistingClient(response.data.data[0]);
        }
      }
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    if (currentClient?.isEdited) {
      !Object.values(errors).includes(true) && (props.drawerMode === 'new' ? addNewClient() : updateClient());
    }
  }, [errors]);

  useEffect(() => {
    if (currentClient?.client?.phoneNumber?.length > 11 && props.drawerMode === 'new') searchClient();
  }, [currentClient?.client?.phoneNumber]);

  return (
    <Fragment>
      <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  {props.drawerMode === 'new' && 'Add new Client'}

                  {props.drawerMode === 'edit' && 'Edit Client'}

                  {props.drawerMode === 'find' && 'Find an existing Client'}
                </Typography>
              </Box>
            </Grid>

            {(props.drawerMode === 'new' || props.drawerMode === 'edit') && (
              <Fragment>
                <Grid item xs={12}>
                  <TextField
                    name="companyName"
                    label="Company"
                    defaultValue={currentClient?.client.companyName}
                    onChange={handleChange}
                    disabled={props.drawerMode === 'edit'}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    name="firstName"
                    label="Fist Name"
                    defaultValue={currentClient?.client.firstName}
                    onChange={handleChange}
                    fullWidth
                    disabled={props.drawerMode === 'edit'}
                    error={errors.firstName}
                    helperText={errors.firstName && messages.errors.required_field}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    name="lastName"
                    label="Last Name"
                    defaultValue={currentClient?.client.lastName}
                    onChange={handleChange}
                    fullWidth
                    disabled={props.drawerMode === 'edit'}
                    error={errors.lastName}
                    helperText={errors.lastName && messages.errors.required_field}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    name="email"
                    label="Email"
                    defaultValue={currentClient?.client.email}
                    onChange={handleChange}
                    fullWidth
                    disabled={props.drawerMode === 'edit'}
                    error={errors.email}
                    helperText={errors.email && messages.errors.required_field}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputMask
                    mask="+1 (999) 999-9999"
                    value={currentClient?.client.phoneNumber}
                    disabled={props.drawerMode === 'edit'}
                    maskChar=" "
                    onChange={handleChange}
                  >
                    {() => (
                      <TextField
                        required
                        name="phoneNumber"
                        label="Phone"
                        fullWidth
                        disabled={props.drawerMode === 'edit'}
                        error={errors.phoneNumber}
                        helperText={errors.phoneNumber && messages.errors.required_field}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={6}>
                  <InputMask
                    mask="+1 (999) 999-9999"
                    value={currentClient?.client.mobile || ''}
                    disabled={props.drawerMode === 'edit'}
                    maskChar=" "
                    onChange={handleChange}
                  >
                    {() => (
                      <TextField
                        name="mobile"
                        label="Mobile"
                        fullWidth
                        disabled={props.drawerMode === 'edit'}
                        error={errors.mobile}
                        helperText={errors.mobile && messages.errors.invalid_format_helper}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={6}>
                  <InputMask
                    mask="+1 (999) 999-9999"
                    value={currentClient?.client.fax || ''}
                    disabled={props.drawerMode === 'edit'}
                    maskChar=" "
                    onChange={handleChange}
                  >
                    {() => (
                      <TextField
                        name="fax"
                        label="Fax"
                        fullWidth
                        disabled={props.drawerMode === 'edit'}
                        error={errors.fax}
                        helperText={errors.fax && messages.errors.invalid_format_helper}
                      />
                    )}
                  </InputMask>
                </Grid>
              </Fragment>
            )}

            {props.drawerMode === 'find' && (
              <Grid item xs={12}>
                <Autocomplete
                  options={clientOptions}
                  getOptionLabel={(option) => option.fullName}
                  filterOptions={(x) => x}
                  onChange={onExistingClientSelected}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Client"
                      onChange={(e) => handleSearchClient(e.target.value)}
                      error={errors.id}
                      helperText={errors.id && messages.errors.required_field}
                    />
                  )}
                />
              </Grid>
            )}

            {props.drawerMode !== 'find' && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                name="relation"
                label="Relation to Property"
                defaultValue={currentClient?.relation || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                onClick={handleValidate}
                disabled={!currentClient?.isEdited}
              >
                {props.drawerMode === 'new' ? 'Add New Client' : 'Save'}
              </Button>

              <Button variant="contained" onClick={handleClose} sx={button('secondary')}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>

      {existingClient && (
        <Modal open={open}>
          <Box sx={modal_style}>
            <Typography sx={{ mb: 2 }}>
              There is a client with the specified number in the system, add it to the appointment?
            </Typography>

            <Button
              variant="contained"
              sx={{ ...button('primary', 'secondary'), mr: 3 }}
              // onClick={handleValidate}
              onClick={addExistingClient}
              // disabled={!currentClient?.isEdited}
            >
              Add
            </Button>

            <Button variant="contained" onClick={() => setOpen(false)} sx={button('secondary')}>
              Cancel
            </Button>
          </Box>
        </Modal>
      )}
    </Fragment>
  );
};

export default PropertyClientsDrawer;
