import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Autocomplete,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

// Utils
import messages from '../../static/messages';

// Services
import propertiesServices from '../../services/propertiesServices';

// Hooks
import { usePropertiesAutocomplete, useLocationAutocomplete } from '../../hooks/useAutocomplete';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['address', 'typeName'];

const ClientPropertiesDrawer = (props) => {
  const { currentProperty, setCurrentProperty, addNewProperty, updateProperty } = props;
  const [errors, setErrors] = useState({});
  const [propertyTypes, setPropertyTypes] = useState();

  const { handleSearchLocation, locationOptions } = useLocationAutocomplete();
  const { handleSearchProperty, propertyOptions } = usePropertiesAutocomplete();

  // Action functions
  const resetErrors = () => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const resetQueries = () => {
    handleSearchLocation('');
    handleSearchProperty('');
  };

  const onAddressSelected = (event, val) => {
    event.preventDefault();
    setCurrentProperty((prevState) => ({
      ...prevState,
      property: {
        ...prevState.property,
        address: val,
      },
      isEdited: true,
    }));
    resetQueries();
  };

  const onPropertySelected = (event, val) => {
    event.preventDefault();
    if (val) {
      setCurrentProperty((prevState) => ({
        ...prevState,
        property: {
          id: val.id,
        },
        isEdited: true,
      }));
    }
    resetQueries();
  };

  // Handler functions
  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setCurrentProperty((prevState) => ({
      ...prevState,
      [key]: val,
      isEdited: true,
    }));
  };

  const handleValidate = () => {
    required_fields.forEach((field) => {
      const el = currentProperty.property[field];
      if (el === '' || el === null) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
  };

  const handleClose = () => props.onCloseDrawer(resetErrors);

  // Async functions
  const getPropertiesTypes = async () => {
    try {
      const response = await propertiesServices.getPropertyTypes();

      setPropertyTypes(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    resetErrors();
    getPropertiesTypes();
  }, []);

  useEffect(() => {
    if (currentProperty?.isEdited) {
      !Object.values(errors).includes(true) && (props.drawerMode === 'new' ? addNewProperty() : updateProperty());
    }
  }, [errors]);

  if (!propertyTypes) return false;

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h4" gutterBottom>
                {props.drawerMode === 'new' && 'Add new Property'}

                {props.drawerMode === 'edit' && 'Edit Property'}

                {props.drawerMode === 'find' && 'Find an existing Property'}
              </Typography>
            </Box>
          </Grid>

          {props.drawerMode !== 'find' && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="typeName" error={errors.typeName}>
                  Property Type *
                </InputLabel>
                <Select
                  labelId="typeName"
                  name="typeName"
                  value={currentProperty?.property?.typeName}
                  label="Property Type"
                  required
                  disabled={props.drawerMode === 'edit'}
                  onChange={(event) =>
                    setCurrentProperty((prevState) => ({
                      ...prevState,
                      property: {
                        ...prevState.property,
                        typeName: event.target.value,
                        isEdited: true,
                      },
                    }))
                  }
                  error={errors.typeName}
                >
                  {propertyTypes?.map((type) => (
                    <MenuItem key={type.id} value={type.name}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.typeName && (
                  <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

          {props.drawerMode !== 'find' && (
            <Grid item xs={12}>
              <Autocomplete
                value={currentProperty?.property?.address || ''}
                options={locationOptions}
                getOptionLabel={(option) => option}
                filterOptions={(x) => x}
                onChange={onAddressSelected}
                isOptionEqualToValue={(option, value) => option}
                freeSolo
                disabled={props.drawerMode === 'edit'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Address"
                    onChange={(e) => handleSearchLocation(e.target.value)}
                    error={errors.address}
                    helperText={errors.address && messages.errors.required_field}
                  />
                )}
              />
            </Grid>
          )}

          {props.drawerMode === 'find' && (
            <Grid item xs={12}>
              <Autocomplete
                options={propertyOptions}
                getOptionLabel={(option) => option?.address}
                filterOptions={(x) => x}
                onChange={onPropertySelected}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                freeSolo
                disabled={props.drawerMode === 'edit'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Property"
                    onChange={(e) => handleSearchProperty(e.target.value)}
                    error={errors.address}
                    helperText={errors.address && messages.errors.required_field}
                  />
                )}
              />
            </Grid>
          )}

          {props.drawerMode !== 'find' && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              name="relation"
              label="Relation to Property"
              defaultValue={currentProperty?.relation}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ ...button('primary', 'secondary'), mr: 3 }}
              onClick={handleValidate}
              disabled={!currentProperty?.isEdited}
            >
              {props.drawerMode === 'new' ? 'Add New Property' : 'Save'}
            </Button>

            <Button variant="contained" onClick={handleClose} sx={button('secondary')}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default ClientPropertiesDrawer;
