import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment-timezone';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Typography,
  Autocomplete,
  Divider,
  Select,
  MenuItem,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  Menu,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

// Custom Components
import {
  Spinner,
  BrowserTitle,
  AppointmentAppointmentsTable,
  AppointmentProposalsTable,
  AppointmentClientsTable,
  PropertyClientsTable,
  PropertyClientsDrawer,
  PropertyMediaTable,
} from '../components';

// Utils
import messages from '../static/messages';
import { emptyStringClear } from '../utils/cleaners';

// Services
import appointmentsServices from '../services/appointmentsServices';
import propertiesServices from '../services/propertiesServices';
import userServices from '../services/userServices';
import clientsServices from '../services/clientsServices';

// Hooks
import useAccordion from '../hooks/useAccordion';
import useDrawer from '../hooks/useDrawer';
import { useLocationAutocomplete, useSmartLocationAutocomplete } from '../hooks/useAutocomplete';

// Styles
import { button } from '../static/theme-styles';

// External variables
import { appointment_times } from '../components/Drawers/appointment.drawer';
import { base_client } from '../components/Tables/Property/property.clients';

const required_fields = ['address', 'type', 'clients'];

const blocks = ['details', 'appointments', 'proposals', 'clients', 'media'];

const menuPaperProps = {
  elevation: 0,
  sx: {
    left: '105px !important',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiSvgIcon-root': {
      width: 25,
      height: 25,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const addressFormat = (adr) => {
  let label = '';

  if (adr) {
    if (adr?.unit) label = `${adr?.address} (unit ${adr?.unit})`;
    else label = adr?.address;
  }

  return label;
};

const AppointmentCreate = () => {
  const [errors, setErrors] = useState({});
  const [appointment, setAppointment] = useState({
    address: '',
    clients: [],
  });
  const [techniciansList, setTechniciansList] = useState();
  const [types, setTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [currentClient, setCurrentClient] = useState();
  const [drawerMode, setDrawerMode] = useState('find');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMore = Boolean(anchorEl);

  const { handleSearchLocation, locationOptions } = useSmartLocationAutocomplete(2);
  const { open, accordionToggle } = useAccordion(blocks);
  const { drawer, openDrawer, foolCheck } = useDrawer(currentClient);
  moment.tz.setDefault('America/Los_Angeles');
  const navigate = useNavigate();
  const confirm = useConfirm();

  // Action functions
  const resetErrors = () => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const clearForm = () =>
    setAppointment({
      address: '',
      clients: [],
    });

  const resetQueries = () => handleSearchLocation('');

  const onAddressSelected = (e, val) => {
    e.preventDefault();

    if (val.id) {
      setAppointment((prevState) => ({
        ...prevState,
        address: val.address,
        address_id: val.id,
        isEdited: true,
      }));
    } else {
      setAppointment((prevState) => ({
        ...prevState,
        address: val.address,
        isEdited: true,
      }));
    }

    resetQueries();
  };

  const onAddNewClient = () => {
    delete currentClient.isEdited;

    if (currentClient.client.id) getClientInfo(currentClient.client.id, currentClient.relation);
    else
      setAppointment((prevState) => ({
        ...prevState,
        clients: [
          ...prevState.clients,
          {
            ...currentClient,
            client: {
              ...emptyStringClear(currentClient.client),
              fullName: `${currentClient.client.firstName} ${currentClient.client.lastName}`,
            },
          },
        ],
      }));

    onCloseDrawer(resetErrors);
  };

  const onAddNewClientFromExisting = (newClient, closeModal) => {
    delete currentClient.isEdited;

    setAppointment((prevState) => ({
      ...prevState,
      clients: [
        ...prevState.clients,
        {
          relation: '',
          client: {
            ...emptyStringClear(newClient),
            fullName: `${newClient.firstName} ${newClient.lastName}`,
          },
        },
      ],
    }));

    onCloseDrawer(resetErrors);
    closeModal();
  };

  const onAddClient = () => {
    setCurrentClient(base_client);
    setDrawerMode('new');
    openDrawer();
  };

  const onFindClient = () => {
    setCurrentClient(base_client);
    openDrawer();
  };

  const onSubmit = () => {
    if (appointment?.property) createNewAppointment(appointment?.property?.id);
    else createNewProperty();
  };

  // Handler functions
  const handleChange = (event) => {
    const key = event.target?.name || 'date';
    const val = event.target?.value;

    setAppointment((prevState) => ({
      ...prevState,
      [key]: event.target?.name ? val : moment.utc(event.$d).format('MM/DD/YYYY'),
    }));
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClearForm = () => {
    confirm({
      title: messages.confirm_message.clear,
      description: messages.confirm_message.clear_description,
    })
      .then(() => clearForm())
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleValidate = () => {
    required_fields.forEach((field) => {
      if (appointment[field] === '' || appointment[field] === null || !appointment[field]) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });

    if (appointment?.date) {
      if (!appointment?.startAt) {
        setErrors((prevState) => ({
          ...prevState,
          startAt: true,
        }));
        return false;
      } else {
        setErrors((prevState) => ({
          ...prevState,
          startAt: false,
        }));
      }

      if (!appointment?.finishAt) {
        setErrors((prevState) => ({
          ...prevState,
          finishAt: true,
        }));
        return false;
      } else {
        setErrors((prevState) => ({
          ...prevState,
          finishAt: false,
        }));
      }
    }

    if (!appointment?.clients.length && !appointment?.property?.clients)
      return toast.error('You must add at least one client');

    onSubmit();
  };

  // Async functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setDrawerMode('find');
      setCurrentClient(null);
      resetErrors && resetErrors();
    }
  };

  const searchExistingProperty = async (address) => {
    try {
      const response = await propertiesServices.searchRequest(0, 1000000, address, false);

      if (response.data.data.length > 0)
        setAppointment((prevState) => ({
          ...prevState,
          property: {
            id: response.data.data[0].id,
          },
        }));
    } catch (error) {}
  };

  const searchExistingPropertyById = async (propertyID) => {
    try {
      const response = await propertiesServices.getPropertyById(propertyID);

      if (response.status === 200)
        setAppointment((prevState) => ({
          ...prevState,
          ...(response.data.unit && { unit: response.data.unit }),
          property: {
            id: response.data.id,
          },
        }));
    } catch (error) {}
  };

  const getPropertyData = async (id) => {
    try {
      const response = await propertiesServices.getPropertyById(id);

      setAppointment((prevState) => ({
        ...prevState,
        property: response.data,
      }));
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getPropertyTypes = async () => {
    try {
      const response = await propertiesServices.getPropertyTypes();

      setPropertyTypes(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getTechniciansList = async () => {
    try {
      const response = await userServices.getUserByRole('technician');

      if (response.status === 206)
        setTechniciansList(
          response.data.data.map((tech) => ({
            tech_id: tech.id,
            name: tech.fullName,
            color: tech.preferences?.color || 'red',
          })),
        );
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getTypes = async () => {
    try {
      const response = await appointmentsServices.getAppointmentTypes(false);

      if (response.status === 206) setTypes(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      const response = await appointmentsServices.getAppointmentSources(false);

      if (response.status === 206) setSources(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getClientInfo = async (id, relation) => {
    try {
      const response = await clientsServices.getClientById(id);

      setAppointment((prevState) => ({
        ...prevState,
        clients: [
          ...prevState.clients,
          {
            client: {
              id: response.data.id,
              fullName: response.data.fullName,
              email: response.data.email,
              phoneNumber: response.data.phoneNumber,
            },
            relation,
          },
        ],
      }));
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const createNewAppointment = async (propertyId) => {
    const start = moment(appointment.date).set({
      hour: appointment.startAt?.split(':')[0],
      minute: appointment.startAt?.split(':')[1],
      second: '0',
    });

    const end = moment(appointment.date).set({
      hour: appointment.finishAt?.split(':')[0],
      minute: appointment.finishAt?.split(':')[1],
      second: '0',
    });

    const data = {
      status: 'Active',
      type: {
        id: appointment.type,
      },
      ...(appointment.source && {
        source: {
          id: appointment.source,
        },
      }),
      ...(appointment.technician && {
        user: {
          id: appointment.technician,
        },
      }),
      property: {
        id: propertyId,
      },
      ...(appointment.startAt && {
        startTime: moment.utc(start).format(),
      }),
      ...(appointment.startAt && {
        endTime: moment.utc(end).format(),
      }),
      ...(appointment.notes && {
        notes: [
          {
            note: appointment.notes,
          },
        ],
      }),
    };

    try {
      const response = await appointmentsServices.create(data);

      if (response.status === 201) {
        toast.success(messages.appointment.create);
        navigate(`/appointments`);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const createNewProperty = async () => {
    const property = {
      typeName: appointment.typeName, // TODO: check this field
      address: appointment.address,
      ...(appointment.unit && { unit: appointment.unit }),
      clients: appointment.clients,
    };

    try {
      const response = await propertiesServices.create(property);

      if (response.status === 200) createNewAppointment(response.data.id);
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    accordionToggle('details');
    getTechniciansList();
    getTypes();
    getSources();
    getPropertyTypes();
    resetErrors();
  }, []);

  useEffect(() => {
    if (appointment.address_id) {
      // searchExistingProperty(appointment.address);
      searchExistingPropertyById(appointment.address_id);
    }

    if (appointment.address === null) {
      setAppointment((prevState) => ({
        ...prevState,
        property: null,
        typeName: '',
        unit: '',
        address_id: null,
      }));

      resetQueries();
    }
  }, [appointment.address]);

  useEffect(() => {
    if (appointment.property?.id) {
      getPropertyData(appointment.property?.id);
    }
  }, [appointment.property?.id]);

  if (!techniciansList || !types || !sources) return <Spinner />;

  return (
    <Fragment>
      <Box component="section">
        <BrowserTitle title={`Create Appointment | AirMaxx Pro`} />

        <Button
          variant="text"
          onClick={() => navigate('/appointments')}
          startIcon={<KeyboardBackspaceIcon />}
          sx={{ mb: 2 }}
        >
          Back to Appointments
        </Button>

        {/* Appointment Details */}
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.details}
          onChange={() => accordionToggle('details')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Appointment Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  loading={locationOptions.length ? false : true}
                  options={locationOptions}
                  getOptionLabel={addressFormat}
                  filterOptions={(x) => x}
                  onChange={onAddressSelected}
                  isOptionEqualToValue={(option, value) => option.address}
                  freeSolo
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Address"
                      onChange={(e) => handleSearchLocation(e.target.value)}
                      error={errors.address}
                      helperText={errors.address && messages.errors.required_field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="unit"
                  label="Unit"
                  value={appointment?.property?.unit ? appointment?.property?.unit || '' : appointment.unit || ''}
                  // value={appointment.unit}
                  onChange={handleChange}
                  fullWidth
                  disabled={!!appointment?.property?.id}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="typeName" error={errors.typeName}>
                    Property Type
                  </InputLabel>
                  <Select
                    labelId="typeName"
                    name="typeName"
                    value={
                      appointment?.property?.typeName
                        ? appointment?.property?.typeName || ''
                        : appointment?.typeName || ''
                    }
                    label="Property Type"
                    // required
                    onChange={handleChange}
                    disabled={!!appointment?.property?.id}
                    // error={errors.typeName}
                  >
                    {propertyTypes?.map((type) => (
                      <MenuItem key={type.id} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {errors.typeName && (
                    <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                  )} */}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="technician" error={errors.technician}>
                    Preferred Technician
                  </InputLabel>
                  <Select
                    labelId="technician"
                    name="technician"
                    value={appointment?.technician || ''}
                    label="Preferred Technician"
                    onChange={handleChange}
                    error={errors.technician}
                  >
                    {techniciansList?.map((tech) => (
                      <MenuItem key={tech.tech_id} value={tech.tech_id}>
                        {tech.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.technician && (
                    <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="type" error={errors.type}>
                    Appointment Type *
                  </InputLabel>
                  <Select
                    labelId="type"
                    name="type"
                    value={appointment?.type || ''}
                    label="Appointment Type *"
                    onChange={handleChange}
                    required
                    error={errors.type}
                  >
                    {types.map((type) => {
                      if (type.name !== 'Day Off')
                        return (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        );
                    })}
                  </Select>
                  {errors.type && (
                    <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="source" error={errors.source}>
                    Source
                  </InputLabel>
                  <Select
                    labelId="source"
                    name="source"
                    value={appointment?.source || ''}
                    label="Source"
                    onChange={handleChange}
                    // error={errors.source}
                  >
                    {sources.map((source) => (
                      <MenuItem key={source.id} value={source.id}>
                        {source.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {errors.source && (
                    <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                  )} */}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="MM/DD/YYYY"
                    value={appointment.date || null}
                    onChange={handleChange}
                    disablePast
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="date"
                        fullWidth
                        error={false}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                    disabled={!appointment.technician}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="startAt" error={errors.startAt}>
                    Start Time
                  </InputLabel>
                  <Select
                    labelId="startAt"
                    name="startAt"
                    value={appointment?.startAt || ''}
                    label="Start Time *"
                    onChange={handleChange}
                    disabled={!appointment.date}
                    error={errors.startAt}
                  >
                    {appointment_times.map((time, index) => (
                      <MenuItem
                        key={index}
                        value={time.time24}
                        disabled={
                          appointment.finishAt &&
                          appointment_times.indexOf(time) >
                            appointment_times.findIndex((x) => x.time24 === appointment.finishAt) - 4
                        }
                      >
                        {time.time12}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.startAt && (
                    <FormHelperText sx={{ color: '#ef5350' }}>
                      If you have chosen a date, the time is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="finishAt" error={errors.finishAt}>
                    End Time
                  </InputLabel>
                  <Select
                    labelId="finishAt"
                    name="finishAt"
                    value={appointment?.finishAt || ''}
                    label="End Time"
                    onChange={handleChange}
                    disabled={!appointment.date}
                    error={errors.finishAt}
                  >
                    {appointment_times.map((time, index) => (
                      <MenuItem
                        key={index}
                        value={time.time24}
                        disabled={
                          appointment_times.indexOf(time) <
                          appointment_times.findIndex((x) => x.time24 === appointment.startAt) + 4
                        }
                      >
                        {time.time12}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.finishAt && (
                    <FormHelperText sx={{ color: '#ef5350' }}>
                      If you have chosen a date, the time is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={appointment.property ? 6 : 12}>
                <TextField
                  name="notes"
                  label="Appointment Notes"
                  value={appointment?.notes}
                  rows={4}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  // required
                  // error={errors.notes}
                  // helperText={errors.notes && messages.errors.required_field}
                />
              </Grid>

              {appointment.property && (
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography sx={{ fontWeight: 600, mb: 1 }}>Property Notes:</Typography>

                    {appointment.property?.notes?.map((el) => (
                      <Fragment key={el.id}>
                        <Box alignItems="center" sx={{ display: 'flex', mt: 1 }} key={el.id}>
                          <Typography variant="body1">
                            {el.note}
                            {` `}
                            <Typography variant="caption" sx={{ fontStyle: 'italic ' }}>
                              (Created by <strong>{el.createdBy.fullName}</strong> at{' '}
                              {moment(el.createdAt).format('L, h:mm:ss a')})
                            </Typography>
                          </Typography>
                        </Box>
                        <Divider sx={{ mt: 1 }} />
                      </Fragment>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Clients */}
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={open.clients}
          onChange={() => accordionToggle('clients')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Clients</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {appointment.address && !appointment.property && (
              <Fragment>
                <Button variant="outlined" onClick={handleClick} endIcon={<AddIcon />} sx={{ mb: 2 }}>
                  Add Client
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openMore}
                  onClose={() => setAnchorEl(null)}
                  onClick={() => setAnchorEl(null)}
                  PaperProps={menuPaperProps}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={onFindClient}>
                    <PersonSearchIcon /> Existing Client
                  </MenuItem>
                  <MenuItem onClick={onAddClient}>
                    <PersonAddIcon /> New Client
                  </MenuItem>
                </Menu>
              </Fragment>
            )}

            {appointment.property ? (
              <PropertyClientsTable
                clientsList={appointment.property.clients || []}
                id={appointment.property.id}
                refresh={() => getPropertyData(appointment.property.id)}
              />
            ) : (
              <AppointmentClientsTable clients={appointment?.clients || []} />
            )}
          </AccordionDetails>
        </Accordion>

        {/* Appointments */}
        {appointment?.property?.appointments && (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            expanded={open.appointments}
            onChange={() => accordionToggle('appointments')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Appointments</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AppointmentAppointmentsTable appointments={appointment?.property?.appointments} />
            </AccordionDetails>
          </Accordion>
        )}

        {/* Proposals */}
        {appointment?.property?.proposals && (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            expanded={open.proposals}
            onChange={() => accordionToggle('proposals')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Proposals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AppointmentProposalsTable proposals={appointment?.property?.proposals} />
            </AccordionDetails>
          </Accordion>
        )}

        {/* Media */}
        {appointment?.property?.media && (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            expanded={open.media}
            onChange={() => accordionToggle('media')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Property Media</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PropertyMediaTable
                mediaList={appointment?.property.media}
                id={appointment.property.id}
                refresh={() => getPropertyData(appointment.property.id)}
              />
            </AccordionDetails>
          </Accordion>
        )}

        <Box sx={{ mt: 2 }}>
          <Button onClick={handleValidate} variant="contained" sx={button('primary', 'secondary')}>
            Save Appointment
          </Button>
          <Button onClick={handleClearForm} variant="contained" sx={{ ...button('secondary'), ml: 2 }}>
            Clear Form
          </Button>
        </Box>
      </Box>

      <PropertyClientsDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        drawerMode={drawerMode}
        currentClient={currentClient}
        setCurrentClient={setCurrentClient}
        addNewClient={onAddNewClient}
        updateClient={onAddNewClient}
        onAddNewClientFromExisting={onAddNewClientFromExisting}
      />
    </Fragment>
  );
};

export default AppointmentCreate;
