export const table_header_with_search = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 3,
};

// export const table_container_base = { maxHeight: 650 };
export const table_container_base = { maxHeight: '100vh', height: '100%' };

export const check_for_deleted_row = (rule) => {
  return { backgroundColor: rule ? 'rgba(0, 0, 0, 0.075) !important' : 'transparent' };
};

export const actions_column = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
};

export const flex_center = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };

export const bottom_border_only = {
  width: '100%',
  '&::before': {
    display: 'none',
  },
};

export const proposal_grouped_items = {
  borderLeft: '4px solid orange',
  paddingLeft: 2,
  '> div': {
    marginBottom: 0,
    '&:not(:last-of-type)': {
      marginBottom: '16px !important',
    },
  },
};

export const select_no_border = {
  width: '100%',
  '> fieldset': {
    border: 'none !important',
    outline: 'none !important',
  },
  '> div': {
    paddingLeft: 0,
  },
};

export const modal_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width: '100%',
  backgroundColor: '#fff',
  boxShadow: 24,
  p: 4,
};
