import api from '../api/axios';

const getFileByName = (name, type) => {
  const response = api.get(`/resource/${type}/${name}`);

  return response;
};

const upload = (newFile, type) => {
  const response = api.post(
    `resource/${type}`,
    {
      file: newFile,
    },
    {
      headers: { accept: 'application/json', 'Content-Type': 'multipart/form-data' },
    },
  );

  return response;
};

const resourceServices = {
  upload,
  getFileByName,
};

export default resourceServices;
