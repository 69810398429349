import { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import {
  Box,
  Button,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

// Custom Components
import { Spinner, SearchBox, ClientDrawer, BrowserTitle } from '../components';

// Utils
import messages from '../static/messages';
import { formatPhone } from '../utils/formatter';
import { nullClearObject, emptyStringClear } from '../utils/cleaners';
import { AuthContext } from '../context/AuthProvider';

// Services
import clientsServices from '../services/clientsServices';

// Hooks
import useSearch from '../hooks/useSearch';
import usePagination from '../hooks/usePagination';
import useDrawer from '../hooks/useDrawer';

// Styles
import {
  table_header_with_search,
  table_container_base,
  check_for_deleted_row,
  actions_column,
} from '../static/styles';

import { button } from '../static/theme-styles';

// External variables
const base_client = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

const Clients = () => {
  const { userData } = useContext(AuthContext);

  const [clientsList, setClientsList] = useState(null);
  const [showDeleted, setShowDeleted] = useState(userData.preferences.showDeletedRows);
  const [totalCount, setTotalCount] = useState();
  const [currentClient, setCurrentClient] = useState(null);
  const [isNewClient, setClientMode] = useState(false);

  const { searchQuery, setSearchQuery, isSearching, searchRequest } = useSearch(clientsServices.searchRequest);
  const { rowsPerPage, setRowsPerPage, page, setPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { drawer, openDrawer, foolCheck } = useDrawer(currentClient);

  const confirm = useConfirm();
  const navigate = useNavigate();
  const location = useLocation();

  // Action functions
  const onCloseDrawer = async (resetErrors) => {
    const confirmed = await foolCheck();

    if (confirmed === true) {
      setCurrentClient(null);
      setClientMode(false);
      resetErrors && resetErrors();
    }
  };

  const onEditClient = (client) => {
    delete client.createdAt;
    delete client.deletedAt;
    delete client.updatedAt;
    const cleanObject = nullClearObject(client);
    setCurrentClient(cleanObject);
    openDrawer();
  };

  const onAddClient = () => {
    setClientMode(true);
    setCurrentClient(base_client);
    openDrawer();
  };

  // Handler functions
  const handleRemove = (id) => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Client'),
    })
      .then(() => removeClient(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  const handleRestore = (id) => {
    confirm({
      title: messages.confirm_message.restore,
      description: messages.confirm_message.restore_description('Client'),
    })
      .then(() => restoreClient(id))
      .catch(() => toast.info(messages.cancel_message));
  };

  // Async functions
  const getClientsList = async (skip, take, widthDeleted = true) => {
    try {
      const response = await clientsServices.getAllClients(skip, take, widthDeleted);
      setTotalCount(response.data.total);
      setClientsList(response.data.data);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
      console.log(error);
    }
  };

  const getSearchResult = useCallback(
    async (skip, take) => {
      const result = await searchRequest(skip, take);
      setTotalCount(result.total);
      setClientsList(result.data);
      // setPage(0);
    },
    [searchRequest],
  );

  const removeClient = async (id) => {
    try {
      const response = await clientsServices.remove(id);

      if (response.status === 200) {
        toast.success(messages.client.remove);
        getClientsList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const restoreClient = async (id) => {
    try {
      const response = await clientsServices.restore(id);

      if (response.status === 200) {
        toast.success(messages.client.restore);
        getClientsList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const createClient = async () => {
    delete currentClient.isEdited;

    emptyStringClear(currentClient);

    try {
      const response = await clientsServices.create(currentClient);

      if (response.status === 201) {
        onCloseDrawer();
        toast.success(messages.client.create);
        navigate(`/clients/${response.data.id}`);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  const updateClient = async () => {
    delete currentClient.isEdited;

    emptyStringClear(currentClient);

    try {
      const response = await clientsServices.update(currentClient.id, currentClient);

      if (response.status === 200) {
        onCloseDrawer();
        toast.success(messages.client.update);
        getClientsList(page * rowsPerPage, rowsPerPage, showDeleted);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    searchQuery === ''
      ? getClientsList(page * rowsPerPage, rowsPerPage, showDeleted)
      : getSearchResult(page * rowsPerPage, rowsPerPage, showDeleted);
  }, [searchQuery, getSearchResult, page, rowsPerPage, showDeleted]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setShowDeleted(userData.isDeletedRowsVisible);

    if (location.search) {
      const params = new URLSearchParams(location.search);
      const page = params.get('page');
      const rowsPerPage = params.get('per_page');

      setPage(Number(page));
      setRowsPerPage(Number(rowsPerPage));
    }
  }, []);

  // Check for data loading
  if (!clientsList) return <Spinner />;

  return (
    <Box component="section">
      <BrowserTitle title="Clients | AirMaxx Pro" />
      <Box sx={table_header_with_search}>
        <Button variant="contained" endIcon={<AddIcon />} onClick={onAddClient} sx={button('primary', 'secondary')}>
          Add New
        </Button>

        <FormControlLabel
          value="end"
          control={<Switch color="primary" checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} />}
          label="Show Deleted"
          labelPlacement="end"
          disabled={searchQuery !== ''}
        />
      </Box>

      <Paper>
        <SearchBox searchRequest={searchRequest} value={searchQuery} setValue={setSearchQuery} />

        <TableContainer sx={table_container_base}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Company</TableCell>
                <TableCell width="30%">Name</TableCell>
                <TableCell width="20%">Phone</TableCell>
                <TableCell width="25%">Email</TableCell>
                <TableCell width="5%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSearching ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Spinner />
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment>
                  {clientsList.length ? (
                    clientsList.map((client) => (
                      <TableRow key={client.id} sx={check_for_deleted_row(!!client.deletedAt)} hover>
                        <TableCell scope="row">
                          <NavLink
                            className="full-col-flex-link"
                            to={`/clients/${client.id}`}
                            onClick={!!client.deletedAt ? (e) => e.preventDefault() : null}
                            state={{
                              page: page,
                              rowsPerPage: rowsPerPage,
                            }}
                          >
                            {client.companyName}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/clients/${client.id}`}
                            onClick={!!client.deletedAt ? (e) => e.preventDefault() : null}
                            state={{
                              page: page,
                              rowsPerPage: rowsPerPage,
                            }}
                          >
                            {client.fullName}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/clients/${client.id}`}
                            onClick={!!client.deletedAt ? (e) => e.preventDefault() : null}
                            state={{
                              page: page,
                              rowsPerPage: rowsPerPage,
                            }}
                          >
                            {client.phoneNumber && formatPhone(client.phoneNumber)}
                          </NavLink>
                        </TableCell>
                        <TableCell>
                          <NavLink
                            className="full-col-flex-link"
                            to={`/clients/${client.id}`}
                            onClick={!!client.deletedAt ? (e) => e.preventDefault() : null}
                            state={{
                              page: page,
                              rowsPerPage: rowsPerPage,
                            }}
                          >
                            {client.email}
                          </NavLink>
                        </TableCell>
                        <TableCell sx={actions_column}>
                          {!!client.deletedAt ? (
                            <Tooltip title="Restore Client">
                              <IconButton onClick={() => handleRestore(client.id)}>
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Fragment>
                              <Tooltip title="Edit Client">
                                <IconButton onClick={() => onEditClient(client)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Client">
                                <IconButton onClick={() => handleRemove(client.id)}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                        <strong>No Data</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Clients per page"
      />

      <ClientDrawer
        drawer={drawer}
        onCloseDrawer={onCloseDrawer}
        currentClient={currentClient}
        setCurrentClient={setCurrentClient}
        isNewClient={isNewClient}
        createClient={createClient}
        updateClient={updateClient}
      />
    </Box>
  );
};

export default Clients;
