import { useState, useCallback, useRef } from 'react';

const useSearch = (searchService) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setSearchStatus] = useState(false);
  const abortControllerRef = useRef(null);

  const searchRequest = useCallback(
    async (skip, take) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      try {
        setSearchStatus(true);

        const response = await searchService(skip, take, searchQuery, true, { signal });

        setSearchStatus(false);
        abortControllerRef.current = null;

        return response.data;
      } catch (error) {
        // console.log(error);
        if (error.name !== 'AbortError') {
          console.log(error);
        }
      }
    },
    [searchQuery, searchService],
  );

  return {
    searchQuery,
    setSearchQuery,
    isSearching,
    searchRequest,
  };
};

export default useSearch;
