import { useState, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';

import { Modal, Box, Typography, Select, Button, FormControl, InputLabel, MenuItem } from '@mui/material';

import { Spinner } from '../../../';

import messages from '../../../../static/messages';

import { modal_style } from '../../../../static/styles';

const SystemTemplateLoadModal = ({ open, close, onLoadTemplate, removeTemplate, templates }) => {
  const [id, setId] = useState('');

  const confirm = useConfirm();

  const handleDelete = () => {
    confirm({
      title: messages.confirm_message.remove,
      description: messages.confirm_message.remove_description('Template'),
    })
      .then(() => {
        removeTemplate(id);
        setId('');
        close();
      })
      .catch(() => toast.info(messages.cancel_message));
  };

  useEffect(() => {
    setId('');
  }, [open]);

  if (!templates) return <Spinner />;

  return (
    <Modal open={open} onClose={close}>
      <Box sx={modal_style}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Load Template
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="template">Choose Template</InputLabel>
          <Select
            labelId="template"
            name="template"
            value={id}
            label="Choose Template"
            onChange={(event) => setId(event.target.value)}
          >
            {templates?.map((template) => (
              <MenuItem
                key={template.id}
                value={template.id}
                sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
              >
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Box>
            <Button
              variant="contained"
              color="success"
              onClick={() => onLoadTemplate(id)}
              size="small"
              sx={{ mr: 2 }}
              disabled={!id}
            >
              Load
            </Button>

            <Button variant="contained" color="error" onClick={close} size="small">
              Cancel
            </Button>
          </Box>

          {id && (
            <Box>
              <Button variant="contained" color="error" onClick={handleDelete} size="small">
                Delete Template
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default SystemTemplateLoadModal;
