import CssBaseline from '@mui/material/CssBaseline';
import { ConfirmProvider } from 'material-ui-confirm';
import { ThemeProvider } from '@mui/material/styles';

import { AuthContext } from './context/AuthProvider';
import { useAuth } from './hooks/useAuth';
import { useRoutes } from './hooks/useRoutes';
import { BrowserTitle, Spinner } from './components';

import { light_theme, dark_theme } from './utils/theme';

export default function App() {
  const { token, login, logout, loading, userData } = useAuth();
  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated, userData);

  if (!loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        isAuthenticated,
        userData,
      }}
    >
      <BrowserTitle title="AirMaxx Pro" />
      <CssBaseline />
      <ThemeProvider theme={light_theme}>
        <ConfirmProvider>{routes}</ConfirmProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}
