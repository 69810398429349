import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import InputMask from 'react-input-mask';

import {
  Alert,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  TextField,
} from '@mui/material';

// Custom Components
import { Spinner } from '../..';

// Utils
import messages from '../../../static/messages';
import { internationalPhoneFormat, validateEmail } from '../../../utils/formatter';

// Services
import settingsServices from '../../../services/settingsServices';

// Hooks
import { useLocationAutocomplete } from '../../../hooks/useAutocomplete';

// Styles
import { bottom_border_only } from '../../../static/styles';

import { button } from '../../../static/theme-styles';

// External variables
const required_fields = [
  'address',
  'cellPhone',
  'companyName',
  'contactName',
  'email',
  'fax',
  'officePhone',
  'termsAndConditions',
];

const autocomplete_styles = {
  border: 'none',
  '.MuiOutlinedInput-root': {
    padding: 0,
  },
  fieldset: {
    border: 0,
  },
  '.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    '&::after': {
      content: `''`,
      borderBottom: '2px solid #1976d2',
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      pointerEvents: 'none',
    },
  },
  '.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
    '&::after': {
      transform: 'scaleX(1) translateX(0)',
    },
  },
  input: {
    padding: '4px 0 5px !important',
    '&[aria-invalid="true"]': {
      borderBottom: '2px solid #d32f2f',
      paddingBottom: '5px !important',
    },
  },
};

const SettingsTable = () => {
  const [companyInfo, setCompanyInfo] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [errors, setErrors] = useState();

  const { handleSearchLocation, locationOptions } = useLocationAutocomplete(2);

  // Action functions
  const resetErrors = () => {
    setErrors({});
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  const onAddressSelected = (event, val) => {
    event.preventDefault();

    setCompanyInfo((prevState) => ({
      ...prevState,
      address: String(val),
      isEdited: true,
    }));
    handleSearchLocation('');
  };

  // Handler functions
  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    if (key === 'officePhone' || key === 'cellPhone' || key === 'fax') {
      setCompanyInfo((prevState) => ({
        ...prevState,
        [key]: val === '1' ? '' : internationalPhoneFormat(val),
        isEdited: true,
      }));
    } else {
      setCompanyInfo((prevState) => ({
        ...prevState,
        [key]: val,
        isEdited: true,
      }));
    }
  };

  const handleValidate = () => {
    resetErrors();

    required_fields.forEach((field) => {
      if (companyInfo[field] === '' || companyInfo[field] === null) {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });

    if (!validateEmail(companyInfo.email)) {
      toast.error(messages.errors.invalid_format('Email'));
      setErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (companyInfo.cellPhone.length !== 12) {
      toast.error(messages.errors.invalid_format('Phone'));
      setErrors((prevState) => ({
        ...prevState,
        cellPhone: true,
      }));
    }

    if (companyInfo.officePhone.length !== 12) {
      toast.error(messages.errors.invalid_format('Phone'));
      setErrors((prevState) => ({
        ...prevState,
        officePhone: true,
      }));
    }

    if (companyInfo.fax.length !== 12) {
      toast.error(messages.errors.invalid_format('Phone'));
      setErrors((prevState) => ({
        ...prevState,
        fax: true,
      }));
    }
  };

  // Async functions
  const getSettings = async () => {
    try {
      const response = await settingsServices.getSettingsList();
      setCompanyInfo(response.data.value);
      setLastUpdate(response.data.updatedAt);
    } catch (error) {
      toast.error(messages.errors.error_data_loaning);
    }
  };

  const updateSettings = async () => {
    delete companyInfo.isEdited;

    try {
      const response = await settingsServices.update(companyInfo);

      if (response.status === 200) {
        toast.success(messages.success.update.common);
        setCompanyInfo(response.data.value);
        setLastUpdate(response.data.updatedAt);
      }
    } catch (error) {
      toast.error(messages.errors.error_try_again);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getSettings();
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  }, []);

  useEffect(() => {
    if (companyInfo?.isEdited) {
      !Object.values(errors).includes(true) && updateSettings();
      Object.values(errors).includes(true) && toast.error('You must not have empty values!');
    }
  }, [errors]);

  if (!companyInfo) return <Spinner />;

  return (
    <Fragment>
      <Alert severity="info">
        Date of last settings update: {moment.utc(lastUpdate).format('MMM Do YYYY, h:mm:ss a')}
      </Alert>

      <TableContainer component={Paper} sx={{ maxHeight: 650, mt: 2 }}>
        <Table aria-label="settings table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="50%">Option</TableCell>
              <TableCell width="50%">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell>Company</TableCell>
              <TableCell>
                <Input
                  defaultValue={companyInfo.companyName}
                  aria-label={companyInfo.companyName}
                  name="companyName"
                  onChange={handleChange}
                  error={errors.companyName}
                  fullWidth
                  sx={bottom_border_only}
                />
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Contact</TableCell>
              <TableCell>
                <Input
                  defaultValue={companyInfo.contactName}
                  aria-label={companyInfo.contactName}
                  name="contactName"
                  onChange={handleChange}
                  error={errors.contactName}
                  fullWidth
                  sx={bottom_border_only}
                />
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Address</TableCell>
              <TableCell>
                <Autocomplete
                  options={locationOptions}
                  getOptionLabel={(option) => option}
                  filterOptions={(x) => x}
                  onChange={onAddressSelected}
                  value={companyInfo.address}
                  freeSolo
                  sx={autocomplete_styles}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="address"
                      aria-label={companyInfo.address}
                      onChange={(e) => handleSearchLocation(e.target.value)}
                      error={errors.address}
                    />
                  )}
                />
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Office</TableCell>
              <TableCell>
                <InputMask
                  mask="+1 (999) 999-9999"
                  defaultValue={companyInfo.officePhone}
                  maskChar=" "
                  onChange={handleChange}
                >
                  {() => <Input name="officePhone" error={errors.officePhone} fullWidth sx={bottom_border_only} />}
                </InputMask>
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Cell Phone</TableCell>
              <TableCell>
                <InputMask
                  mask="+1 (999) 999-9999"
                  defaultValue={companyInfo.cellPhone}
                  maskChar=" "
                  onChange={handleChange}
                >
                  {() => <Input name="cellPhone" error={errors.cellPhone} fullWidth sx={bottom_border_only} />}
                </InputMask>
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Fax</TableCell>
              <TableCell>
                <InputMask mask="+1 (999) 999-9999" defaultValue={companyInfo.fax} maskChar=" " onChange={handleChange}>
                  {() => <Input name="fax" error={errors.fax} fullWidth sx={bottom_border_only} />}
                </InputMask>
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Email</TableCell>
              <TableCell>
                <Input
                  defaultValue={companyInfo.email}
                  aria-label={companyInfo.email}
                  name="email"
                  onChange={handleChange}
                  error={errors.email}
                  fullWidth
                  sx={bottom_border_only}
                />
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Terms and Conditions</TableCell>
              <TableCell>
                <Input
                  defaultValue={companyInfo.termsAndConditions}
                  aria-label={companyInfo.termsAndConditions}
                  name="termsAndConditions"
                  onChange={handleChange}
                  error={errors.termsAndConditions}
                  fullWidth
                  sx={bottom_border_only}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        sx={{ ...button('primary', 'secondary'), mt: 3 }}
        onClick={handleValidate}
        disabled={!companyInfo.isEdited}
        // sx={}
      >
        Save
      </Button>
    </Fragment>
  );
};

export default SettingsTable;
