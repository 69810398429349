import { useState, useEffect } from 'react';

import {
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';

// Utils
import messages from '../../static/messages';

// Styles
import { button } from '../../static/theme-styles';

// External variables
const required_fields = ['mode', 'name'];

const AppointmentTypesAndSourcesDrawer = (props) => {
  const { currentItem, setCurrentItem, createType, createSource } = props;
  const [errors, setErrors] = useState({});

  const [mode, setMode] = useState();

  // Action functions
  const resetErrors = () => {
    setErrors({});
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  };

  // Handler functions
  const handleClose = () => props.onCloseDrawer(resetErrors);

  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setCurrentItem((prevState) => ({
      ...prevState,
      [key]: val,
      isEdited: true,
    }));
  };

  const handleValidate = () => {
    resetErrors();

    required_fields.forEach((field) => {
      if (currentItem[field] === '') {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else if (!mode) {
        setErrors((prevState) => ({
          ...prevState,
          mode: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });
  };

  // Hooks
  useEffect(() => {
    required_fields.forEach((el) =>
      setErrors((prevState) => ({
        ...prevState,
        [el]: false,
      })),
    );
  }, []);

  useEffect(() => {
    if (currentItem?.isEdited) {
      delete currentItem.isEdited;

      !Object.values(errors).includes(true) && (mode === 'type' ? createType() : createSource());

      setMode();
    }
  }, [errors]);

  return (
    <Drawer anchor="right" open={props.drawer} onClose={handleClose}>
      <Box>
        {currentItem && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Add New Item
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="mode *" sx={{ color: errors.mode ? '#ef5350' : '' }}>
                  Chose What You Want To Add *
                </InputLabel>
                <Select
                  labelId="mode"
                  name="mode"
                  value={mode || ''}
                  label="Chose What You Want To Add *"
                  error={errors.mode}
                  onChange={(event) => setMode(event.target.value)}
                >
                  <MenuItem value="type">Type</MenuItem>
                  <MenuItem value="source">Source</MenuItem>
                </Select>
                {errors.mode && (
                  <FormHelperText sx={{ color: '#ef5350' }}>{messages.errors.required_field}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="name"
                label="Name"
                defaultValue={currentItem.name}
                fullWidth
                onChange={handleChange}
                error={errors.name}
                helperText={errors.name && messages.errors.required_field}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ ...button('primary', 'secondary'), mr: 3 }}
                disabled={!currentItem.isEdited}
                onClick={handleValidate}
              >
                Add New Item
              </Button>

              <Button variant="contained" onClick={handleClose} sx={button('secondary')}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default AppointmentTypesAndSourcesDrawer;
